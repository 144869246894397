import { useState } from "react";
import { Modal, message } from "antd";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PasswordInput, Button } from "shared/components";
import { setLocalToken } from "shared/utils/util-funcs";
import { LOGIN } from "shared/services";
import { useUser } from "shared/contexts/user-context";

export default function ReenterPasswordModal({
  isOpen,
  onCancel,
  onSuccessFunc,
}) {
  const { t } = useTranslation();
  const {
    user: { id, email },
  } = useUser();

  const [formStatus, setFormStatus] = useState({
    status: "",
  });

  const { register, handleSubmit, errors } = useForm();

  const [formValues, setFormValues] = useState({
    password: "",
  });
  const handleChange = (prop) => (event) => {
    setFormValues({ ...formValues, [prop]: event.target.value });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [login] = useMutation(LOGIN);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const res = await login({
      variables: { ...data, email: email },
    });
    if (res?.data?.professional_login?.error) {
      message.error(res?.data?.professional_login?.error);
      setIsSubmitting(false);
    } else if (res?.data?.professional_login?.token) {
      setLocalToken("auth", res.data.professional_login.token.access_token);
      setLocalToken(
        "auth-createdAt",
        res.data.professional_login.token.created_at
      );

      await onSuccessFunc();
      onCancel();
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={null}
      visible={isOpen}
      onCancel={!isSubmitting && onCancel}
      footer={null}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <PasswordInput
          register={register({
            required: { value: true, message: "required" },
          })}
          errors={errors}
          label="enter your password"
          // value={formValues.password}
          // onChange={handleChange}
          tabIndex={2}
          // showForgotPassword={true}
        />

        <Button
          tabIndex="2"
          type="submit"
          // classes={[styles.submitButton]}
          disabled={isSubmitting}
          isProcessing={isSubmitting}
        >
          {t("submit")}
        </Button>
      </form>
    </Modal>
  );
}
