import { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Modal, Divider, Input, message } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import { ADD_STRIPE_PAYMENT_METHOD } from "shared/services";
import { useUser } from "shared/contexts/user-context";
import {
  ReenterPasswordModal,
  StripeCardForm,
  StripeElementProvider,
} from "shared/components";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export default function AddStripeDetails({
  isOpen,
  onCancel,
  enterBillingDetails,
  refetchUser,
}) {
  return (
    <StripeElementProvider>
      <StripeDetailsModal
        isOpen={isOpen}
        onCancel={onCancel}
        refetchUser={refetchUser}
      />
    </StripeElementProvider>
  );
}

function StripeDetailsModal({ isOpen, onCancel, refetchUser }) {
  const { t } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();

  const [addPaymentMethod] = useMutation(ADD_STRIPE_PAYMENT_METHOD);

  const {
    user: { id: userId },
  } = useUser();

  const [paymentStatus, setPaymentStatus] = useState();
  const [error, setError] = useState();
  const [status, setStatus] = useState();

  const [billingDetails, setBillingDetails] = useState({
    email: "",
    phone: "",
    name: "",
  });

  const handleCreateClientSecret = async () => {
    const paymentIntentData = await addPaymentMethod({
      variables: { id: userId },
    });

    console.log(paymentIntentData.data);

    // Handle error
    if (paymentIntentData.data.add_payment_method.error) {
      message.error(paymentIntentData.data.add_payment_method.error);
      onCancel();
      return;
    }

    setStatus("PROCESSING");

    const res = await handleStripePaymentMethod(
      paymentIntentData.data.add_payment_method.setup_intent.client_secret
    );
  };

  const handleStripePaymentMethod = async (clientSecret) => {
    const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      },
    });

    // Handle stripe error
    if (error) {
      message.error(error.message);
      onCancel();
    } else {
      if (setupIntent.status === "succeeded") {
        message.success(
          `${t("Card has been saved successfully in your account")}`
        );
        refetchUser();
        onCancel();
      }
    }
  };

  async function handleAddCardDetails() {
    setStatus("AWAITING_LOGIN");
  }

  return (
    <>
      {status === "AWAITING_LOGIN" && (
        <ReenterPasswordModal
          isOpen={status === "AWAITING_LOGIN"}
          onCancel={() => setStatus(null)}
          onSuccessFunc={handleCreateClientSecret}
        />
      )}
      <Modal
        title={t("Add card to your account")}
        visible={isOpen}
        footer={null}
        closable={true}
        // width={700}
        onCancel={onCancel}
        destroyOnClose
      >
        <div style={{ paddingBottom: "1rem" }}>
          <StripeCardForm
            saveCard={true}
            enterBillingDetails={true}
            billingDetails={billingDetails}
            setBillingDetails={setBillingDetails}
            elements={elements}
            paymentStatus={paymentStatus}
            onExecFunc={handleAddCardDetails}
            error={error}
            setError={setError}
          />
        </div>
      </Modal>
    </>
  );
}
