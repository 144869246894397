import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { format, isAfter } from "date-fns";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { message } from "antd";
import AntDatePicker from "../AntDatePicker/AntDatePicker";
import clsx from "clsx";

import {
  FormInput,
  Button,
  ReenterPasswordModal,
  DatePicker,
  // CustomDatePicker,
  ReactDatePicker,
} from "shared/components";
import { UPDATE_USER } from "shared/services";
import moment from "moment";

export default function AddProfIdDetails({ user, refetchUser }) {
  const { t } = useTranslation();

  const { register, handleSubmit, errors, control, setValue } = useForm();

  const [updateUser] = useMutation(UPDATE_USER);

  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const [formValues, setFormValues] = useState();

  const handleAddProfDetails = async () => {
    // format date string
    const graduationDate = moment(formValues.graduationDate).format(
      "YYYY-MM-DD"
    );
    const res = await updateUser({
      variables: {
        id: user.id,
        ...formValues,
        graduationDate: graduationDate,
      },
    });

    if (res?.data?.update_professional?.error) {
      setShowPasswordModal(false);
      message.error(res.data.update_professional.error);
    } else if (res?.data?.update_professional?.professional.id) {
      setShowPasswordModal(false);
      refetchUser();
    }
  };
  const handleDateChange = (date) => {
    if (date) {
      setValue("graduationDate", moment(date).format("YYYY-MM-DD"));
    } else {
      setValue("graduationDate", null);
    }
  };

  return (
    <>
      {showPasswordModal && (
        <ReenterPasswordModal
          isOpen={showPasswordModal}
          onCancel={() => setShowPasswordModal(false)}
          onSuccessFunc={handleAddProfDetails}
        />
      )}

      <form
        // className={styles.form}
        // style={{ ...inlineStyles }} // To hanle wider signup form
        onSubmit={handleSubmit((data) => {
          setFormValues(data);
          setShowPasswordModal(true);
        })}
      >
        <FormInput
          label="licence no"
          name="licenceNo"
          tabIndex={1}
          register={register({
            required: { value: true, message: "required" },
          })}
          errors={errors}
        />
        {/* <Controller
          render={(props) => (
            <div className="select-form-input" style={{ marginBottom: "1rem" }}>
              <label htmlFor="graduation_date" className={clsx("formLabel")}>
                {t("graduation date")}
                {errors?.dob && (
                  <span className={"formErrortext"}>
                    {t(errors["graduation_date"]?.message)}
                  </span>
                )}
              </label>
              <AntDatePicker handleDateChange={handleDateChange} />
            </div>
          )}
          control={control}
          name="graduationDate"
          rules={{ required: { value: true, message: "required" } }}
        /> */}
        <AntDatePicker
          title="graduation date"
          name="graduationDate"
          error={errors.graduationDate}
          rules={{ required: { value: true } }}
          control={control}
          style={{ marginBottom: "1rem" }}
        />

        <Button type="submit" inlineStyles={{ padding: ".75rem 0" }}>
          {t("submit")}
        </Button>
      </form>
    </>
  );
}
