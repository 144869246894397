import { isSameDay, isTomorrow } from "date-fns";

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

export const filterCurrentDateLoginSessions = (sessions) => {
  const loginSessions = sessions.filter((session) => session.event === "login");

  const currentDayLogins = loginSessions.filter((session) =>
    isSameDay(new Date(session.created_at), new Date())
  );

  return currentDayLogins;
};

// Check if user has viewed this slot in the current day already
export const currentDaySlotViews = (slotTrackings, userId) => {
  const viewTrackingsByUser = slotTrackings.filter(
    (tracking) =>
      tracking.action === "view" &&
      tracking.user_id === userId &&
      isSameDay(new Date(tracking.created_at), new Date())
  );
  return viewTrackingsByUser;
};
