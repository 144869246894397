import { useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import publicIp from "public-ip";

import { REST_VIDEO_API_ENDPOINT } from "config";
import { getLocalToken } from "shared/utils/util-funcs";
import { useUser } from "shared/contexts/user-context";

import styles from "./video-player.module.scss";
import { message } from "antd";
import moment from "moment";
import { formatToLocalTime } from "shared/utils/util";
import { useMutation } from "@apollo/client";
import { INCREMENT_VIEW_COUNT } from "shared/services";

export default function VideoPlayer({ video, videoId }) {
  const [incrementViewCount] = useMutation(INCREMENT_VIEW_COUNT);
  const { t } = useTranslation();

  const fetchVideoWithOtp = async () => {
    const authToken = getLocalToken("auth");

    try {
      // get IP address from external service
      const ipRes = await publicIp.v4({
        fallbackUrls: ["https://ifconfig.co/ip"],
      });

      const videoRes = await axios.post(
        `${REST_VIDEO_API_ENDPOINT}/videos/watermark/${videoId}`,
        {
          annotate: JSON.stringify([
            {
              type: "rtext",
              text: video.professional.id,
              alpha: "0.6",
              color: "#a9a9a9",
              size: "11",
              x: "10",
              y: "70",
              interval: "5000",
              skip: "2000",
            },
            {
              type: "rtext",
              text: `${moment(formatToLocalTime(video?.created_at)).format(
                "HH:mm, DD/MM/YYYY"
              )}, ${ipRes}`,
              alpha: "0.6",
              color: "#a9a9a9",
              size: "11",
              x: "10",
              y: "50",
              interval: "5000",
              skip: "2000",
            },
            {
              type: "text",
              text: `${moment(formatToLocalTime(video?.created_at)).format(
                "HH:mm, DD/MM/YYYY"
              )}, ${ipRes}`,
              alpha: "0.6",
              color: "#a9a9a9",
              size: "11",
              x: "10",
              y: "50",
              // interval: "5000",
              // skip: "2000",
            },
          ]),
        },
        {
          headers: {
            Authorization: `${authToken}`,
          },
        }
      );

      var vid = new window.VdoPlayer({
        otp: videoRes.data.otp,
        playbackInfo: videoRes.data.playbackInfo,
        theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
        container: document.querySelector(`#videoPlayer${videoId}`),
      });
    } catch (err) {
      if (err.message) {
        message.error(err.message);
      } else {
        message.error(`${t("something went wrong please refresh your page")}`);
      }
    }
  };

  useEffect(() => {
    fetchVideoWithOtp();
    incrementViewCount({ variables: { video_id: videoId } });
  }, []);

  return (
    <div className={styles.videoPlayer}>
      <div id={`videoPlayer${videoId}`} />
    </div>
  );
}
