import { MdImage, MdPlayCircleFilled } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import Button from "../Button/Button";

export default function PostToggleButton() {
  const { pathname } = useLocation();

  const videoPaneLink = pathname === "/videos" ? "" : "videos";
  return (
    <div className="imageToggleButton">
      <Link to={`/${videoPaneLink}`}>
        {/* {pathname === "/videos" ? <MdPlayCircleFilled /> : <MdImage />} */}
        <Button inlineStyles={{ width: 65 }}>
          <div className="flex items-center justify-center">
            {pathname === "/videos" ? (
              <>
                <span style={{ marginLeft: "0.1rem" }}>Posts</span>{" "}
              </>
            ) : (
              <>
                <span style={{ marginLeft: "0.1rem" }}>Videos</span>{" "}
              </>
            )}
          </div>
        </Button>
      </Link>
    </div>
  );
}
