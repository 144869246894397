import { useState, useEffect } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useMutation, useApolloClient, useQuery } from "@apollo/client";
import { Button, message, Divider } from "antd";
import { useTranslation } from "react-i18next";

import { useUser } from "shared/contexts/user-context";
import {
  GET_MASTERLIST_TOPICS,
  GET_PROFESSIONAL_INTERESTS,
  CREATE_MASTERLIST_TOPIC,
  CREATE_PROFESSIONAL_INTEREST,
  DELETE_PROFESSIONAL_INTEREST,
} from "shared/services";
import { LoadingSkeleton } from "shared/components";

export default function AddInterests({ changeToView, currentUserId }) {
  const { t } = useTranslation();
  const { user } = useUser();

  const { loading, data } = useQuery(GET_PROFESSIONAL_INTERESTS, {
    variables: { professionalId: currentUserId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  /* ---- Get already added interests ---- */
  const [addedInterests, setAddedInterests] = useState();
  useEffect(() => {
    if (!loading && data) {
      const interestTopicArr =
        data?.professional_interests.professional_interests.map((int) => {
          return {
            value: int.id,
            label: int.masterlist_topic.topic,
          };
        });
      setAddedInterests(interestTopicArr);
    }
  }, [loading]);

  /* ---- Handle interests actions ---- */
  const client = useApolloClient();
  const [createMasterlistTopic] = useMutation(CREATE_MASTERLIST_TOPIC);
  const [addProfessionalInterest] = useMutation(CREATE_PROFESSIONAL_INTEREST, {
    refetchQueries: [
      {
        query: GET_PROFESSIONAL_INTERESTS,
        variables: {
          professionalId: currentUserId,
        },
      },
    ],
  });
  const [deleteProfessionalInterest] = useMutation(
    DELETE_PROFESSIONAL_INTEREST,
    {
      onCompleted(data) {
        if (data.delete_professional_interest.deleted) {
          message.success(`${t("Interest successfully deleted")}`);
        }
      },
      refetchQueries: [
        {
          query: GET_PROFESSIONAL_INTERESTS,
          variables: {
            professionalId: currentUserId,
          },
        },
      ],
    }
  );

  const [interests, setInterests] = useState([]);
  const handleChange = (newValue, actionMeta) => {
    // Remove interest
    if (actionMeta.action === "remove-value") {
      deleteProfessionalInterest({
        variables: { id: actionMeta.removedValue.value },
      });
    }
    setInterests(newValue);
  };

  const handleCreateMasterlistTopics = async (newTopics) => {
    const newTopicIds = [];
    for (const topic of newTopics) {
      const { data } = await createMasterlistTopic({
        variables: { topic: topic.value },
      });
      newTopicIds.push(data.create_masterlist_topic?.masterlist_topic.id);
    }
    return newTopicIds;
  };

  const handleAddProfessionalInterest = async () => {
    // Go over already existing  interests, remove those
    const filterExistingInterests = interests.slice(addedInterests.length);

    // Newly entered ones
    // Not UUIDS
    const newTopics = filterExistingInterests.filter(
      (i) => i.value.length !== 36
    );

    // find one without ids
    const existingTopicIds = filterExistingInterests
      .filter((i) => i.value.length === 36)
      .map((i) => {
        return i.value;
      });

    // create new topics
    const newTopicIds = await handleCreateMasterlistTopics(newTopics);

    // merge existing and new topics to create interest
    const interestsToAdd = [...existingTopicIds, ...newTopicIds];

    for (const topic of interestsToAdd) {
      const { data } = await addProfessionalInterest({
        variables: {
          professionalId: user.id,
          masterlistTopicId: topic,
        },
      });
    }

    message.success(`${t("Interest successfully added")}`);
    changeToView();
  };

  const getTopics = async (inputValue) => {
    const { data } = await client.query({
      query: GET_MASTERLIST_TOPICS,
      variables: {
        topic: inputValue,
      },
    });

    return data
      ? data.masterlist_topics?.masterlist_topics.map((t) => ({
          value: t.id,
          label: t.topic,
        }))
      : [];
  };

  if (loading || !addedInterests) {
    return (
      <LoadingSkeleton
        props={{ count: 1, height: 40, style: { marginTop: "2rem" } }}
      />
    );
  }
  return (
    <div style={{ marginBottom: "2.5rem" }}>
      <Divider orientation="left">{t("edit interests")}</Divider>

      <AsyncCreatableSelect
        isMulti
        isClearable={false}
        defaultValue={addedInterests}
        loadOptions={getTopics}
        onChange={handleChange}
        placeholder={t("Select Interest")}
      />
      <div
        style={{
          marginTop: "1.5rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button type="secondary" onClick={() => changeToView()}>
          {t("cancel")}
        </Button>
        <Button type="primary" onClick={() => handleAddProfessionalInterest()}>
          {t("update interests")}
        </Button>
      </div>
    </div>
  );
}
