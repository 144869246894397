import { useEffect, useState } from "react";
import { useApolloClient, useQuery, useSubscription } from "@apollo/client";

import "shared/styles/app.global.scss";
import { Post, NoPostExists, PostSkeleton } from "shared/components";
import { useUser } from "shared/contexts/user-context";
import { useFeed } from "shared/contexts/feed-context";
import InfiniteScroll from "react-infinite-scroller";
import { POST_FEEDS, SUB_POST_FEED } from "shared/services";
import { getLocalToken } from "shared/utils/util-funcs";
import { useParams } from "react-router-dom";

export default function PostsFeed({ currentUserId = null }) {
  const { user } = useUser();
  const { userId } = useParams();
  console.log("🚀 ~ file: PostsFeed.js ~ line 16 ~ PostsFeed ~ userId", userId);
  const [{ posts }, dispatch] = useFeed();
  const [hasMore, setHasMore] = useState(false);
  const client = useApolloClient();
  const { data: subData, loading: subLoading } = useSubscription(
    SUB_POST_FEED,
    {
      variables: { token: getLocalToken("auth"), professional_id: user.id },
      // onSubscriptionData: ({ data }) => {
      //   const d = data?.post_feed?.post_feed;
      //   if (d.length > 0) {
      //     const res = d.map((item) => {
      //       const { post } = item;
      //       return {
      //         ...post,
      //       };
      //     });
      //     dispatch({ type: "ADD_POST", post: res });
      //   }
      // },
    }
  );

  useEffect(() => {
    if (!subLoading && subData) {
      const res = subData.post_feed.post_feed.map((item) => {
        const { post } = item;
        return {
          ...post,
        };
      });
      dispatch({ type: "ADD_POST", post: res });
    }
  }, [subLoading, subData]);

  const { loading, data } = useQuery(POST_FEEDS, {
    variables: {
      reverse: true,
      limit: 5,
    },
    // fetchPolicy: "cache-and-network",
  });

  const LoadMore = () => {
    client
      .query({
        query: POST_FEEDS,
        variables: {
          reverse: true,
          limit: 5,
          start: posts[posts.length - 1].serialNo,
        },
      })
      .then((res) => {
        const response = res.data.post_feeds.post_feeds.map((item) => {
          const { post, id } = item;
          return {
            ...post,
            serialNo: id,
          };
        });
        //REMOVING FIRST OBJECT BECAUSE ITS ALREADY EXIST IN POSTS
        response.shift();
        dispatch({
          type: "ADD_MORE_POSTS",
          posts: response,
        });
        setHasMore(res.data.post_feeds.post_feeds.length === 5);
      });
  };

  useEffect(() => {
    if (!loading && data) {
      const res = data.post_feeds.post_feeds.map((item) => {
        const { post, id } = item;
        return {
          ...post,
          serialNo: id,
        };
      });
      setHasMore(data.post_feeds?.total_count > 0);
      dispatch({
        type: "ADD_POSTS",
        posts: res,
      });
    }
  }, [loading, data]);

  return (
    <div className="feedContainer b-rad-4">
      {!loading && data?.post_feeds?.total_count === 0 ? (
        <NoPostExists />
      ) : (
        <InfiniteScroll
          pageStart={0}
          loadMore={LoadMore}
          initialLoad={false}
          hasMore={hasMore}
          threshold={10}
          isReverse={false}
          loader={
            <div style={{ marginBottom: "2rem" }}>
              <PostSkeleton post={{}} />
            </div>
          }
        >
          {posts.map((post) => (
            <Post key={post.id} post={post} />
          ))}
        </InfiniteScroll>
      )}
      {loading && <PostSkeleton post={{}} />}
    </div>
  );
}
