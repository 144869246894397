import { useState } from "react";
import { Avatar, Popover } from "antd";
import { MdPerson, MdMoreHoriz } from "react-icons/md";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useUser } from "shared/contexts/user-context";
import { formatRelativeTimeFromNow } from "shared/utils/util";
import {
  PostLikes,
  PostCommentButton,
  PostComments,
  LoadingSkeleton,
} from "shared/components";

import styles from "./post.module.scss";
import PostPrivacy from "./PostPrivacy";
import PostMenu from "./PostMenu";

export default function PostSkeleton({ post }) {
  const { t } = useTranslation();
  const { user } = useUser();

  const [postData, setPostData] = useState();

  const { image, content, created_at, status, professional_id, professional } =
    post;

  // check if one's own post
  const ownPost = user.id === post.professional_id;

  const [postMode, setPostMode] = useState();
  const handlePostMode = (status) => {
    setPopvisible(false);
    setPostMode(status);
  };

  // Programmitically control pop-over state
  const [popvisible, setPopvisible] = useState(false);
  const handleVisibleChange = (visible) => {
    setPopvisible(visible);
  };

  const [showComments, setShowComments] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.postTop}>
        {postData && professional ? (
          <>
            <Avatar
              src={null}
              icon={
                <MdPerson
                  style={{
                    marginTop: ".5rem",
                    height: "2rem",
                    width: "2rem",
                  }}
                />
              }
              style={{
                marginRight: ".5rem",
                height: "3rem",
                width: "3rem",
              }}
            />
            <div className={styles.postUser}>
              <Link to={`/user/${professional_id}`}>
                <p className={styles.username}>
                  {professional.last_name} {professional.first_name}
                </p>
              </Link>
              <div className={styles.postSubHeader}>
                <p className={styles.postTime}>
                  <time datetime={created_at} title={new Date(created_at)}>
                    {formatRelativeTimeFromNow(created_at)}
                  </time>
                </p>
                {ownPost && (
                  <>
                    <p style={{ marginRight: "2px", marginLeft: "2px" }}>·</p>
                    <PostPrivacy status={status} postId={post.id} />
                  </>
                )}
              </div>
            </div>
            <Popover
              placement="bottomRight"
              content={
                <PostMenu
                  post={post}
                  currentUser={user.id}
                  postUser={post.professional_id}
                  postMode={postMode}
                  handlePostMode={handlePostMode}
                />
              }
              trigger="click"
              visible={popvisible}
              onVisibleChange={handleVisibleChange}
            >
              <MdMoreHoriz
                style={{ fontSize: "1.5rem", opacity: ".9", cursor: "pointer" }}
              />
            </Popover>
          </>
        ) : (
          <LoadingSkeleton props={{ circle: true, height: 50, width: 50 }} />
        )}
      </div>

      <p className={styles.postText}>
        {content || <LoadingSkeleton props={{ count: 5 }} />}
      </p>
      {image && <img src={image} className={styles.postImage} />}

      <hr className={styles.divider} />
      {!postData?.content ? (
        <LoadingSkeleton />
      ) : (
        <div className={styles.postFooter}>
          <div className={styles.postActions}>
            <PostLikes post={postData} />
            <PostCommentButton
              post={postData}
              toggleShowComments={() => setShowComments(!showComments)}
            />
          </div>
        </div>
      )}
      {showComments && <PostComments />}
    </div>
  );
}
