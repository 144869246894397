import { useState, useEffect } from "react";
import { Avatar, Popover, Tag } from "antd";
import { MdPerson, MdMoreHoriz } from "react-icons/md";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import {
  VideoPlayer,
  FollowUser,
  PostLikes,
  PostCommentButton,
  PostComments,
  LoadingSkeleton,
} from "shared/components";

import { GET_VIDEO, GET_COMMENTS } from "shared/services";
import { useUser } from "shared/contexts/user-context";
import { formatRelativeTimeFromNow } from "shared/utils/util";

import VideoFavourite from "./VideoFavourite";
import VideoPostMenu from "./VideoPostMenu";
import VideoPostPrivacy from "./VideoPostPrivacy";

import styles from "./video-post.module.scss";

export default function VideoPost({
  video,
  videoId,
  refetchFavVids = () => {},
}) {
  const { t } = useTranslation();

  const { user } = useUser();

  const [videoPostData, setVideoPostData] = useState();

  const { loading, data, refetch } = useQuery(GET_VIDEO, {
    variables: { videoId: video.id },
    notifyOnNetworkStatusChange: true,
  });
  useEffect(() => {
    if (!loading && data) {
      setVideoPostData(data.video.video);
    }
  }, [loading, data]);

  const { created_at, professional_id, professional } = video;

  // check if one's own post
  const ownPost = user.id === video.professional_id;

  const [postMode, setPostMode] = useState();
  const handlePostMode = (status) => {
    setPopvisible(false);
    setPostMode(status);
  };

  /* ---- Commnents ---- */
  const {
    loading: commentsLoading,
    data: commentsData,
    refetch: refetchComments,
  } = useQuery(GET_COMMENTS, {
    variables: { videoId: video.id },
    notifyOnNetworkStatusChange: true,
  });

  // Programmitically control pop-over state
  const [popvisible, setPopvisible] = useState(false);
  const handleVisibleChange = (visible) => {
    setPopvisible(visible);
  };

  const [showComments, setShowComments] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.postTop}>
        {videoPostData ? (
          <>
            <Link to={`/user/${professional_id}`}>
              <Avatar
                src={professional.image}
                icon={
                  <MdPerson
                    style={{
                      marginTop: ".5rem",
                      height: "1.5rem",
                      width: "1.5rem",
                    }}
                  />
                }
                style={{
                  marginRight: ".5rem",
                  height: "2.5rem",
                  width: "2.5rem",
                }}
              />
            </Link>

            <div className={styles.postUser}>
              <Link to={`/user/${professional_id}`}>
                <p className={styles.username}>
                  {professional.last_name} {professional.first_name}
                </p>
              </Link>
              <div className={styles.postSubHeader}>
                <Link to={`/video/${video.id}`}>
                  <p className={styles.postTime}>
                    <time datetime={created_at} title={new Date(created_at)}>
                      {formatRelativeTimeFromNow(created_at)}
                    </time>
                  </p>
                </Link>
                {ownPost && (
                  <>
                    <p style={{ marginRight: "2px", marginLeft: "2px" }}>·</p>
                    <VideoPostPrivacy
                      status={videoPostData.privacy_policy}
                      videoPostId={video.id}
                      refetchVideoPost={refetch}
                    />
                  </>
                )}
              </div>
            </div>
            {ownPost ? (
              <Popover
                placement="bottomRight"
                content={
                  <VideoPostMenu
                    video={videoPostData}
                    currentUser={user.id}
                    postUser={video.professional_id}
                    postMode={postMode}
                    handlePostMode={handlePostMode}
                  />
                }
                trigger="click"
                visible={popvisible}
                onVisibleChange={handleVisibleChange}
              >
                <MdMoreHoriz
                  style={{
                    fontSize: "1.5rem",
                    opacity: ".9",
                    cursor: "pointer",
                  }}
                />
              </Popover>
            ) : (
              <FollowUser />
            )}
          </>
        ) : (
          <LoadingSkeleton props={{ circle: true, height: 50, width: 50 }} />
        )}
      </div>
      <div className={styles.videoHeader}>
        <h1>{videoPostData?.title || <LoadingSkeleton />}</h1>
        {videoPostData && (
          <VideoPlayer video={videoPostData} videoId={videoId} />
        )}

        <p className={styles.postText}>
          {videoPostData?.description || <LoadingSkeleton />}
        </p>
        <div className={styles.tagContainer}>
          {videoPostData &&
            videoPostData.tags.map((t) => (
              <Tag>{t.masterlist_topic.topic}</Tag>
            ))}
        </div>
      </div>

      <hr className={styles.divider} />

      {!videoPostData?.title || !commentsData ? (
        <LoadingSkeleton />
      ) : (
        <div className={styles.postFooter}>
          <div className={styles.postActions}>
            <div className={styles.postActions}>
              <PostLikes video={videoPostData} />
              <PostCommentButton
                toggleShowComments={() => setShowComments(!showComments)}
                totalComments={commentsData?.comments}
              />
              <VideoFavourite
                post={videoPostData}
                refetchFavVids={refetchFavVids}
              />
            </div>
          </div>
        </div>
      )}

      {showComments && (
        <PostComments
          video={videoPostData}
          toggleShowComments={() => setShowComments(!showComments)}
          refetchTotalComments={refetchComments}
        />
      )}
    </div>
  );
}
