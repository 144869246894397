import { useQuery } from "@apollo/client";

import "shared/styles/app.global.scss";
import { useUser } from "shared/contexts/user-context";
import { VideoPost, NoPostExists, PostSkeleton } from "shared/components";

import { GET_VIDEOS } from "shared/services";

export default function VideoPosts({ currentUserId }) {
  const { user } = useUser();

  const { loading, data } = useQuery(GET_VIDEOS, {
    variables: {
      professionalId: currentUserId || user.id,
      status: "published",
      sort: "created_at",
      order: "DESC",
      limit: 10,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  return (
    <div className="feedContainer b-rad-4">
      {!loading && data?.videos.videos.length === 0 ? (
        <NoPostExists />
      ) : (
        data?.videos.videos.map((video) => (
          <VideoPost key={video.id} video={video} videoId={video.id} />
        ))
      )}
      {loading && <PostSkeleton post={{}} />}
    </div>
  );
}
