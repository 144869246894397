import { gql } from "@apollo/client";

export const GET_SLOTS = gql`
  query Slots(
    $offset: Int
    $limit: Int
    $campaignId: UUID
    $bannerId: UUID
    $bannerPositionId: UUID
    $price: Int
    $date: Date
    $maxDate: Date
    $minDate: Date
    $status: CampaignStatus
    $order: SortOrder
    $sort: String
  ) {
    slots(
      offset: $offset
      limit: $limit
      campaign_id: $campaignId
      banner_id: $bannerId
      banner_position_id: $bannerPositionId
      price: $price
      date: $date
      max_date: $maxDate
      min_date: $minDate
      status: $status
      order: $order
      sort: $sort
    ) {
      slots {
        id
        date
        status
        banner_position {
          id
          position
        }
        banner {
          id
          name
          link
          image
        }
        trackings {
          id
          created_at
          user_id
          action
        }
      }
      total_count
      error
    }
  }
`;

export const CREATE_SLOT_TRACKING = gql`
  mutation CreateSlotTracking(
    $userId: String!
    $slotId: String!
    $action: SlotTrackingAction!
    $deviceType: DeviceType!
  ) {
    create_slot_tracking(
      details: {
        user_id: $userId
        slot_id: $slotId
        action: $action
        device_type: $deviceType
      }
    ) {
      error
      slot_tracking {
        id
      }
    }
  }
`;
