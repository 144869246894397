import moment from "moment";
import "moment/locale/ja";

import { getLocalToken } from "shared/utils/util-funcs";

const getLocaleForTime = () => {
  const localLang = getLocalToken("locale");
  // Current selected lang by user or User stored one or eng
  let currentLang = localLang || "en";

  // ja for moment locale match
  currentLang = currentLang === "jp" ? "ja" : currentLang;

  return currentLang;
};

export const formatRelativeTimeFromNow = (time) => {
  const currentLang = getLocaleForTime();
  const momentLocale = moment(time);

  const result = momentLocale.locale(currentLang).local().fromNow();

  return result;
};

export const formatToLocalTime = (utcTime, formatStr = "LLL") => {
  const currentLang = getLocaleForTime();
  const momentLocale = moment(utcTime);

  const resTime = momentLocale.locale(currentLang).local().format(formatStr);
  return resTime;
};

function isEmptyObj(obj) {
  return Object.keys(obj).length === 0;
}

export const createMessageGroup = (
  data,
  currentGroupId = "",
  currentGroupObj = {}
) => {
  let groupObj = {};

  data.map((d) => {
    if (!currentGroupId.includes(d.sent_by)) {
      currentGroupId = `${d.sent_by}#${d.created_at}`;
      // Check if the message group is on a different day altogether
      const dateToCheck = formatToLocalTime(d.created_at, "YYYY-MM-DD");
      if (
        isEmptyObj(groupObj)
          ? !Object.keys(currentGroupObj).some((p) => p.includes(dateToCheck))
          : !Object.keys(groupObj).some((p) => p.includes(dateToCheck))
      ) {
        currentGroupId = `${currentGroupId}#new#${dateToCheck}`;
      }

      groupObj[currentGroupId] = [];
      groupObj[currentGroupId].push(d);
    } else {
      groupObj[currentGroupId].push(d);
    }
  });

  return groupObj;
};

export const getChatUserDetail = (detailObj, userKey, detailKey) => {
  return detailObj[userKey]?.[detailKey] || "";
};

// awaiting_payment -> Awaiting Payment
// https://www.digitalocean.com/community/tutorials/js-capitalizing-strings
export const formatStatusStr = (str) => {
  return str
    .split("_")
    .map((i) =>
      i.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
    )
    .join(" ");
};

/* ---- Create the data-structure needed for cascaded specialisation ---- */
const IsKeyExists = (arrToCheck, value) => {
  return arrToCheck?.some((data) => data["value"] === value);
};

const createPushChildrenNode = (arrayToPushInto, value) => {
  if (!arrayToPushInto?.children) {
    arrayToPushInto.children = [
      {
        value: value,
        label: value,
      },
    ];
  } else {
    return arrayToPushInto["children"].push({ value: value, label: value });
  }
};

const createRootNode = (arrayToPushInto, value) => {
  arrayToPushInto.push({
    value: value,
    label: value,
  });
};

const getPosition = (arrToCheck, valueToCheck) => {
  return arrToCheck.findIndex((d) => d.value === valueToCheck);
};

export const loopOverCascade = (base) => {
  let rootArr = [];

  base.map((node) => {
    let majorPos, middlePos, subPos, specificPos;

    // // node.middle_classification
    // if (!node.middle_classification) {
    //   return;
    // }

    // majorPos = getPosition(rootArr, node.major_classification);
    // // Check if exists in that major cs. children
    // // if yes, just note the pos
    // // if no, push to children, if no children, create children and push
    // if (!IsKeyExists(rootArr[majorPos]?.children, node.middle_classification)) {
    //   createPushChildrenNode(rootArr[majorPos], node.middle_classification);
    // }

    // node.middle_classification
    if (!IsKeyExists(rootArr, node.middle_classification)) {
      createRootNode(rootArr, node.middle_classification);
    }

    // node.sub_category
    if (!node.sub_category || node.sub_category === "null") {
      return;
    }

    middlePos = getPosition(rootArr, node.middle_classification);
    // middlePos = getPosition(
    //   rootArr[majorPos].children,
    //   node.middle_classification
    // );
    if (!IsKeyExists(rootArr[middlePos]?.children, node.sub_category)) {
      createPushChildrenNode(rootArr[middlePos], node.sub_category);
    }

    // node.specific_category
    if (!node.specific_category) {
      return;
    }

    middlePos = getPosition(rootArr, node.middle_classification);
    subPos = getPosition(rootArr[middlePos].children, node.sub_category);

    if (
      !IsKeyExists(
        rootArr[middlePos]?.children[subPos].children,
        node.specific_category
      )
    ) {
      createPushChildrenNode(
        rootArr[middlePos].children[subPos],
        node.specific_category
      );
    }
  });

  return rootArr;
};

export const getProfFromSpecialisation = (specialisation) => {
  const profs = specialisation.map((s) => s.major_classification);
  const uniqueProfs = new Set([...profs]);
  const selectObj = [...uniqueProfs].map((p) => {
    return { value: p, label: p };
  });
  return selectObj;
};

export const filterMatchedProfFromSpecialisation = (selectedProf, allSpecs) => {
  const filterByProf = allSpecs.filter(
    (spec) => spec.major_classification === selectedProf
  );

  return filterByProf;
};

export const isMobileView = () => {
  return window.screen.availWidth < 500;
};

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?>&#^~`/._+()-=]{1,}$/;
