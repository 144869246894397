import { useQuery } from "@apollo/client";

import "shared/styles/app.global.scss";
import { useUser } from "shared/contexts/user-context";
import { VideoPost, NoPostExists, PostSkeleton } from "shared/components";

import { GET_FAVOURITE_VIDEOS } from "shared/services";

export default function VideoPosts({ currentUserId }) {
  const { user } = useUser();

  const {
    loading,
    data,
    refetch: refetchFavVids,
  } = useQuery(GET_FAVOURITE_VIDEOS, {
    variables: {
      professionalId: currentUserId || user.id,
      sort: "created_at",
      order: "DESC",
      limit: 10,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  return (
    <div className="feedContainer b-rad-4">
      {!loading && data?.favourite_videos.favourite_videos.length === 0 ? (
        <NoPostExists text="You have no favourite videos" />
      ) : (
        data?.favourite_videos.favourite_videos.map((fav) => (
          <VideoPost
            key={fav.video.id}
            video={fav.video}
            refetchFavVids={refetchFavVids}
          />
        ))
      )}
      {loading && <PostSkeleton post={{}} />}
    </div>
  );
}
