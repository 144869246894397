import React from "react";
import { Spin } from "antd";
import { AiOutlineLoading } from "react-icons/ai";

import styles from "./loading-spinner.module.scss";

type LoadingSpinnerProps = {
  size?: number;
  color?: string;
};

const LoadingSpinner = ({
  size = 24,
  color = "primary",
}: LoadingSpinnerProps) => {
  let colorStyle = "#3385ff";

  if (color === "secondary") {
    colorStyle = "#3c4257";
  } else if (color === "secondary-white") {
    colorStyle = "#f7fafc";
  }

  return (
    <Spin
      style={{ lineHeight: 0 }}
      indicator={
        <AiOutlineLoading
          className={styles.spinner}
          style={{ fontSize: size, color: colorStyle, fontWeight: "bold" }}
        />
      }
    />
  );
};

export default LoadingSpinner;
