import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Button, message } from "antd";
import { useQuery, useMutation } from "@apollo/client";

import { useUser } from "shared/contexts/user-context";
import { GET_FOLLOWS, CREATE_FOLLOW, DELETE_FOLLOW } from "shared/services";

import styles from "./styles.module.scss";

export default function FollowUser({ currentUserId }) {
  const { t } = useTranslation();
  const { user } = useUser();

  const [followedByMe, setFollowedByMe] = useState();
  const [followId, setfollowId] = useState();

  const { data, refetch, loading } = useQuery(GET_FOLLOWS, {
    variables: { followingId: currentUserId, professionalId: user.id },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!loading && data) {
      setFollowedByMe(Boolean(data?.professional_follows.total_count === 1));
      setfollowId(data?.professional_follows.professional_follows[0]?.id);
    }
  }, [loading]);

  const [createFollow, { loading: createFollowLoading }] = useMutation(
    CREATE_FOLLOW,
    {
      onCompleted(data) {
        if (data.create_professional_follow.error) {
          message.error(data.create_professional_follow.error);
        } else {
          refetch();
        }
      },
      refetchQueries: [
        {
          query: GET_FOLLOWS,
          variables: {
            professionalId: currentUserId,
          },
        },
        {
          query: GET_FOLLOWS,
          variables: {
            followingId: currentUserId,
          },
        },
      ],
    }
  );

  const [deleteFollow, { loading: deleteFollowLoading }] = useMutation(
    DELETE_FOLLOW,
    {
      onCompleted(data) {
        if (data.delete_professional_follow.error) {
          message.error(data.delete_professional_follow.error);
        } else {
          refetch();
        }
      },
      refetchQueries: [
        {
          query: GET_FOLLOWS,
          variables: {
            professionalId: currentUserId,
          },
        },
        {
          query: GET_FOLLOWS,
          variables: {
            followingId: currentUserId,
          },
        },
      ],
    }
  );

  return (
    <Button
      type="primary"
      style={{ marginRight: ".5rem" }}
      className={clsx(styles.followBtn, styles.following)}
      disabled={deleteFollowLoading || createFollowLoading}
      onClick={() => {
        if (!followedByMe) {
          createFollow({ variables: { followingId: currentUserId } });
        } else {
          deleteFollow({
            variables: { id: followId },
          });
        }
      }}
    >
      {followedByMe ? `${t("unfollow")}` : `${t("follow")}`}
    </Button>
  );
}
