import { gql } from "@apollo/client";

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($userId: UUID!, $code: String!, $user_group: String!) {
    verify_email(user_id: $userId, code: $code, user_group: $user_group) {
      error
      verification_code_matched
    }
  }
`;
