import { Link } from "react-router-dom";
import { MdPlayCircleFilled } from "react-icons/md";
import { useTranslation } from "react-i18next";

import styles from "./video-section.module.scss";
import { NEW_VIDEO_FEEDS } from "shared/services";
import { useQuery } from "@apollo/client";
import LoadingSkeleton from "../LoadingSkeleton/LoadingSkeleton";
import NoPostExists from "../NoPostExists/NoPostExists";
import { useEffect } from "react";
import { formatRelativeTimeFromNow } from "shared/utils/util";
import { REST_VIDEO_API_ENDPOINT } from "config";
import { getLocalToken } from "shared/utils/util-funcs";
import axios from "axios";

export default function VideoSection({ title, data = [], loading }) {
  console.log(
    "🚀 ~ file: VideoSection.js ~ line 17 ~ VideoSection ~ data",
    data
  );
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t(title)}</h2>
      <div className={styles.scrollContainer}>
        {loading ? (
          <LoadingSkeleton count={5} />
        ) : data.length > 0 ? (
          data.map((video) => (
            <VideoSectionCard
              title={video.title}
              thumbnail={video.thumbnail_link}
              postedAt={video.created_at}
              key={video.id}
              id={video.id}
            />
          ))
        ) : (
          <NoPostExists text="No video exist" />
        )}
      </div>
    </div>
  );
}

function VideoSectionCard({ title, id, postedAt, thumbnail }) {
  return (
    <div className={styles.similarCardContainer}>
      <Link to={`/video/${id}`}>
        <div className={styles.videoCard}>
          <div
            className={styles.videoThumbnail}
            style={{ backgroundImage: `url(${thumbnail})` }}
          >
            <MdPlayCircleFilled className={styles.playIcon} />
          </div>
          <div className={styles.videoDetail}>
            <p>{title}</p>
            <p className={styles.postTime}>
              <time dateTime={postedAt} title={new Date(postedAt)}>
                {formatRelativeTimeFromNow(postedAt)}
              </time>
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
}
