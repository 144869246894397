import { useState } from "react";
import { Avatar } from "antd";
import clsx from "clsx";
import { MdPerson } from "react-icons/md";
import { useTranslation } from "react-i18next";

import "shared/styles/app.global.scss";
import { useUser } from "shared/contexts/user-context";

import styles from "./create-video-post.module.scss";
import CreateVideoPostModal from "./CreateVideoPostModal";
import PostToggleButton from "../PostToggleButton/PostToggleButton";

export default function CreateVideoPost() {
  const { t } = useTranslation();
  const { user } = useUser();

  const [isCreatePostModalOpen, setIsCreatePostModalOpen] = useState(false);

  return (
    <>
      {isCreatePostModalOpen && (
        <CreateVideoPostModal
          isOpen={isCreatePostModalOpen}
          onClose={() => setIsCreatePostModalOpen(false)}
        />
      )}

      <div
        className={clsx("b-rad-4", "feedContainer", "container")}
        onClick={() => setIsCreatePostModalOpen(true)}
      >
        <div className={styles.clickContainer}>
          {" "}
          <Avatar
            src={user.image}
            icon={
              <MdPerson
                style={{
                  marginTop: ".25rem",
                }}
              />
            }
            style={{
              cursor: "pointer",
            }}
          />
          <div className={styles.createContainer}>
            <h3>{t("upload video")}</h3>
          </div>
          <PostToggleButton />
        </div>
      </div>
    </>
  );
}
