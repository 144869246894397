import Logo from "assets/images/logo.svg";
import { useUser } from "shared/contexts/user-context";
import { LocaleSelector } from "shared/components";

import styles from "./auth-navbar.module.scss";
import Logout from "./Logout";

export default function AuthNavbar() {
  const { user } = useUser();

  return (
    <div className={styles.navContainer}>
      <div className={styles.navbar}>
        <div>
          {/* <div className={styles.logoLink}> */}
          <img src={Logo} alt="meem  logo" className={styles.logo} />
          {/* </div> */}
        </div>
        <div className={styles.rightContainer}>
          <p className={styles.userFullName}>{user.first_name}</p>
          <Logout />
          <LocaleSelector textOnly style={{ marginLeft: "0.5rem" }} />
        </div>
      </div>
    </div>
  );
}
