import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import styles from "./password-input.module.scss";
import { useTranslation } from "react-i18next";

export default function RepeatPasswordInput({
  register,
  errors,
  tabIndex,
  value,
  onChange = () => {},
  showForgotPassword = false,
  width,
  label = "Confirm password",
  name = "repeatpassword",
}) {
  const { t } = useTranslation();

  /* ---- Toggle password visibility ---- */
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <div
      className={clsx(styles.formField, width === "half" && styles.halfWidth)}
    >
      <div className={styles.passwordField}>
        <div>
          <label htmlFor="repeatpassword">{t(label)}</label>
          {errors.repeatpassword && (
            <span className={styles.formErrortext}>
              {t(errors.repeatpassword.message)}
            </span>
          )}
        </div>
        {showForgotPassword && (
          <Link
            to="/forgot-password"
            tabIndex={tabIndex + 1}
            className={styles.link}
          >
            {t("forgot your password?")}
          </Link>
        )}
      </div>

      <div className={`flex ${styles.passwordFieldDisplay}`}>
        <input
          tabIndex={tabIndex}
          id={name}
          name={name}
          // autoComplete="off"
          autocomplete="new-password"
          // type={isPasswordVisible ? "text" : "password"}
          type="password"
          label={t("Repeat Password")}
          ref={register}
          value={value}
          onChange={onChange(name)}
          className={clsx(styles.passwordInput, {
            [styles.formFieldError]: errors?.name,
          })}
        />
      </div>
    </div>
  );
}
