import { gql } from "@apollo/client";

export const UPDATE_POST = gql`
  mutation UpdatePost(
    $postId: UUID!
    $content: String
    $status: PostStatusUpdation
    $tags: [UUID]
  ) {
    update_post(
      id: $postId
      details: { content: $content, status: $status }
      tags: $tags
    ) {
      error
      post {
        id
        content
        status
        image
        image_uploaded
      }
    }
  }
`;

export const DELETE_POST_TAGS = gql`
  mutation DeletePostTags($postId: UUID!, $tags: [UUID!]!) {
    delete_post_tags(id: $postId, tags: $tags) {
      error
      post {
        id
        content
        status
        image
        image_uploaded
      }
    }
  }
`;
