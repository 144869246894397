import { gql } from "@apollo/client";

/* ---- AUTHENTICATION ---- */
export const VALIDATE = gql`
  query Validate {
    validate {
      error
      user {
        id
        group
      }
    }
  }
`;

/* ---- USER ---- */
export const GET_USER = gql`
  query professional($userId: UUID!, $reverse: Boolean) {
    professional(id: $userId) {
      error
      professional {
        id
        user_name
        user_name
        group
        email
        phone_cc
        phone_no
        first_name
        middle_name
        last_name
        profession
        designation
        organization
        specialisation
        email_confirmed
        gender
        bio
        DOB
        status
        status_description
        active
        created_at
        updated_at
        gender
        office_country
        office_state
        office_city
        office_address
        residency_country
        residency_state
        residency_city
        residency_address
        status
        status_description
        licence_no
        graduation_date
        docs {
          id
          doc_type
          status
          status_description
        }
        sessions(reverse: $reverse) {
          id
          created_at
          event
        }
        followings {
          id
          professional_id
          following_id
        }
        followers {
          id
          professional_id
          following_id
        }
        image
        stripe_customer_id
        payment_method_exists
        default_payment_method
        payment_methods {
          id
          card {
            brand
            country
            exp_month
            exp_year
            last4
          }
        }
      }
    }
  }
`;

export const GET_USER_STORAGE = gql`
  query professional($userId: UUID!) {
    professional(id: $userId) {
      error
      professional {
        id
        available_storage
      }
    }
  }
`;

/* ---- MASTERLISTS ---- */
export const GET_MASTERLIST_SPECIALISATIONS = gql`
  query MasterlistSpecialisations(
    $offset: Int
    $limit: Int
    $majorClassification: String
    $middleClassification: String
    $subCategory: String
    $specificCategory: String
    $order: SortOrder
    $sort: String
  ) {
    masterlist_specialisations(
      offset: $offset
      limit: $limit
      major_classification: $majorClassification
      middle_classification: $middleClassification
      sub_category: $subCategory
      specific_category: $specificCategory
      order: $order
      sort: $sort
    ) {
      error
      masterlist_specialisations {
        id
        major_classification
        middle_classification
        sub_category
        specific_category
        locale
      }
      total_count
    }
  }
`;

export const GET_MASTERLIST_COUNTRIES = gql`
  query MasterlistCountries(
    $offset: Int
    $limit: Int
    $country: String
    $locale: String
    $order: SortOrder
    $sort: String
  ) {
    masterlist_countries(
      offset: $offset
      limit: $limit
      order: $order
      sort: $sort
      country: $country
      locale: $locale
    ) {
      error
      masterlist_countries {
        id
        country
        locale
        states {
          id
          country_id
          state
          locale
        }
      }
      total_count
    }
  }
`;

export const GET_MASTERLIST_STATES = gql`
  query MasterlistStates(
    $offset: Int
    $limit: Int
    $countryId: UUID
    $state: String
    $locale: String
    $order: SortOrder
    $sort: String
  ) {
    masterlist_states(
      offset: $offset
      limit: $limit
      country_id: $countryId
      order: $order
      sort: $sort
      state: $state
      locale: $locale
    ) {
      error
      masterlist_states {
        id
        country_id
        state
      }
      total_count
    }
  }
`;

/* PROFESSIONAL INTERESTS */
export const GET_PROFESSIONAL_INTERESTS = gql`
  query GetProfessionalInterests(
    $offset: Int
    $limit: Int
    $professionalId: UUID
    $masterlistTopicId: UUID
    $order: SortOrder
    $sort: String
  ) {
    professional_interests(
      offset: $offset
      limit: $limit
      professional_id: $professionalId
      masterlist_topic_id: $masterlistTopicId
      order: $order
      sort: $sort
    ) {
      error
      professional_interests {
        id
        masterlist_topic_id
        masterlist_topic {
          id
          topic
        }
      }
      total_count
    }
  }
`;

/* MASTERLIST TOPICS */
export const GET_MASTERLIST_TOPICS = gql`
  query GetMasterlistTopics(
    $offset: Int
    $limit: Int
    $topic: String
    $order: SortOrder
    $sort: String
  ) {
    masterlist_topics(
      offset: $offset
      limit: $limit
      topic: $topic
      order: $order
      sort: $sort
    ) {
      error
      masterlist_topics {
        id
        topic
      }
      total_count
    }
  }
`;

export const GET_POSTS = gql`
  query GetPosts(
    $offset: Int
    $limit: Int
    $professionalId: UUID
    $imageUploaded: Boolean
    $status: PostStatus
    $minCreatedAt: Datetime
    $maxCreatedAt: Datetime
    $order: SortOrder
    $sort: String
  ) {
    posts(
      offset: $offset
      limit: $limit
      professional_id: $professionalId
      image_uploaded: $imageUploaded
      status: $status
      order: $order
      sort: $sort
      min_created_at: $minCreatedAt
      max_created_at: $maxCreatedAt
    ) {
      error
      posts {
        id
        professional_id
        professional {
          id
          first_name
          last_name
          image
        }
        content
        image
        image_uploaded
        created_at
        updated_at
        privacy_policy
        likes {
          id
          professional_id
          post_id
        }
        comments {
          id
          created_at
        }
        tags {
          id
          masterlist_topic_id
        }
        status
        status_description
      }
      total_count
    }
  }
`;

export const GET_POST = gql`
  query GetPost($postId: UUID!) {
    post(id: $postId) {
      error
      post {
        id
        professional_id
        professional {
          id
          first_name
          last_name
          image
        }
        content
        image
        image_uploaded
        created_at
        updated_at
        privacy_policy
        tags {
          id
          masterlist_topic_id
        }
        status
        status_description
      }
    }
  }
`;

export const GET_VIDEOS = gql`
  query GetVideos(
    $offset: Int
    $limit: Int
    $professionalId: UUID
    $allowAdvertisementUse: Boolean
    $status: VideoStatus
    $minCreatedAt: Datetime
    $maxCreatedAt: Datetime
    $order: SortOrder
    $sort: String
  ) {
    videos(
      offset: $offset
      limit: $limit
      professional_id: $professionalId
      allow_advertisement_use: $allowAdvertisementUse
      status: $status
      order: $order
      sort: $sort
      min_created_at: $minCreatedAt
      max_created_at: $maxCreatedAt
    ) {
      error
      videos {
        id
        professional_id
        professional {
          id
          first_name
          last_name
          image
        }
        title
        description
        allow_advertisement_use
        created_at
        updated_at
        status
        status_description
        privacy_policy
        tags {
          id
          masterlist_topic {
            id
            topic
          }
        }
      }
    }
  }
`;

export const GET_VIDEO = gql`
  query GetVideo($videoId: UUID!) {
    video(id: $videoId) {
      error
      video {
        id
        professional_id
        professional {
          id
          first_name
          last_name
          image
        }
        title
        description
        allow_advertisement_use
        created_at
        updated_at
        privacy_policy
        status
        status_description
        tags {
          id
          masterlist_topic {
            id
            topic
          }
        }
      }
    }
  }
`;

/* LIKES */
export const GET_LIKES = gql`
  query GetLikes(
    $offset: Int
    $limit: Int
    $postId: UUID
    $videoId: UUID
    $professionalId: UUID
    $minCreatedAt: Datetime
    $maxCreatedAt: Datetime
    $order: SortOrder
    $sort: String
  ) {
    likes(
      offset: $offset
      limit: $limit
      post_id: $postId
      video_id: $videoId
      professional_id: $professionalId
      min_created_at: $minCreatedAt
      max_created_at: $maxCreatedAt
      order: $order
      sort: $sort
    ) {
      error
      total_count
      likes {
        id
        professional_id
        created_at
      }
    }
  }
`;

/* ---- COMMENTS ---- */
export const GET_COMMENTS = gql`
  query GetComments(
    $offset: Int
    $limit: Int
    $postId: UUID
    $videoId: UUID
    $professionalId: UUID
    $commentId: UUID
    $minCreatedAt: Datetime
    $maxCreatedAt: Datetime
    $order: SortOrder
    $sort: String
  ) {
    comments(
      offset: $offset
      limit: $limit
      post_id: $postId
      video_id: $videoId
      professional_id: $professionalId
      comment_id: $commentId
      min_created_at: $minCreatedAt
      max_created_at: $maxCreatedAt
      order: $order
      sort: $sort
    ) {
      error
      total_count
      comments {
        id
        professional_id
        created_at
        content
        professional {
          id
          first_name
          last_name
          image
        }
      }
    }
  }
`;

/* ---- FAVOURITE VIDEOS ---- */
export const GET_FAVOURITE_VIDEOS = gql`
  query GetFavouriteVideos(
    $offset: Int
    $limit: Int
    $videoId: UUID
    $professionalId: UUID
    $minCreatedAt: Datetime
    $maxCreatedAt: Datetime
    $order: SortOrder
    $sort: String
  ) {
    favourite_videos(
      offset: $offset
      limit: $limit
      video_id: $videoId
      professional_id: $professionalId
      min_created_at: $minCreatedAt
      max_created_at: $maxCreatedAt
      order: $order
      sort: $sort
    ) {
      error
      total_count
      favourite_videos {
        id
        video {
          id
          professional_id
          professional {
            id
            first_name
            last_name
            image
          }
          title
          description
          allow_advertisement_use
          created_at
          updated_at
          privacy_policy
          status
          status_description
        }
      }
    }
  }
`;

/* ---- FOLLOWS ---- */
export const GET_FOLLOWS = gql`
  query GetFollows(
    $offset: Int
    $limit: Int
    $professionalId: UUID
    $followingId: UUID
    $minCreatedAt: Datetime
    $maxCreatedAt: Datetime
    $order: SortOrder
    $sort: String
  ) {
    professional_follows(
      offset: $offset
      limit: $limit
      professional_id: $professionalId
      following_id: $followingId
      min_created_at: $minCreatedAt
      max_created_at: $maxCreatedAt
      order: $order
      sort: $sort
    ) {
      error
      total_count
      professional_follows {
        id
        professional_id
        following_id
        created_at
      }
    }
  }
`;

/* CHAT */
export const GET_CHATROOMS = gql`
  query GetChatRooms(
    $offset: Int
    $limit: Int
    $minCreatedAt: Datetime
    $maxCreatedAt: Datetime
    $order: SortOrder
    $sort: String
    $participantId: UUID
    $initiatorId: UUID
    $participant1Id: UUID
    $participant2Id: UUID
    $accepted: Boolean
  ) {
    professional_chatrooms(
      offset: $offset
      limit: $limit
      min_created_at: $minCreatedAt
      max_created_at: $maxCreatedAt
      order: $order
      sort: $sort
      participant_id: $participantId
      initiator_id: $initiatorId
      participant_1_id: $participant1Id
      participant_2_id: $participant2Id
      accepted: $accepted
    ) {
      error
      total_count
      professional_chatrooms {
        id
        participant_id
        initiator_id
        accepted
        created_at
        updated_at
      }
    }
  }
`;

export const GET_CHATROOM_MESSAGES = gql`
  query GetChatRoomMessages(
    $professionalChatroomId: UUID!
    $start: String
    $stop: String
    $limit: Int
    $reverse: Boolean
  ) {
    professional_chatroom_messages(
      professional_chatroom_id: $professionalChatroomId
      start: $start
      stop: $stop
      reverse: $reverse
      limit: $limit
    ) {
      error
      professional_chatroom_messages {
        id
        professional_chatroom_id
        message
        sent_by
        seen
        message_type
        professional_chatroom_file_id
        created_at
        seen_at
        professional_chatroom_file {
          id
          professional_id
          professional_chatroom_id
          file_extension
          file_uploaded
          created_at
          updated_at
          file
        }
      }
    }
  }
`;

/* REPORT POST/VIDEO */

export const GET_POST_REPORTS = gql`
  query GetPostReports($postId: UUID, $videoId: UUID, $reporterId: UUID) {
    post_video_reports(
      post_id: $postId
      video_id: $videoId
      reporter_id: $reporterId
    ) {
      error
      post_video_reports {
        id
      }
      total_count
    }
  }
`;

export const GET_STORAGE_TIERS = gql`
  query getStorageTier(
    $offset: Int
    $limit: Int
    $name: String
    $total_units: Int
    $monthly_price: Int
    $yearly_price: Int
    $stripe_product_id: String
    $min_created_at: Datetime
    $max_created_at: Datetime
    $order: SortOrder
    $sort: String
  ) {
    storage_tiers(
      offset: $offset
      limit: $limit
      name: $name
      total_units: $total_units
      monthly_price: $monthly_price
      yearly_price: $yearly_price
      stripe_product_id: $stripe_product_id
      min_created_at: $min_created_at
      max_created_at: $max_created_at
      order: $order
      sort: $sort
    ) {
      error
      storage_tiers {
        id
        name
        total_units
        monthly_price
        yearly_price
        active
        stripe_product_id
        created_at
        updated_at
      }
      total_count
    }
  }
`;

export const EXISTING_SUBSCRIPTIONS = gql`
  query getExistingSub(
    $offset: Int
    $limit: Int
    $professional_id: UUID
    $storage_tier_id: UUID
    $transaction_id: UUID
    $valid_till: Date
    $status: ProfessionalSubscriptionStatus
    $subscription_type: ProfessionalSubscriptionType
    $min_created_at: Datetime
    $max_created_at: Datetime
    $order: SortOrder
    $sort: String
  ) {
    professional_subscriptions(
      offset: $offset
      limit: $limit
      professional_id: $professional_id
      storage_tier_id: $storage_tier_id
      transaction_id: $transaction_id
      valid_till: $valid_till
      status: $status
      subscription_type: $subscription_type
      min_created_at: $min_created_at
      max_created_at: $max_created_at
      order: $order
      sort: $sort
    ) {
      error
      professional_subscriptions {
        id
        professional_id
        storage_tier_id
        storage_tier_quantity
        valid_till
        status
        subscription_type
        stripe_subscription_id
        created_at
        updated_at
        status_description
      }
      total_count
    }
  }
`;

export const POST_FEEDS = gql`
  query postFeeds(
    $start: String
    $stop: String
    $limit: Int
    $reverse: Boolean
  ) {
    post_feeds(start: $start, stop: $stop, limit: $limit, reverse: $reverse) {
      error
      post_feeds {
        id
        created_at
        post_id
        post {
          id
          content
          professional_id
          created_at
          status
        }
      }
      total_count
    }
  }
`;

export const NEW_VIDEO_FEEDS = gql`
  query videoFeeds(
    $start: String
    $stop: String
    $limit: Int
    $reverse: Boolean
  ) {
    video_feeds(start: $start, stop: $stop, limit: $limit, reverse: $reverse) {
      error
      video_feeds {
        id
        created_at
        video_id
        video {
          created_at
          id
          professional_id
          title
          thumbnail_link
        }
      }
      total_count
    }
  }
`;

export const TRANSACTIONS = gql`
  query getTransactions(
    $offset: Int
    $limit: Int
    $order: SortOrder
    $sort: String
    $user_id: UUID
  ) {
    transactions(
      offset: $offset
      limit: $limit
      order: $order
      sort: $sort
      user_id: $user_id
    ) {
      error
      total_count
      transactions {
        id
        user_id
        transaction_type
        status
        created_at
        updated_at
        ref_id
        amount
      }
    }
  }
`;

export const NOTIFICATIONS = gql`
  query getNotifications($professional_id: UUID!) {
    get_notification(professional_id: $professional_id) {
      error
      notifications {
        id
        post_id
        content
        seen
        video_id
        professional_id
        updated_at
        created_at
      }
    }
  }
`;

export const RECOMMENDATION_SYSTEM = gql`
  query getRecommendedVideos($type: String!) {
    recommendation_system(type: $type) {
      error
      videos {
        created_at
        id
        professional_id
        title
        thumbnail_link
      }
    }
  }
`;
