import { gql } from "@apollo/client";

/* ---- AUTHENTICATION ---- */
export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    professional_login(email: $email, password: $password) {
      error
      token {
        access_token
        token_type
        created_at
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation loginRefresh {
    login_refresh {
      error
      token {
        access_token
      }
    }
  }
`;

export const SIGNUP = gql`
  mutation Signup(
    $username: String!
    $password: String!
    $email: String!
    $phone: String!
    $address1: String!
    $address2: String
    $city: String!
    $state: String!
    $postalCode: String!
    $companyName: String!
  ) {
    create_advertiser(
      details: {
        user_name: $username
        password: $password
        email: $email
        phone: $phone
        company_name: $companyName
        address_1: $address1
        address_2: $address2
        city: $city
        state: $state
        postal_code: $postalCode
      }
    ) {
      error
      advertiser {
        id
        user_name
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout {
      error
      logged_out
    }
  }
`;

export const REQUEST_EMAIL_CODE = gql`
  mutation RequestEmailVerifyCode(
    $userId: UUID
    $username: String
    $email: String
    $userGroup: String!
    $intent: VerificationCodeIntent!
  ) {
    create_verification_code(
      user_id: $userId
      user_name: $username
      email: $email
      user_group: $userGroup
      intent: $intent
    ) {
      error
      verification_code_sent
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($userId: UUID!, $code: String!, $user_group: String!) {
    verify_email(user_id: $userId, code: $code, user_group: $user_group) {
      error
      verification_code_matched
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: UUID!
    $firstName: String
    $lastName: String
    $middleName: String
    $phoneCc: String
    $phoneNo: String
    $dob: Date
    $profession: String
    $designation: String
    $organization: String
    $specialisation: String
    $residencyAddress: String
    $residencyCity: String
    $residencyState: String
    $residencyCountry: String
    $officeAddress: String
    $officeCity: String
    $officeState: String
    $officeCountry: String
    $gender: ProfessionalGenderEnum
    $licenceNo: String
    $graduationDate: Date
    $bio: String
    $password: String
  ) {
    update_professional(
      id: $id
      details: {
        first_name: $firstName
        last_name: $lastName
        middle_name: $middleName
        password: $password
        phone_cc: $phoneCc
        phone_no: $phoneNo
        profession: $profession
        designation: $designation
        organization: $organization
        specialisation: $specialisation
        DOB: $dob
        office_country: $officeCountry
        office_state: $officeState
        office_city: $officeCity
        office_address: $officeAddress
        residency_country: $residencyCountry
        residency_state: $residencyState
        residency_city: $residencyCity
        residency_address: $residencyAddress
        gender: $gender
        licence_no: $licenceNo
        graduation_date: $graduationDate
        bio: $bio
      }
    ) {
      error
      professional {
        id
        user_name
        phone_cc
        phone_no
        first_name
        last_name
        profession
        designation
        organization
        specialisation
        DOB
        active
        office_country
        office_state
        office_city
        office_address
        residency_country
        residency_state
        residency_city
        residency_address
        licence_no
        graduation_date
        docs {
          id
          doc_type
          status
          status_description
        }
      }
    }
  }
`;

/* PROFESSIONAL INTERESTS */
export const CREATE_PROFESSIONAL_INTEREST = gql`
  mutation CreateProfessionalInterest(
    $professionalId: UUID!
    $masterlistTopicId: UUID!
  ) {
    create_professional_interest(
      details: {
        professional_id: $professionalId
        masterlist_topic_id: $masterlistTopicId
      }
    ) {
      error
      professional_interest {
        id
      }
    }
  }
`;

export const DELETE_PROFESSIONAL_INTEREST = gql`
  mutation DeleteProfessionalInterest($id: UUID!) {
    delete_professional_interest(id: $id) {
      error
      deleted
    }
  }
`;

/* MASTERLIST TOPICS */
export const CREATE_MASTERLIST_TOPIC = gql`
  mutation CreateMasterlistTopic($topic: String!) {
    create_masterlist_topic(details: { topic: $topic }) {
      error
      masterlist_topic {
        id
      }
    }
  }
`;

/* VIDEOS */
export const UPDATE_VIDEO = gql`
  mutation UpdateVideo(
    $id: UUID!
    $title: String
    $description: String
    $allowAdvertisementUse: Boolean
    $privacyPolicy: VideoPrivacyPolicy
    $tags: [UUID]
  ) {
    update_video(
      id: $id
      details: {
        title: $title
        description: $description
        allow_advertisement_use: $allowAdvertisementUse
        privacy_policy: $privacyPolicy
      }
      tags: $tags
    ) {
      error
      video {
        id
        professional_id
        title
        description
        allow_advertisement_use
        created_at
        updated_at
        status
        status_description
      }
    }
  }
`;

export const DELETE_VIDEO_TAGS = gql`
  mutation DeleteVideoTags($id: UUID!, $tags: [UUID!]!) {
    delete_video_tags(id: $id, tags: $tags) {
      error
      video {
        id
      }
    }
  }
`;

/* POSTS */
export const UPDATE_POST = gql`
  mutation UpdatePost($postId: UUID!, $privacyPolicy: PostPrivacyPolicy) {
    update_post(id: $postId, details: { privacy_policy: $privacyPolicy }) {
      error
      post {
        id
        content
        status
        image
        image_uploaded
      }
    }
  }
`;

/* ---- LIKES ---- */
export const CREATE_LIKE = gql`
  mutation CreateLike($postId: UUID, $videoId: UUID) {
    create_like(details: { post_id: $postId, video_id: $videoId }) {
      error
      like {
        id
      }
    }
  }
`;

export const DELETE_LIKE = gql`
  mutation DeleteLike($likeId: UUID!) {
    delete_like(id: $likeId) {
      error
      deleted
    }
  }
`;

/* ---- COMMENTS ---- */
export const CREATE_COMMENT = gql`
  mutation CreateComment(
    $postId: UUID
    $videoId: UUID
    $commentId: UUID
    $content: String!
  ) {
    create_comment(
      details: {
        post_id: $postId
        video_id: $videoId
        comment_id: $commentId
        content: $content
      }
    ) {
      error
      comment {
        id
        content
      }
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation DeleteComment($commentId: UUID!) {
    delete_comment(id: $commentId) {
      error
      deleted
    }
  }
`;

/* FAVOURTIE-UNFAVOURITE VIDEO */
export const CREATE_FAVOURITE_VIDEO = gql`
  mutation CreateFavouriteVideo($videoId: UUID!) {
    create_favourite_video(video_id: $videoId) {
      error
      favourite_video {
        id
      }
    }
  }
`;

export const DELETE_FAVOURITE_VIDEO = gql`
  mutation DeleteFavouriteVideo($id: UUID!) {
    delete_favourite_video(id: $id) {
      error
      deleted
    }
  }
`;

/* FOLLOW-UNFOLLOW */
export const CREATE_FOLLOW = gql`
  mutation CreateFollow($followingId: UUID!) {
    create_professional_follow(following_id: $followingId) {
      error
      professional_follow {
        id
      }
    }
  }
`;

export const DELETE_FOLLOW = gql`
  mutation DeleteFollow($id: UUID!) {
    delete_professional_follow(id: $id) {
      error
      deleted
    }
  }
`;

/* CHAT-ROOM */
export const CREATE_CHATROOM = gql`
  mutation CreateChatRoom($participantId: UUID!) {
    create_professional_chatroom(participant_id: $participantId) {
      error
      professional_chatroom {
        id
        participant_id
        initiator_id
        accepted
        created_at
        updated_at
      }
    }
  }
`;

export const UPDATE_CHATROOM = gql`
  mutation UpdateChatRoom($id: UUID!, $accepted: Boolean!) {
    update_professional_chatroom(id: $id, details: { accepted: $accepted }) {
      error
      professional_chatroom {
        id
        accepted
      }
    }
  }
`;

/* CHAT MESSAGE */
export const CREATE_CHAT_MESSAGE = gql`
  mutation CreateChatMessage(
    $professionalChatroomId: UUID!
    $message: String
    $messageType: ProfessionalChatroomMessageType!
    $professionalChatroomFileId: UUID
  ) {
    create_professional_chatroom_message(
      details: {
        professional_chatroom_id: $professionalChatroomId
        message: $message
        message_type: $messageType
        professional_chatroom_file_id: $professionalChatroomFileId
      }
    ) {
      error
      professional_chatroom_message {
        id
        professional_chatroom_id
        message
        sent_by
        seen
        message_type
        professional_chatroom_file_id
        created_at
        seen_at
        professional_chatroom_file {
          id
          professional_id
          professional_chatroom_id
          file_extension
          file_uploaded
          created_at
          updated_at
          file
        }
      }
    }
  }
`;

export const UPDATE_CHAT_MESSAGE = gql`
  mutation UpdateChatRoom($id: String!, $professionalChatroomId: UUID!) {
    update_professional_chatroom_message(
      id: $id
      professional_chatroom_id: $professionalChatroomId
    ) {
      error
      professional_chatroom_message {
        id
        professional_chatroom_id
        message
        sent_by
        seen
        message_type
        professional_chatroom_file_id
        created_at
        seen_at
        professional_chatroom_file {
          id
          professional_id
          professional_chatroom_id
          file_extension
          file_uploaded
          created_at
          updated_at
          file
        }
      }
    }
  }
`;

export const UPDATE_CHAT_TYPING = gql`
  mutation UpdateChatTyping($professionalChatroomId: UUID!) {
    update_professional_chatroom_typing(
      professional_chatroom_id: $professionalChatroomId
    ) {
      error
      updated
    }
  }
`;

export const CREATE_CHAT_FILE = gql`
  mutation CreateChatFile($professionalChatroomId: UUID!) {
    create_professional_chatroom_file(
      details: { professional_chatroom_id: $professionalChatroomId }
    ) {
      error
      professional_chatroom_file {
        id
      }
    }
  }
`;

/* REPORT POST/VIDEO */
export const REPORT_POST = gql`
  mutation ReportPost(
    $postId: UUID
    $videoId: UUID
    $name: String!
    $email: String!
    $description: String
    $category: PostVideoReportCategory!
  ) {
    create_post_video_report(
      details: {
        post_id: $postId
        video_id: $videoId
        name: $name
        email: $email
        description: $description
        category: $category
      }
    ) {
      error
      post_video_report {
        id
      }
    }
  }
`;

/* PAYMENT */
export const ADD_STRIPE_PAYMENT_METHOD = gql`
  mutation AddStripePaymentMethod($id: UUID!) {
    add_payment_method(user_id: $id) {
      error
      setup_intent {
        id
        client_secret
        payment_method_types
      }
    }
  }
`;

export const DELETE_STRIPE_PAYMENT_METHOD = gql`
  mutation DeleteStripePaymentMethod($id: String!) {
    remove_payment_method(pm_id: $id) {
      error
      deleted
    }
  }
`;
export const CREATE_PAYMENT_SUBSCRIPTION = gql`
  mutation createPaymentSub($details: ProfessionalSubscriptionCreation!) {
    create_professional_subscription(details: $details) {
      error
      professional_subscription {
        id
        professional_id
        storage_tier_id
        valid_till
        status
        subscription_type
        stripe_subscription_id
        created_at
        updated_at
        status_description
      }
    }
  }
`;
export const UPDATE_PAYMENT_SUBSCRIPTION = gql`
  mutation createPaymentSub($id: UUID!, $quantity: Int!) {
    update_professional_subscription(id: $id, quantity: $quantity) {
      error
      professional_subscription {
        id
        professional_id
        storage_tier_id
        valid_till
        status
        subscription_type
        stripe_subscription_id
        created_at
        updated_at
        status_description
      }
    }
  }
`;

export const CANCEL_PAYMENT_SUBSCRIPTION = gql`
  mutation cancelSubscription($id: UUID!) {
    cancel_professional_subscription(id: $id) {
      error
      professional_subscription {
        id
        professional_id
        storage_tier_id
        valid_till
        status
        subscription_type
        stripe_subscription_id
        created_at
        updated_at
        status_description
      }
    }
  }
`;
export const SET_DEFAULT_CARD = gql`
  mutation setDefaultCard(
    $professional_id: UUID!
    $payment_method_id: String!
  ) {
    update_professional_default_payment_method(
      professional_id: $professional_id
      payment_method_id: $payment_method_id
    ) {
      error
      professional {
        default_payment_method
      }
    }
  }
`;

export const SET_NOTIFICATION_SEEN = gql`
  mutation setNotificationSeen($id: UUID!) {
    seen_notification(id: $id) {
      error
      notification {
        id
      }
    }
  }
`;

export const INCREMENT_VIEW_COUNT = gql`
  mutation incrementViewCount($video_id: UUID) {
    create_video_view_count(video_id: $video_id) {
      error
    }
  }
`;
