import { Tooltip, Progress } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./navbar.module.scss";
import Skeleton from "react-loading-skeleton";
import useStorage from "shared/hooks/useStorage";

export default function StorageUsage() {
  const { t } = useTranslation();
  const { availableStorage, currUnit, totalUsage, usagePercent, storageInfo } =
    useStorage();

  return (
    <div className={styles.usedSpaceContainer}>
      {storageInfo.vdocipher_usage_in_bytes ? (
        <Link to="/settings?tab=2">
          <Tooltip placement="left" title={t("your media storage usage")}>
            <div>
              <Progress percent={usagePercent} size="small" showInfo={false} />
              <p className={styles.usageText}>
                {totalUsage}
                <span>{currUnit}</span> / {availableStorage}
                <span>{currUnit}</span>
              </p>
            </div>
          </Tooltip>
        </Link>
      ) : (
        <Skeleton />
      )}
    </div>
  );
}
