import { Link, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Popover, Avatar } from "antd";
import {
  MdPerson,
  MdArrowDropDown,
  MdSettings,
  MdPersonOutline,
  MdViewHeadline,
  MdChat,
} from "react-icons/md";
import { useTranslation } from "react-i18next";
import "./_search.scss";
import Logo from "assets/images/logo.svg";
import { useUser } from "shared/contexts/user-context";
import { LOGOUT } from "shared/services";
import { removeLocalToken } from "shared/utils/util-funcs";
import { Button, LocaleSelector } from "shared/components";

import StorageUsage from "./StorageUsage";

import styles from "./navbar.module.scss";
import Notifications from "./Notifications";
import Search from "./Search";

export default function Navbar() {
  const history = useHistory();
  const { setUser } = useUser();

  const [logout] = useMutation(LOGOUT, {
    onCompleted(data) {
      if (data?.logout?.logged_out) {
        removeLocalToken("auth");
        setUser(null);
        history.replace("/login");
      }
    },
  });

  return (
    <div className={styles.navContainer}>
      <div className={styles.navbar}>
        <div>
          <Link to="/" className={styles.logoLink}>
            <img src={Logo} alt="meem  logo" className={styles.logo} />
          </Link>
        </div>
        <Search />
        <StorageUsage />
        <NavRight logout={logout} />
      </div>
    </div>
  );
}

/* ---- Account Menu ---- */
function AccountMenuItem({ icon, linkTo, text }) {
  const { t } = useTranslation();

  return (
    <Link to={linkTo}>
      <div className={styles.accountMenuItem}>
        {icon}
        <p>{t(text)}</p>
      </div>
    </Link>
  );
}

function AccountMenu({ onLogout, user }) {
  const { t } = useTranslation();

  return (
    <div className={styles.accountMenu}>
      <AccountMenuItem
        icon={<MdPersonOutline className={styles.accountMenuIcon} />}
        linkTo="/me"
        text="view profile"
      />
      <AccountMenuItem
        icon={<MdChat className={styles.accountMenuIcon} />}
        linkTo="/chats"
        text="chat"
      />
      {/* <AccountMenuItem
        icon={<MdEdit className={styles.accountMenuIcon} />}
        linkTo="/me"
        text="edit profile"
      /> */}
      <AccountMenuItem
        icon={<MdViewHeadline className={styles.accountMenuIcon} />}
        linkTo="/stats"
        text="stats"
      />

      <AccountMenuItem
        icon={<MdSettings className={styles.accountMenuIcon} />}
        linkTo="/settings"
        text="settings"
      />
      {/* <div className={styles.accountMenuItem}>
        <MdLanguage /> <LocaleSelector textOnly />
      </div> */}
      <Button onClick={() => onLogout()} classes={[styles.accountBtn]}>
        {t("logout")}
      </Button>
    </div>
  );
}

/* ---- Logo | Search | NavRight ---- */
function NavRight({ logout }) {
  const { user } = useUser();
  return (
    <div className={styles.navRight}>
      <Link to="/me" className={styles.profileLink}>
        <div className={styles.userAvatar}>
          <Avatar
            src={user.image}
            icon={<MdPerson />}
            style={{
              cursor: "pointer",
              marginRight: ".5rem",
            }}
          />
          <p>{user?.first_name}</p>
        </div>
      </Link>
      <Notifications />
      <LocaleSelector textOnly style={{ marginLeft: "0.8rem" }} />
      {/* Mobile view menu options */}
      <Popover
        className="mobile-only"
        placement="bottomRight"
        content={<AccountMenu onLogout={logout} user={user} />}
      >
        <Avatar
          src={user.image}
          icon={<MdPerson />}
          style={{
            cursor: "pointer",
            // marginRight: ".5rem",
            border: "2px solid #180f77",
          }}
        />
      </Popover>

      {/* Desktop view menu options */}
      <Popover
        className="desktop-only"
        placement="bottomRight"
        content={<AccountMenu onLogout={logout} user={user} />}
      >
        <MdArrowDropDown
          style={{ cursor: "pointer", fontSize: 28, marginLeft: "1rem" }}
        />
      </Popover>
    </div>
  );
}
