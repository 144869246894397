import { gql } from "@apollo/client";

export const SUB_CHAT_MESSAGE = gql`
  subscription subChatMessage($token: String!, $professionalChatroomId: UUID!) {
    professional_chatroom_message(
      token: $token
      professional_chatroom_id: $professionalChatroomId
    ) {
      error
      event
      professional_chatroom_message {
        id
        professional_chatroom_id
        message
        sent_by
        seen
        message_type
        professional_chatroom_file_id
        created_at
        seen_at
        professional_chatroom_file {
          id
          professional_id
          professional_chatroom_id
          file_extension
          file_uploaded
          created_at
          updated_at
          file
        }
      }
      typing_user_id
    }
  }
`;
export const SUB_SEARCH = gql`
  subscription search($token: String!, $keyword: String!) {
    search(token: $token, keyword: $keyword) {
      error
      search {
        category
        post {
          id
          content
          professional_id
        }
        video {
          id
          title
        }
        professional {
          id
          last_name
          first_name
          profession
          organization
        }
      }
    }
  }
`;
export const SUB_POST_FEED = gql`
  subscription subPostFeed($token: String!, $professional_id: UUID!) {
    post_feed(token: $token, professional_id: $professional_id) {
      error
      post_feed {
        id
        created_at
        post_id
        post {
          professional_id
          id
        }
      }
    }
  }
`;

export const SUB_NOTIFICATION = gql`
  subscription notification($token: String!, $professional_id: UUID!) {
    notification(token: $token, professional_id: $professional_id) {
      error
      notification {
        id
        post_id
        video_id
        professional_id
        seen
        content
      }
    }
  }
`;
