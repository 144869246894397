import { Button } from "antd";
import { FiMessageSquare } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { LoadingSkeleton } from "shared/components";

import styles from "./styles.module.scss";

export default function PostCommentButton({
  post,
  toggleShowComments,
  totalComments,
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.action}>
      <Button
        className={clsx(styles.button, "post-action-btn")}
        onClick={() => toggleShowComments()}
      >
        <FiMessageSquare className={clsx(styles.actionIcon)} />
        <p style={{ marginLeft: "2px" }}>
          {totalComments.total_count || <LoadingSkeleton />}
        </p>
        <p style={{ margin: "0 2px" }}>·</p>
        <p>{t("comment")}</p>
      </Button>
    </div>
  );
}
