import { Button } from "antd";
import clsx from "clsx";
import { MdClose, MdAccountCircle } from "react-icons/md";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

export default function UploadProfilePicPrompt({ hidePrompt }) {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        "b-rad-4",
        "feedContainer",
        "container",
        styles.outerContainer
      )}
    >
      <div className={styles.innerContainer}>
        <div className={styles.detail}>
          <MdAccountCircle className={styles.detailIcon} />
          <Link to="/me">
            <Button type="primary">{t("Add your profile picture")}</Button>
          </Link>
        </div>
        <Button
          shape="circle"
          icon={<MdClose style={{ fontSize: "1.25rem" }} />}
          style={{
            borderRadius: "50%",
          }}
          onClick={hidePrompt}
        />
      </div>
    </div>
  );
}
