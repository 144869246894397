import { PostSkeleton, LoadingSkeleton } from "shared/components";

export default function HomeLayoutSkeleon() {
  return (
    <div className="bodyContainer">
      <div className="homeContainer">
        <div className="sideNavContainer">
          <LoadingSkeleton props={{ height: 500 }} />
        </div>

        <div className="heightedMiddleContainer">
          <PostSkeleton post={{}} />
          <PostSkeleton post={{}} />
        </div>

        <div className="bannerAdsContainer">
          <LoadingSkeleton props={{ height: 500 }} />
        </div>
      </div>
    </div>
  );
}
