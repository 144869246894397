import { gql } from "@apollo/client";

export const CREATE_VIDEO = gql`
  mutation CreateVIdeo(
    $title: String!
    $description: String!
    $allowAdvertisementUse: Boolean!
    $privacyPolicy: VideoPrivacyPolicy!
    $tags: [UUID]
  ) {
    create_video(
      details: {
        title: $title
        description: $description
        allow_advertisement_use: $allowAdvertisementUse
        privacy_policy: $privacyPolicy
      }
      tags: $tags
    ) {
      error
      video {
        id
        professional_id
        title
        description
        allow_advertisement_use
        created_at
        updated_at
        status
        status_description
      }
    }
  }
`;
