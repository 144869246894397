import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import { useUser } from "shared/contexts/user-context";
import { GET_USER, REQUEST_EMAIL_CODE } from "shared/services";
import { FormInput, AuthForm, Button } from "shared/components";

import { VERIFY_EMAIL } from "./confirm-email.service";
import styles from "./confirm-email.module.scss";

export default function ConfirmEmail() {
  const { t } = useTranslation();
  const { user } = useUser();

  /* ---- Handle Form ---- */
  const { register, handleSubmit, errors, reset } = useForm();

  // Enums for Form Status: PROCESSING, ERROR
  const [formStatus, setFormStatus] = useState({
    status: null,
    statusMsg: "",
  });

  // if user email is not confirmed, sent email confirmation code
  const [requestCode, { loading: requestCodeLoading }] = useMutation(
    REQUEST_EMAIL_CODE,
    {
      onCompleted(data) {
        // FOR MANUAL CODE SENT
        if (formStatus.status === "ERROR") {
          if (data.create_verification_code.error) {
            setFormStatus({
              ...formStatus,
              status: "ERROR",
              statusMsg: data.create_verification_code.error,
            });
          } else {
            message.success(
              t("confirmation code sent. please check your email.")
            );
            setFormStatus({
              ...formStatus,
              status: null,
            });
            reset({
              code: "",
            }); // Reset the form
          }
        }
      },
    }
  );

  const [formValues, setFormValues] = useState({
    code: null,
  });

  const handleChange = (prop) => (event) => {
    setFormValues({ ...formValues, [prop]: event.target.value.trim() });
  };

  const history = useHistory();
  const [verifyEmail, { loading }] = useMutation(VERIFY_EMAIL, {
    refetchQueries: [
      {
        query: GET_USER,
        variables: {
          userId: user.id,
        },
      },
    ],
    onCompleted(data) {
      if (data.verify_email.error) {
        setFormStatus({
          ...formStatus,
          status: "ERROR",
          statusMsg: data.verify_email.error,
        });
      } else {
        message.success(t("your email has been successfully verified"));
        history.replace("/");
      }
    },
  });

  return (
    <>
      <AuthForm
        onSubmit={handleSubmit((data) =>
          verifyEmail({
            variables: {
              userId: user.id,
              code: data.code,
              user_group: "professional",
            },
          })
        )}
        formStatus={formStatus}
        buttonLabel="submit"
        title={t("confirm email")}
        subTitle="Thank you for the request"
        isDisabled={loading}
      >
        <FormInput
          label="confirmation code"
          name="code"
          register={register({
            required: { value: true, message: "required" },
          })}
          errors={errors}
          value={formValues.code}
          onChange={handleChange}
          tabIndex={1}
        />
        {formStatus.status === "ERROR" && (
          <Button
            tabIndex="2"
            type="button"
            classes={[styles.resentCodeBtn]}
            disabled={requestCodeLoading}
            isProcessing={requestCodeLoading}
            onClick={() => requestCode({ variables: { userId: user.id } })}
          >
            {t("resent confirmation code")}
          </Button>
        )}
      </AuthForm>
    </>
  );
}
