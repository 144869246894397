import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { getLocalToken } from "shared/utils/util-funcs";

import { STRIPE_PUB_KEY } from "config";

const localLang = getLocalToken("locale");
console.log(
  "👉 ~ file: StripeElementProvider.js ~ line 9 ~ localLang",
  localLang
);

// Current selected lang by user or User stored one or eng
// Stripe uses ja for japanese locale
const currentLang = localLang === "jp" ? "ja" : "en";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PUB_KEY, { locale: currentLang });

export default function StripeElementProvider({ children }) {
  return <Elements stripe={stripePromise}>{children}</Elements>;
}
