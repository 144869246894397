import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { LOGOUT } from "shared/services";
import { removeLocalToken } from "shared/utils/util-funcs";

export default function Logout() {
  const { t } = useTranslation();
  const history = useHistory();

  const [logout] = useMutation(LOGOUT, {
    onCompleted(data) {
      if (data?.logout?.logged_out) {
        removeLocalToken("auth");
        history.replace("/login");
      }
    },
  });
  return (
    <div style={{ justifySelf: "end" }}>
      <Button type="primary" onClick={() => logout()}>
        {t("logout")}
      </Button>
    </div>
  );
}
