import { gql } from "@apollo/client";

export const UPDATE_POST = gql`
  mutation UpdatePost($postId: UUID!, $privacyPolicy: PostPrivacyPolicy) {
    update_post(id: $postId, details: { privacy_policy: $privacyPolicy }) {
      error
      post {
        id
        content
        status
        image
        image_uploaded
      }
    }
  }
`;

/* ---- LIKES ---- */
export const GET_LIKES = gql`
  query GetLikes(
    $offset: Int
    $limit: Int
    $postId: UUID
    $professionalId: UUID
    $minCreatedAt: Datetime
    $maxCreatedAt: Datetime
    $order: SortOrder
    $sort: String
  ) {
    likes(
      offset: $offset
      limit: $limit
      post_id: $postId
      professional_id: $professionalId
      min_created_at: $minCreatedAt
      max_created_at: $maxCreatedAt
      order: $order
      sort: $sort
    ) {
      error
      total_count
      likes {
        id
        professional_id
        created_at
      }
    }
  }
`;

export const CREATE_LIKE = gql`
  mutation CreateLike($postId: UUID!) {
    create_like(details: { post_id: $postId }) {
      error
      like {
        id
      }
    }
  }
`;

export const DELETE_LIKE = gql`
  mutation DeleteLike($likeId: UUID!) {
    delete_like(id: $likeId) {
      error
      deleted
    }
  }
`;

/* ---- COMMENTS ---- */
export const GET_COMMENTS = gql`
  query GetComments(
    $offset: Int
    $limit: Int
    $postId: UUID
    $professionalId: UUID
    $commentId: UUID
    $minCreatedAt: Datetime
    $maxCreatedAt: Datetime
    $order: SortOrder
    $sort: String
  ) {
    comments(
      offset: $offset
      limit: $limit
      post_id: $postId
      professional_id: $professionalId
      comment_id: $commentId
      min_created_at: $minCreatedAt
      max_created_at: $maxCreatedAt
      order: $order
      sort: $sort
    ) {
      error
      total_count
      comments {
        id
        professional_id
        created_at
        content
        professional {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation CreateComment(
    $postId: UUID
    $videoId: UUID
    $commentId: UUID
    $content: String!
  ) {
    create_comment(
      details: {
        post_id: $postId
        video_id: $videoId
        comment_id: $commentId
        content: $content
      }
    ) {
      error
      comment {
        id
        content
      }
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation DeleteComment($commentId: UUID!) {
    delete_comment(id: $commentId) {
      error
      deleted
    }
  }
`;
