import { createContext, useContext, useMemo, useState } from "react";

const UserStateContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const userState = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user, setUser]
  );

  return (
    <UserStateContext.Provider value={userState}>
      {children}
    </UserStateContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context;
};

export { UserProvider, useUser };
