import { message, Upload } from "antd";
import { MdCloudUpload } from "react-icons/md";
import { useTranslation } from "react-i18next";

import styles from "./create-post.module.scss";

export default function UploadPostImage({ fileList, setFileList }) {
  console.log(
    "🚀 ~ file: UploadPostImage.js ~ line 8 ~ UploadPostImage ~ fileList",
    fileList
  );
  const { t } = useTranslation();

  const onChange = (info) => {
    if (
      ["image/jpeg", "image/png"].includes(info.file.type) &&
      info.file.size < 5242880
    ) {
      setFileList(
        info.fileList.filter((file) =>
          ["image/jpeg", "image/png"].includes(file.type)
        )
      );
    } else {
      return [];
    }
  };

  return (
    <div className={styles.uploadImageContainer}>
      <div className="prof-doc-upload">
        <Upload
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          beforeUpload={(file) => {
            if (file.size > 5242880) {
              message.error(`${t("Allowed file size <5mb")}`);
              setFileList([]);
              return false;
            }
            if (!["image/jpeg", "image/png"].includes(file.type)) {
              message.error(
                `${t("Allowed file extensions")}: .jpg, .jpeg, .png`
              );
              setFileList([]);
              return false;
            }
            setFileList([file]); // Handle file upload call manually
            return false;
          }}
        >
          {fileList.length < 1 && <UploadButton />}
        </Upload>
      </div>
    </div>
  );
}

function UploadButton() {
  const { t } = useTranslation();

  return (
    <div>
      <MdCloudUpload />
      <div style={{ marginTop: 8 }}>{t("select image")}</div>
      <small style={{ color: "gray" }}>Allowed formats (JPG,PNG)</small>
    </div>
  );
}
