const API_ENDPOINT = process.env.REACT_APP_API;
const API_WS_ENDPOINT = process.env.REACT_APP_API_WS; // For websocket subscriptions
const STRIPE_PUB_KEY = process.env.REACT_APP_STRIPE_PUB_KEY;

const REST_API_ENDPOINT = process.env.REACT_APP_REST_ENDPOINT;
const REST_VIDEO_API_ENDPOINT = process.env.REACT_APP_REST_VIDEO_ENDPOINT;

export {
  API_ENDPOINT,
  API_WS_ENDPOINT,
  STRIPE_PUB_KEY,
  REST_API_ENDPOINT,
  REST_VIDEO_API_ENDPOINT,
};
