import { useState, useEffect } from "react";
import { Checkbox, Modal, Input, Button, message } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import {
  UPDATE_VIDEO,
  DELETE_VIDEO_TAGS,
  CREATE_MASTERLIST_TOPIC,
  GET_VIDEO,
} from "shared/services";
import { EditVideoTags } from "shared/components";

import styles from "./update-video-post.module.scss";

const { TextArea } = Input;

export default function UpdateVideoPostModal({
  isOpen,
  onClose,
  video,
  currentUserId,
}) {
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    title: video?.title,
    description: video?.description,
  });

  const handleChange = (prop) => (event) => {
    setFormValues({ ...formValues, [prop]: event.target.value.trim() });
  };

  const [allowAdvertisementUse, setAllowAdvertisementUse] = useState(
    () => video?.allow_advertisement_use
  );
  const toggleChecked = () => {
    setAllowAdvertisementUse(!allowAdvertisementUse);
  };

  /* ---- Tags ---- */
  const [existingTagsTopics] = useState(() =>
    video.tags.map((t) => {
      return {
        value: t.masterlist_topic.id,
        label: t.masterlist_topic.topic,
        tagId: t.id,
      };
    })
  );

  const [editedTags, setEditedTags] = useState(() =>
    video.tags.map((t) => {
      return {
        value: t.masterlist_topic.id,
        label: t.masterlist_topic.topic,
        tagId: t.id,
      };
    })
  );

  const [deleteVideoTags] = useMutation(DELETE_VIDEO_TAGS, {
    refetchQueries: [
      {
        query: GET_VIDEO,
        variables: {
          videoId: video.id,
        },
      },
    ],
  });
  const [createMasterlistTopic] = useMutation(CREATE_MASTERLIST_TOPIC);

  const handleCreateMasterlistTopics = async (newTopics) => {
    const newTopicIds = [];
    for (const topic of newTopics) {
      const { data } = await createMasterlistTopic({
        variables: { topic: topic.value },
      });
      newTopicIds.push(data.create_masterlist_topic?.masterlist_topic.id);
    }
    return newTopicIds;
  };

  const filterNewTags = (editedTags) => {
    const newTopics = editedTags.filter((i) => i.value.length !== 36);

    return newTopics;
  };
  // Tag is used for deletion purpose, tag.masterlist_topic_id is for actual hashtags
  const diffRemovedTags = (existingTags, editedNewTags) => {
    const removedTags = existingTags.filter(
      (ext) => !editedNewTags.some((edt) => edt.label === ext.label)
    );
    return removedTags.map((tg) => tg.tagId);
  };

  /* ---- Polling for status change check ---- */
  const {
    loading: postLoading,
    data: postData,
    startPolling,
    stopPolling,
  } = useQuery(GET_VIDEO, {
    variables: {
      videoId: video.id,
    },
    fetchPolicy: "network-only",
  });

  const [removedTags, setRemovedTags] = useState([]);
  useEffect(() => {
    const handleRemoveTags = async () => {
      if (
        removedTags.length > 0 &&
        postData.video.video.status !== "processing"
      ) {
        // Match if tags removed with diff from existing tags, run delete tags mutation for them
        await deleteVideoTags({
          variables: { id: video.id, tags: removedTags },
        });
        stopPolling();
        message.success(`${t("post updated successfully")}`);
        onClose();
      }
    };
    handleRemoveTags();
  }, [postData, postLoading]);

  /* ---- Handle Video post update ---- */

  const [updateVideo, { loading }] = useMutation(UPDATE_VIDEO, {
    refetchQueries: [
      {
        query: GET_VIDEO,
        variables: {
          videoId: video.id,
        },
        notifyOnNetworkStatusChange: true,
      },
    ],
  });
  const [isUpdatingVideoPost, setIsUpdatingVideoPost] = useState(false);

  const handleUpdateVideoPost = async () => {
    setIsUpdatingVideoPost(true);

    // Remove deleted tags, if any
    const removedTagIds = diffRemovedTags(existingTagsTopics, editedTags);
    setRemovedTags(removedTagIds);

    const newTopics = filterNewTags(editedTags);
    const newTopicIds = await handleCreateMasterlistTopics(newTopics);

    const existingNonRemovedTags = editedTags
      .filter((et) => !removedTagIds.includes(et.tagId))
      .filter((t) => t.value.length === 36)
      .map((t) => t.value);

    const res = await updateVideo({
      variables: {
        id: video.id,
        ...formValues,
        allowAdvertisementUse,
        tags: [...existingNonRemovedTags, ...newTopicIds],
      },
    });
    if (res.data.update_video.error) {
      message.error(res.data.update_video.error);
      setIsUpdatingVideoPost(false);
      return;
    }
    // if there are tags to be deleted, start and wait for status to be changed from processing
    if (removedTagIds.length > 0) {
      startPolling(1000);
    } else {
      message.success(`${t("post updated successfully")}`);
      onClose();
    }
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={(!postLoading || !loading || !isUpdatingVideoPost) && onClose}
      footer={null}
      title={t("update video post")}
    >
      <TextArea
        showCount
        maxLength={100}
        rows={4}
        bordered={true}
        autoSize
        placeholder={t("title")}
        allowClear
        onChange={handleChange("title")}
        defaultValue={formValues.title}
      />
      <div style={{ marginBottom: "1rem", marginTop: "1rem" }}></div>
      <TextArea
        showCount
        maxLength={1000}
        rows={4}
        bordered={true}
        autoSize
        placeholder={t("description")}
        allowClear
        onChange={handleChange("description")}
        defaultValue={formValues.description}
      />
      <EditVideoTags
        video={video}
        existingTagsTopics={existingTagsTopics}
        setEditedTags={setEditedTags}
      />
      <Checkbox checked={allowAdvertisementUse} onChange={toggleChecked}>
        {t("Allow usage for advertising purposes")}
      </Checkbox>

      <div className={styles.footer}>
        <Button
          type="primary"
          onClick={() => handleUpdateVideoPost()}
          disabled={isUpdatingVideoPost}
          loading={isUpdatingVideoPost}
        >
          {t("update video post")}
        </Button>
      </div>
    </Modal>
  );
}
