import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Result, Radio, Divider } from "antd";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import TextLogo from "assets/images/logo-text.svg";

import { useUser } from "shared/contexts/user-context";
import { GET_USER } from "shared/services";

import styles from "./onboarding.module.scss";
import UploadDoc from "./UploadDoc";
import AddProfIdDetails from "./AddProfIdDetails";

export default function UploadProfDocs({ refetchUser }) {
  const { t } = useTranslation();

  const { user } = useUser();
  const { docs } = user;

  const checkDocTypeExists = (types) => {
    return docs.some((doc) => types.includes(doc.doc_type));
  };

  const [profIdSelection, setProfIdSelection] = useState("addDocs");

  const onChange = (e) => {
    setProfIdSelection(e.target.value);
  };

  const [getUser] = useLazyQuery(GET_USER, {
    variables: {
      userId: user.id,
    },
    fetchPolicy: "no-cache",
  });

  return (
    <div className={styles.container}>
      <div className="flex flex-col">
        <div className={styles.form}>
          {/* <div className={styles.logoContainer}>
            <img src={TextLogo} className={styles.logo} alt="MeeM text logo" />
          </div> */}
          <h1 className={styles.title} style={{ marginTop: "1rem" }}>
            {t("upload documents for approval")}
          </h1>
          {/* Photo ID */}
          {!checkDocTypeExists(["photo_id"]) && (
            <UploadDoc
              user={user}
              refetchUser={refetchUser}
              docType="photo_id"
              docTypeName="photo id"
              docTypesList={[{ name: `${t("Photo Id")}`, value: "photo_id" }]}
            />
          )}
          {checkDocTypeExists("photo_id") && (
            <UploadedDoc statusText="photo id has been uploaded" />
          )}{" "}
          <Divider />
          {(!checkDocTypeExists(["profession_licence"]) ||
            !user.licence_no) && (
            <>
              <h3>{t("professional identity")}</h3>
              <Radio.Group
                onChange={onChange}
                value={profIdSelection}
                defaultValue="addDocs"
                buttonStyle="solid"
                style={{ marginTop: "1rem", marginBottom: "1.5rem" }}
              >
                <Radio.Button value="addDocs">
                  {t("upload document")}
                </Radio.Button>
                <Radio.Button
                  value="addDetails"
                  // disabled={!checkDocTypeExists(["profession_licence"])}
                >
                  {t("add details")}
                </Radio.Button>
              </Radio.Group>
              {!checkDocTypeExists(["profession_licence"]) && (
                <UploadDoc
                  user={user}
                  refetchUser={refetchUser}
                  docTypeName="professional id"
                  docType="profession_licence"
                />
              )}
              {profIdSelection === "addDetails" && (
                <AddProfIdDetails refetchUser={refetchUser} user={user} />
              )}
            </>
          )}
          {(checkDocTypeExists([
            "office_staff_id",
            "profession_licence",
            "diplomas",
            "education_certificate",
          ]) ||
            user.licence_no) && (
            <UploadedDoc statusText="professional id has been added" />
          )}
        </div>
      </div>
    </div>
  );
}

function UploadedDoc({ statusText }) {
  const { t } = useTranslation();

  return (
    <div>
      <Result status="success" subTitle={t(statusText)} />
    </div>
  );
}
