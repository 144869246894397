import AsyncCreatableSelect from "react-select/async-creatable";
import { useApolloClient } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { useUser } from "shared/contexts/user-context";
import { GET_MASTERLIST_TOPICS } from "shared/services";
import { LoadingSkeleton } from "shared/components";

export default function EditVideoTags({
  existingTagsTopics,
  video,
  setEditedTags,
}) {
  const { t } = useTranslation();
  const { user } = useUser();

  const client = useApolloClient();

  const getTopics = async (inputValue) => {
    const { data } = await client.query({
      query: GET_MASTERLIST_TOPICS,
      variables: {
        topic: inputValue,
      },
    });

    return data
      ? data.masterlist_topics?.masterlist_topics.map((t) => ({
          value: t.id,
          label: t.topic,
        }))
      : [];
  };

  const handleChange = (newValue, actionMeta) => {
    setEditedTags(newValue);
  };

  if (!existingTagsTopics) {
    return (
      <LoadingSkeleton
        props={{ count: 1, height: 40, style: { marginTop: "2rem" } }}
      />
    );
  }
  return (
    <div style={{ marginTop: "1.5rem" }}>
      <p style={{ marginBottom: ".5rem" }}>{t("Tags")}</p>

      <AsyncCreatableSelect
        isMulti
        isClearable={false}
        defaultValue={existingTagsTopics}
        loadOptions={getTopics}
        onChange={handleChange}
        placeholder={t("Select Tags")}
      />
      <div
        style={{
          marginTop: "1.5rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      ></div>
    </div>
  );
}
