import { NavLink, useHistory } from "react-router-dom";
import { useSubscription } from "@apollo/client";
import { Avatar, Select } from "antd";
import {
  MdPerson,
  MdSearch,
  MdPlayCircleFilled,
  MdLibraryBooks,
} from "react-icons/md";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import "./_search.scss";
import { SUB_SEARCH } from "shared/services";
import { getLocalToken } from "shared/utils/util-funcs";

import styles from "./navbar.module.scss";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import HashtagOutput from "../HashtagOutput/HashtagOutput";

export default function Search({}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { Option } = Select;
  const [isSearching, setIsSearching] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [result, setResult] = useState({
    professional: [],
    post: [],
    video: [],
  });
  const { data, loading } = useSubscription(SUB_SEARCH, {
    variables: {
      token: getLocalToken("auth"),
      keyword,
    },
    fetchPolicy: "network-only",
    skip: !isSearching,
  });
  useEffect(() => {
    if (data && data.search) {
      const propSelector = data.search.search.category;
      if (propSelector !== "complete") {
        setResult((pr) => ({
          ...pr,
          [propSelector]: [
            ...pr[propSelector],
            data.search.search[propSelector],
          ],
        }));
      }
    }
  }, [data]);

  useEffect(() => {
    if (keyword.length > 3) {
      setIsSearching(true);
    } else {
      setResult({ professional: [], post: [], video: [] });
    }
  }, [keyword]);

  return (
    <div className={clsx("search-bar", styles.searchContainer)}>
      <Select
        style={{ width: 500 }}
        showSearch
        value={keyword}
        // style={this.props.style}
        defaultActiveFirstOption={false}
        // showArrow={false}
        filterOption={false}
        onBlur={(e) => {
          setTimeout(() => {
            setIsSearching(false);
            setResult({ professional: [], post: [], video: [] });
          }, 1000);
        }}
        allowClear
        onSearch={(e) => setKeyword(e)}
        onChange={(e) => console.log(e)}
        notFoundContent={
          loading ? <Skeleton count={7} height={35} /> : "No result found"
        }
        placeholder={t("search")}
        suffixIcon={<MdSearch />}
        // loading={loading}
        onSelect={(e) => console.log(e)}
      >
        {Object.entries(result).map(([key, val]) => {
          return val.map((item) => (
            <Option value={item.id} title={key} key={item.id}>
              {key === "professional" && (
                <NavLink to={`/user/${item.id}`}>
                  <div className="search professional-selection flex items-center">
                    <Avatar
                      src={item.image}
                      icon={
                        <MdPerson
                          style={{
                            marginTop: ".5rem",
                            height: "1.5rem",
                            width: "1.5rem",
                          }}
                        />
                      }
                      style={{
                        marginRight: ".5rem",
                        height: "2.5rem",
                        width: "2.5rem",
                      }}
                    />
                    <div>
                      <p>
                        {item?.last_name} {item?.first_name}
                      </p>
                      <small>
                        {item.profession} ({item.organization})
                      </small>
                    </div>
                  </div>
                </NavLink>
              )}
              {key === "post" && (
                <NavLink to={`/post/${item.id}`}>
                  <div
                    style={{ cursor: "pointer" }}
                    className="search professional-selection flex items-center"
                  >
                    <Avatar
                      // src={item.image}
                      icon={
                        <MdLibraryBooks
                          style={{
                            marginTop: ".5rem",
                            height: "1.5rem",
                            width: "1.5rem",
                          }}
                        />
                      }
                      style={{
                        marginRight: ".5rem",
                        height: "2.5rem",
                        width: "2.5rem",
                      }}
                    />
                    {/* <p style={{ maxWidth: 100 }}>{item?.content}</p> */}
                    <HashtagOutput content={item?.content} />
                  </div>
                </NavLink>
              )}
              {key === "video" && (
                <NavLink to={`/video/${item.id}`}>
                  <div className="search professional-selection flex items-center">
                    <Avatar
                      // src={item.image}
                      icon={
                        <MdPlayCircleFilled
                          style={{
                            marginTop: ".5rem",
                            height: "1.5rem",
                            width: "1.5rem",
                          }}
                        />
                      }
                      style={{
                        marginRight: ".5rem",
                        height: "2.5rem",
                        width: "2.5rem",
                      }}
                    />
                    <p>{item?.title}</p>
                  </div>
                </NavLink>
              )}
              {/* <p key={item.id}>{item?.first_name || item?.content}</p> */}
            </Option>
          ));
        })}
        {/* {result.professional.map((item) => (
          <Option value="" key={item.id}>
            {item.id}
          </Option>
        ))} */}
        {/* <Option value=""></Option> */}
        {/* <Option value="jack2">Posts</Option>
        <Option value="jack3">Videos</Option> */}
      </Select>
    </div>
  );
}
