import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Logo from "assets/images/logo.svg";
import { useUser } from "shared/contexts/user-context";

import { formatStatusStr } from "./utils";
import styles from "./onboarding.module.scss";

export default function OnboardingStatus({ refetchUser }) {
  const { t } = useTranslation();

  const history = useHistory();
  const { user } = useUser();

  return (
    <div className={styles.container}>
      <div className="flex flex-col">
        <div className={styles.form}>
          <h2
            className={styles.title}
            style={{
              fontSize: "1rem",
              fontWeight: "normal",
              marginBottom: "1rem",
            }}
          >
            {t("Your profile status")}:
          </h2>
          <h1 className={styles.title}>{t(formatStatusStr(user.status))}</h1>
          {user.status !== "awaiting_approval" && (
            <p className={styles.subTitle}>{user.status_description}</p>
          )}
        </div>
      </div>
    </div>
  );
}
