import { Avatar, Button, message } from "antd";
import { useTranslation } from "react-i18next";
import { MdPerson } from "react-icons/md";
import { useQuery, useMutation } from "@apollo/client";

import { useUser } from "shared/contexts/user-context";
import {
  GET_USER,
  GET_FOLLOWS,
  CREATE_FOLLOW,
  DELETE_FOLLOW,
} from "shared/services";
import { LoadingSkeleton } from "shared/components";

import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";

export default function FollowingOrFollower({
  type,
  followObj,
  refetch,
  userId,
  gotoUser,
}) {
  const { t } = useTranslation();
  const { user } = useUser();

  const { id, professional_id, following_id } = followObj;
  const { loading: userLoading, data: userData } = useQuery(GET_USER, {
    variables: {
      userId: userId,
    },
  });

  const followedByMe = Boolean(
    user.followings.some((f) => f.professional_id === following_id)
  );
  const followId = user.followings.filter(
    (f) => f.professional_id === following_id
  )[0]?.id;

  const [createFollow, { loading: createFollowLoading }] = useMutation(
    CREATE_FOLLOW,
    {
      onCompleted(data) {
        if (data.create_professional_follow.error) {
          message.error(data.create_professional_follow.error);
        } else {
          refetch();
        }
      },
      refetchQueries: [
        {
          query: GET_FOLLOWS,
          variables: {
            professionalId: user.id,
          },
        },
        {
          query: GET_FOLLOWS,
          variables: {
            followingId: userId,
          },
        },
        {
          query: GET_USER,
          variables: {
            userId: user.id,
          },
        },
      ],
    }
  );

  const [deleteFollow, { loading: deleteFollowLoading }] = useMutation(
    DELETE_FOLLOW,
    {
      onCompleted(data) {
        if (data.delete_professional_follow.error) {
          message.error(data.delete_professional_follow.error);
        } else {
          refetch();
        }
      },
      refetchQueries: [
        {
          query: GET_FOLLOWS,
          variables: {
            professionalId: user.id,
          },
        },
        {
          query: GET_FOLLOWS,
          variables: {
            followingId: userId,
          },
        },
        {
          query: GET_USER,
          variables: {
            userId: user.id,
          },
        },
      ],
    }
  );

  return (
    <div className={styles.container}>
      {userLoading || !userData ? (
        <LoadingSkeleton />
      ) : (
        <>
          <div
            className={styles.user}
            onClick={() => gotoUser(userData.professional.professional.id)}
          >
            <Avatar
              src={userData.professional.professional.image}
              icon={
                <MdPerson
                  style={{
                    marginTop: ".5rem",
                  }}
                />
              }
              style={{
                cursor: "pointer",
                marginRight: "1rem",
                width: "2rem",
                height: "auto",
              }}
            />
            <p>
              {" "}
              {userData.professional.professional.last_name}
              {userData.professional.professional.first_name}
            </p>
          </div>
          {type === "following" ? (
            <Button
              type="primary"
              onClick={() =>
                deleteFollow({
                  variables: { id },
                })
              }
            >
              {t("unfollow")}
            </Button>
          ) : type === "follower" && !followedByMe ? (
            <Button
              type="primary"
              disabled={createFollowLoading || deleteFollowLoading}
              onClick={() =>
                createFollow({ variables: { followingId: professional_id } })
              }
            >
              {t("follow")}
            </Button>
          ) : (
            <Button
              type="primary"
              disabled={createFollowLoading || deleteFollowLoading}
              onClick={() =>
                deleteFollow({
                  variables: { id: followId },
                })
              }
            >
              {t("unfollow")}
            </Button>
          )}
        </>
      )}
    </div>
  );
}
