import i18n from "i18next";
import { initReactI18next } from "react-i18next";
/* ---- English Lang Translations ---- */
import en from "locales/en.json";
/* ---- Japanese translations ---- */
import jp from "locales/jp.json";

import { getLocalToken } from "./shared/utils/util-funcs";

let currentLocalLang = getLocalToken("locale");

// On local developement, english is default
// if (["localhost", "127.0.0.1"].includes(window.location.hostname)) {
//   currentLocalLang = "en";
// }

i18n.use(initReactI18next).init({
  lng: currentLocalLang || "jp",
  // lng: "jp",
  fallbackLng: "en",
  whitelist: ["en", "jp"],
  debug: false,
  ns: ["translation"],
  defaultNS: "translation",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: en,
    jp: jp,
  },
});

export default i18n;
