import { gql } from "@apollo/client";

/* ---- AUTHENTICATION ---- */
export const CREATE_PROFESSIONAL_DOC = gql`
  mutation CreateProfessionalDoc(
    $professionalId: UUID!
    $docType: ProfessionalDocType!
  ) {
    create_professional_doc(
      details: { professional_id: $professionalId, doc_type: $docType }
    ) {
      error
      professional_doc {
        id
      }
    }
  }
`;
