import { useState } from "react";
import { message, Upload, Divider, Result } from "antd";
import axios from "axios";
import { MdCloudUpload } from "react-icons/md";
import { useTranslation } from "react-i18next";

import TextLogo from "assets/images/logo-text.svg";

import { REST_API_ENDPOINT } from "config";
import { Button } from "shared/components";
import { useUser } from "shared/contexts/user-context";

import styles from "./onboarding.module.scss";
import { formatStatusStr } from "./utils";

export default function ReuploadDocs({ refetchUser }) {
  const { t } = useTranslation();

  const { user } = useUser();
  const { docs } = user;
  // console.log(
  //   docs
  //     .slice()
  //     .sort((a, b) => (a.status < b.status ? 1 : -1))
  //     .map((docs) => docs)
  // );

  return (
    <div className={styles.container}>
      <div className="flex flex-col">
        <div className={styles.form}>
          {/* <div className={styles.logoContainer}>
            <img src={TextLogo} className={styles.logo} alt="MeeM text logo" />
          </div> */}
          <h1 className={styles.title}>
            {t("reupload documents for approval")}
          </h1>
          {docs
            .slice()
            .sort((a, b) => (a.status < b.status ? 1 : -1))
            .map((doc) => {
              if (doc.status === "rejected") {
                return (
                  <>
                    <ReuploadDoc doc={doc} refetchUser={refetchUser} />
                    <Divider />
                  </>
                );
              }

              return (
                <>
                  <ApprovedDoc doc={doc} />
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
}

function UploadButton() {
  const { t } = useTranslation();

  return (
    <div>
      <MdCloudUpload />
      <div style={{ marginTop: 8 }}>{t("select document")}</div>
    </div>
  );
}

function ReuploadDoc({ doc, refetchUser }) {
  const { t } = useTranslation();
  const { id, doc_type, status, status_description } = doc;

  const [fileList, setFileList] = useState([]);

  const onChange = (info) => {
    if (
      ["image/jpeg", "application/pdf", "image/png"].includes(info.file.type)
    ) {
      setFileList(
        info.fileList.filter((file) =>
          ["image/jpeg", "application/pdf", "image/png"].includes(file.type)
        )
      );
    } else {
      return [];
    }
  };
  const [isDocUploading, setIsDocUploading] = useState(false);

  const handleUploadImage = async () => {
    setIsDocUploading(true);

    const formData = new FormData();

    formData.append("file", fileList[0].originFileObj);

    console.log(formData);
    const authToken = localStorage.getItem("meem-pro-auth");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      await axios.post(
        `${REST_API_ENDPOINT}/professional_docs/${id}/file`,
        formData,
        config
      );

      // Show success message
      message.success(`${t("document uploaded sucessfully")}`);
      refetchUser();
      // history.replace("/");
    } catch (err) {
      console.log({ err });
      setIsDocUploading(false);
      // Show error message
      message.error(`${t("something went wrong please try again")}`);
    }
  };

  return (
    <div>
      <div style={{ marginBottom: "1rem", lineHeight: "1.7" }}>
        <h2>{t(formatStatusStr(doc_type))}</h2>
        <h3>
          {t("status")}: {t(formatStatusStr(status))}
        </h3>
        {status_description && (
          <p>
            {t("reason")}: {status_description}
          </p>
        )}
      </div>

      <div className="prof-doc-upload">
        <Upload
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          beforeUpload={(file) => {
            if (
              !["image/jpeg", "application/pdf", "image/png"].includes(
                file.type
              )
            ) {
              message.error(
                `${t("Allowed file extensions")}: .jpg, .jpeg, .png, .pdf`
              );

              setFileList([]);
            } else {
              setFileList([file]); // Handle file upload call manually
              return false;
            }
          }}
        >
          {fileList.length < 1 && <UploadButton />}
        </Upload>
        {fileList.length >= 1 && (
          <Button
            type="primary"
            onClick={() => handleUploadImage()}
            disabled={fileList.length < 1 || isDocUploading}
          >
            {t("upload")}
          </Button>
        )}
      </div>
    </div>
  );
}

function ApprovedDoc({ doc }) {
  const { t } = useTranslation();

  const { id, doc_type, status, status_description } = doc;

  return (
    <div>
      <Result
        status="success"
        title={t(formatStatusStr(doc_type))}
        subTitle={status_description}
      />
    </div>
  );
}
