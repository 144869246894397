import { MentionsInput, Mention } from "react-mentions";

export default function HashtagOutput({ content }) {
  return (
    <MentionsInput className="hashtag-contentarea" value={content} disabled>
      <Mention
        displayTransform={(id, display) => `#${display}`}
        markup="@@@____id__^^____display__@@@^^^"
      />
    </MentionsInput>
  );
}
