import "shared/styles/app.global.scss";
import { VideoSection } from "shared/components";
import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import {
  NEW_VIDEO_FEEDS,
  RECOMMENDATION_SYSTEM,
  VIDEO_FEEDS,
} from "shared/services";
import { useEffect } from "react";
import { useFeed } from "shared/contexts/feed-context";

const dispatchType = {
  "most-viewed": "POPULAR",
  recommended: "RECOMMENDED",
};

export default function VideosFeed() {
  const [{ newVideos, popularVideos, recommended }, dispatch] = useFeed();
  const { data, loading, error } = useQuery(NEW_VIDEO_FEEDS, {
    variables: { limit: 10 },
  });

  const clientQuery = useApolloClient();

  const fetchVideos = async (type) => {
    try {
      const res = await clientQuery.query({
        query: RECOMMENDATION_SYSTEM,
        variables: {
          type,
        },
      });

      dispatch({
        type: `ADD_${dispatchType[type]}_VIDEOS`,
        videos: res.data.recommendation_system?.videos,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!loading && data) {
      const res = data.video_feeds.video_feeds.map((item) => {
        const { video, id } = item;
        return {
          ...video,
          serialNo: id,
        };
      });
      dispatch({
        type: "ADD_NEW_VIDEOS",
        videos: res,
      });
    }
  }, [loading, data]);

  useEffect(() => {
    fetchVideos("most-viewed");
    fetchVideos("recommended");
  }, []);

  return (
    <div className="feedContainer b-rad-4">
      <VideoSection title="new uploads" data={newVideos} loading={loading} />
      <VideoSection title="most watched" data={popularVideos} />
      <VideoSection title="recommended" data={recommended} />
    </div>
  );
}
