import { useState } from "react";
import { Modal, Radio, message } from "antd";
import { useTranslation } from "react-i18next";
import { MdHttps, MdLanguage } from "react-icons/md";
import { useMutation } from "@apollo/client";

import { useUser } from "shared/contexts/user-context";
import { UPDATE_VIDEO } from "shared/services";

export default function PostPrivacy({ status, videoPostId, refetchVideoPost }) {
  const [mode, setmode] = useState();
  return (
    <>
      {mode === "EDIT_PRIVACY" && (
        <VideoPostPrivacyUpdateModal
          isOpen={mode === "EDIT_PRIVACY"}
          onClose={() => setmode()}
          status={status}
          videoPostId={videoPostId}
          refetchVideoPost={refetchVideoPost}
        />
      )}
      <p style={{ cursor: "pointer" }} onClick={() => setmode("EDIT_PRIVACY")}>
        {status === "only_me" ? (
          <MdHttps style={{ fontSize: "1rem" }} />
        ) : (
          <MdLanguage style={{ fontSize: "1rem" }} />
        )}
      </p>
    </>
  );
}

function VideoPostPrivacyUpdateModal({
  isOpen,
  onClose,
  status,
  refetchVideoPost,
  videoPostId,
}) {
  const { t } = useTranslation();
  const { user } = useUser();

  const [updateVideo, { loading }] = useMutation(UPDATE_VIDEO);

  const [selectedValue, setSelectedValue] = useState();
  const onChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleUpdatePostPrivacy = async () => {
    const res = await updateVideo({
      variables: { id: videoPostId, privacyPolicy: selectedValue },
    });
    if (res.data.update_video.error) {
      message.error(res.data.update_video.error);
      return;
    }
    message.success(`${t("post privacy changed successfully")}`);
    refetchVideoPost();
    onClose();
  };

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
    fontSize: "1rem",
    marginBottom: ".5rem",
  };

  return (
    <Modal
      title={t("Change post privacy")}
      visible={isOpen}
      onCancel={onClose}
      width={320}
      okText={t("update")}
      cancelText={t("cancel")}
      confirmLoading={loading}
      onOk={() => {
        handleUpdatePostPrivacy();
      }}
    >
      <Radio.Group
        onChange={onChange}
        value={selectedValue}
        defaultValue={status}
      >
        <Radio style={radioStyle} value="public">
          {t("Public")}
        </Radio>
        <Radio style={radioStyle} value="only_me">
          {t("Only me")}
        </Radio>
      </Radio.Group>
    </Modal>
  );
}
