import { useState, useEffect } from "react";
import { message, Upload } from "antd";
import axios from "axios";
import clsx from "clsx";
import { useMutation } from "@apollo/client";
import { MdCloudUpload } from "react-icons/md";

import { REST_API_ENDPOINT } from "config";
import { SelectDropdownInput, Button } from "shared/components";

import { CREATE_PROFESSIONAL_DOC } from "./onboarding.service";
import styles from "./onboarding.module.scss";

import { useTranslation } from "react-i18next";

function UploadButton() {
  const { t } = useTranslation();

  return (
    <div>
      <MdCloudUpload />
      <div style={{ marginTop: 8 }}>{t("Select file")}</div>
    </div>
  );
}

export default function UploadDoc({
  user,
  refetchUser,
  docTypeName,
  docTypesList,
  docType,
}) {
  const { t } = useTranslation();

  const [createdDocId, setCreatedDocId] = useState("");

  const [errors, setErrors] = useState();
  const [selectedDocType, setSelectedDocType] = useState();

  const [createProfessionalDoc, { loading }] = useMutation(
    CREATE_PROFESSIONAL_DOC,
    {
      onCompleted(data) {
        if (data.create_professional_doc.error) {
          message.error(data.create_professional_doc.error);
        }
      },
    }
  );

  const [fileList, setFileList] = useState([]);

  const onChange = (info) => {
    if (
      ["image/jpeg", "application/pdf", "image/png"].includes(info.file.type)
    ) {
      setFileList(
        info.fileList.filter((file) =>
          ["image/jpeg", "application/pdf", "image/png"].includes(file.type)
        )
      );
    } else {
      return [];
    }
  };
  const [isDocUploading, setIsDocUploading] = useState(false);

  const handleUploadImage = async () => {
    setIsDocUploading(true);

    const formData = new FormData();

    formData.append("file", fileList[0].originFileObj);

    const authToken = localStorage.getItem("meem-pro-auth");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      const doc = await createProfessionalDoc({
        variables: {
          professionalId: user.id,
          docType: docType,
        },
      });
      const docId = doc?.data?.create_professional_doc?.professional_doc?.id;

      if (!docId) {
        throw new Error("Error while generating docId");
      }

      await axios.post(
        `${REST_API_ENDPOINT}/professional_docs/${docId}/file`,
        formData,
        config
      );

      // Show success message
      message.success(`${t("document uploaded sucessfully")}`);
      refetchUser();
      setIsDocUploading(false);
      // history.replace("/");
    } catch (err) {
      setIsDocUploading(false);
      console.log(err);
      // Show error message
      message.error(`${t("something went wrong please try again")}`);
    }
  };

  return (
    <div>
      <div className="select-form-input" style={{ marginBottom: "1rem" }}>
        <label htmlFor="prof-doc-type" className={clsx("formLabel")}>
          {t(docTypeName)}
          {errors?.doc && <span className={"formErrortext"}>{errors.doc}</span>}
        </label>
        {/* <SelectDropdownInput
          optionValue="value"
          optionKey="name"
          optionsArr={docTypesList}
          onChange={(val) => setSelectedDocType(val)}
        /> */}
      </div>
      {/* {!createdDocId && selectedDocType && (
        <div className={styles.formBtn}>
          <Button
            tabIndex="2"
            type="button"
            // classes={[styles.submitButton]}
            inlineStyles={{ padding: ".75rem 0" }}
            onClick={() => {
              if (!selectedDocType) {
                setErrors({ doc: "required" });
                return;
              }

              createProfessionalDoc({
                variables: {
                  professionalId: user.id,
                  docType: selectedDocType,
                },
              });
            }}
            disabled={loading}
            isProcessing={loading}
          >
            {t("Next")}
          </Button>
        </div>
      )} */}

      <div className="prof-doc-upload">
        <Upload
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          beforeUpload={(file) => {
            if (
              !["image/jpeg", "application/pdf", "image/png"].includes(
                file.type
              )
            ) {
              message.error(
                `${t("Allowed file extensions")}: .jpg, .jpeg, .png, .pdf`
              );

              setFileList([]);
            } else {
              setFileList([file]); // Handle file upload call manually
              return false;
            }
          }}
        >
          {fileList.length < 1 && <UploadButton />}
        </Upload>
        {fileList.length >= 1 && (
          <Button
            type="primary"
            onClick={() => handleUploadImage()}
            disabled={fileList.length < 1 || isDocUploading}
            isProcessing={isDocUploading}
          >
            {t("Click to upload")}
          </Button>
        )}
      </div>
    </div>
  );
}
