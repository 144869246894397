// awaiting_payment -> Awaiting Payment
// https://www.digitalocean.com/community/tutorials/js-capitalizing-strings
export const formatStatusStr = (str) => {
  return str
    .split("_")
    .map((i) =>
      i.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
    )
    .join(" ");
};

/* ---- Create the data-structure needed for cascaded specialisation ---- */
const IsKeyExists = (arrToCheck, value) => {
  return arrToCheck?.some((data) => data["value"] === value);
};

const createPushChildrenNode = (arrayToPushInto, value) => {
  if (!arrayToPushInto?.children) {
    arrayToPushInto.children = [
      {
        value: value,
        label: value,
      },
    ];
  } else {
    return arrayToPushInto["children"].push({ value: value, label: value });
  }
};

const createRootNode = (arrayToPushInto, value) => {
  arrayToPushInto.push({
    value: value,
    label: value,
  });
};

const getPosition = (arrToCheck, valueToCheck) => {
  return arrToCheck.findIndex((d) => d.value === valueToCheck);
};

export const loopOverCascade = (base) => {
  let rootArr = [];

  base.map((node) => {
    let majorPos, middlePos, subPos, specificPos;

    // node.major_classification
    if (!IsKeyExists(rootArr, node.major_classification)) {
      createRootNode(rootArr, node.major_classification);
    }

    // node.middle_classification
    if (!node.middle_classification) {
      return;
    }

    majorPos = getPosition(rootArr, node.major_classification);
    // Check if exists in that major cs. children
    // if yes, just note the pos
    // if no, push to children, if no children, create children and push
    if (!IsKeyExists(rootArr[majorPos]?.children, node.middle_classification)) {
      createPushChildrenNode(rootArr[majorPos], node.middle_classification);
    }

    // node.sub_category
    if (!node.sub_category) {
      return;
    }

    majorPos = getPosition(rootArr, node.major_classification);
    middlePos = getPosition(
      rootArr[majorPos].children,
      node.middle_classification
    );
    if (
      !IsKeyExists(
        rootArr[majorPos]?.children[middlePos]?.children,
        node.sub_category
      )
    ) {
      createPushChildrenNode(
        rootArr[majorPos].children[middlePos],
        node.sub_category
      );
    }

    // node.specific_category
    if (!node.specific_category) {
      return;
    }

    majorPos = getPosition(rootArr, node.major_classification);
    middlePos = getPosition(
      rootArr[majorPos].children,
      node.middle_classification
    );
    subPos = getPosition(
      rootArr[majorPos].children[middlePos].children,
      node.sub_category
    );

    if (
      !IsKeyExists(
        rootArr[majorPos]?.children[middlePos]?.children[subPos].children,
        node.specific_category
      )
    ) {
      createPushChildrenNode(
        rootArr[majorPos].children[middlePos].children[subPos],
        node.specific_category
      );
    }
  });

  return rootArr;
};
