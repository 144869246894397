import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { MdVisibilityOff, MdVisibility } from "react-icons/md";

import styles from "./password-input.module.scss";
import { useTranslation } from "react-i18next";

export default function PasswordInput({
  desc = "",
  register,
  errors,
  tabIndex,
  value,
  onChange = () => {},
  showForgotPassword = false,
  width,
  label = "password",
  name = "password",
  required,
}) {
  const { t } = useTranslation();

  /* ---- Toggle password visibility ---- */
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div
      className={clsx(styles.formField, width === "half" && styles.halfWidth)}
    >
      <div className={styles.passwordField}>
        <div>
          <label htmlFor="password">{t(label)}</label>
          {desc ? (
            <span className={styles.formErrortext}>{t(desc)}</span>
          ) : (
            errors.password && (
              <span className={styles.formErrortext}>
                {t(errors.password.message)}
              </span>
            )
          )}
        </div>
        {showForgotPassword && (
          <Link
            to="/forgot-password"
            tabIndex={tabIndex + 1}
            className={styles.link}
          >
            {t("forgot your password?")}
          </Link>
        )}
      </div>

      <div className={`flex ${styles.passwordFieldDisplay}`}>
        <input
          tabIndex={tabIndex}
          id={name}
          name={name}
          style={errors.password && { border: "1px solid red" }}
          autocomplete="new-password"
          // type={isPasswordVisible ? "text" : "password"}
          type="password"
          ref={register}
          value={value}
          onChange={onChange(name)}
          className={clsx(styles.passwordInput, {
            [styles.formFieldError]: errors?.name,
          })}
        />
      </div>
    </div>
  );
}
