import { useState, useEffect } from "react";
import { Button, message } from "antd";
import { FiThumbsUp } from "react-icons/fi";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { useUser } from "shared/contexts/user-context";
import { GET_LIKES, CREATE_LIKE, DELETE_LIKE } from "shared/services";
import { LoadingSkeleton } from "shared/components";

import styles from "./styles.module.scss";

export default function PostLikes({ post, video }) {
  const { t } = useTranslation();
  const { user } = useUser();

  const [postLikedByMe, setPostLikedByMe] = useState(false);
  const [likeId, setlikeId] = useState();

  const {
    loading: likesLoading,
    data,
    refetch,
  } = useQuery(GET_LIKES, {
    variables: {
      professionalId: user.id,
      postId: post?.id,
      videoId: video?.id,
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: totalLikesData, refetch: refetchTotalLikes } = useQuery(
    GET_LIKES,
    {
      variables: { postId: post?.id, videoId: video?.id },
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    if (!likesLoading && data) {
      setPostLikedByMe(Boolean(data?.likes.total_count === 1));
      setlikeId(data?.likes?.likes[0]?.id);
    }
  }, [likesLoading]);

  const [createLike, { loading }] = useMutation(CREATE_LIKE, {
    onCompleted(data) {
      if (data.create_like.error) {
        message.error(data.create_like.error);
      } else {
        refetchTotalLikes();
        refetch();
      }
    },
  });

  const [deleteLike, { loading: deleteLikeLoading }] = useMutation(
    DELETE_LIKE,
    {
      onCompleted(data) {
        if (data.delete_like.error) {
          message.error(data.delete_like.error);
        } else {
          refetchTotalLikes();
          refetch();
        }
      },
    }
  );

  return (
    <div className={styles.action}>
      <Button
        className={clsx(styles.button, "post-action-btn")}
        disabled={loading || deleteLikeLoading || likesLoading}
        onClick={() => {
          if (!postLikedByMe) {
            createLike({ variables: { postId: post?.id, videoId: video?.id } });
          } else {
            deleteLike({
              variables: { likeId },
            });
          }
        }}
      >
        <FiThumbsUp
          className={(styles.actionIcon, postLikedByMe && styles.liked)}
        />
        <p style={{ marginLeft: "2px" }}>
          {totalLikesData?.likes?.total_count || <LoadingSkeleton />}
        </p>
        <p style={{ margin: "0 2px" }}>·</p>
        <p>{t("like")}</p>
      </Button>
    </div>
  );
}
