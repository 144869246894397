import { MentionsInput, Mention } from "react-mentions";
import { useApolloClient } from "@apollo/client";

import { GET_MASTERLIST_TOPICS } from "shared/services";

export default function HashtagInput({
  placeholder,
  postContent,
  onContentChange,
  setTags,
}) {
  const client = useApolloClient();

  const fetchTopics = async (query, callback) => {
    if (!query) return;

    client
      .query({
        query: GET_MASTERLIST_TOPICS,
        variables: {
          topic: query,
        },
      })
      .then((res) =>
        res.data.masterlist_topics.masterlist_topics.map((t) => ({
          display: t.topic,
          id: t.id,
        }))
      )
      .then(callback);
  };

  const onChange = (e, newValue, newPlainTextValue, mentions) => {
    setTags(mentions);
    onContentChange(e.target.value);
  };

  return (
    <MentionsInput
      className="hashtag-textarea"
      allowSpaceInQuery
      value={postContent}
      onChange={onChange}
      placeholder={placeholder}
    >
      <Mention
        data={fetchTopics}
        trigger="#"
        // onAdd={(id, display) => onAddTag(id)}
        displayTransform={(id, display) => `#${display}`}
        markup="@@@____id__^^____display__@@@^^^"
        appendSpaceOnAdd={true}
      />
    </MentionsInput>
  );
}
