import { Link, useHistory } from "react-router-dom";
import {
  gql,
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { Popover, Avatar, Badge, notification } from "antd";
import {
  MdNotifications,
  MdPlayCircleFilled,
  MdLibraryBooks,
} from "react-icons/md";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import "./_search.scss";
import { useUser } from "shared/contexts/user-context";
import {
  NOTIFICATIONS,
  SET_NOTIFICATION_SEEN,
  SUB_NOTIFICATION,
} from "shared/services";
import { getLocalToken } from "shared/utils/util-funcs";

import styles from "./navbar.module.scss";
import { formatRelativeTimeFromNow } from "shared/utils/util";

const icons = {
  post: (
    <MdLibraryBooks
      style={{
        height: "1rem",
        width: "1rem",
      }}
    />
  ),
  video: (
    <MdPlayCircleFilled
      style={{
        height: "1rem",
        width: "1rem",
      }}
    />
  ),
};

export default function Notifications() {
  const { user } = useUser();

  const openNotificationWithIcon = (isPost = false, msg) => {
    notification.info({
      message: msg,
      icon: isPost ? <MdLibraryBooks /> : <MdPlayCircleFilled />,
      placement: "bottomLeft",
      onClick: "",
    });
  };

  useSubscription(SUB_NOTIFICATION, {
    variables: { token: getLocalToken("auth"), professional_id: user.id },
    skip: !user.id,
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const info = data.notification.notification;
      if (info) {
        openNotificationWithIcon(info.post_id, info.content);
      }
    },
  });

  return (
    <Popover placement="bottomRight" content={<NotificationMenu />}>
      {/* color="#3385ff" offset={[-1, 5]} */}
      <Badge count={1} style={{ backgroundColor: "#180f77" }}>
        <MdNotifications
          className={styles.notificationIcon}
          style={{
            cursor: "pointer",
            fontSize: 28,
            opacity: ".4",
          }}
        />
      </Badge>
    </Popover>
  );
}

function NotificationMenu() {
  const { t } = useTranslation();
  const client = useApolloClient();
  const history = useHistory();
  const { user } = useUser();
  const [setNotificationSeen] = useMutation(SET_NOTIFICATION_SEEN);

  const { loading, data } = useQuery(NOTIFICATIONS, {
    variables: {
      professional_id: user.id,
    },
    fetchPolicy: "cache-and-network",
    skip: !user.id,
  });

  const updateSeen = ({ type, info }) => {
    client.writeFragment({
      id: `Notification:${info.id}`,
      fragment: gql`
        fragment ob on Notification {
          seen
        }
      `,
      data: {
        seen: true,
      },
    });

    if (!info.seen) setNotificationSeen({ variables: { id: info.id } });

    history.push(`/${type}/${info[`${type}_id`]}`);
  };

  return (
    <div className={clsx(styles.accountMenu, styles.notificationMenu)}>
      <h2>{t("notifications")}</h2>
      {data?.get_notification?.notifications.length > 0 &&
        data?.get_notification?.notifications.map((item) => (
          <NotificationMenuItem
            type={item.video_id ? "video" : "post"}
            info={item}
            key={item.id}
            updateSeen={updateSeen}
          />
        ))}
    </div>
  );
}

/* ---- Notification Menu ---- */

function NotificationMenuItem({ username, info, type, updateSeen }) {
  const { id, content, seen, updated_at } = info;

  return (
    <div
      className={styles.notificationItem}
      onClick={() => updateSeen({ type, info })}
      style={{ cursor: "pointer" }}
    >
      <div>
        <Avatar
          src={null}
          icon={icons[type]}
          style={{
            cursor: "pointer",
            marginRight: ".5rem",
          }}
        />
      </div>
      <div>
        <p className={clsx(styles.notificationItemTitle, seen && styles.seen)}>
          {content}
        </p>
        <p style={{ opacity: ".5", textAlign: "end" }}>
          <time dateTime={updated_at} title={new Date(updated_at)}>
            {formatRelativeTimeFromNow(updated_at)}
          </time>
        </p>
      </div>
    </div>
  );
}
