import { useState } from "react";
import { Modal, message } from "antd";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PasswordInput, Button } from "shared/components";
import { setLocalToken } from "shared/utils/util-funcs";
import { LOGIN } from "shared/services";
import { useUser } from "shared/contexts/user-context";

export const useLoginHook = () => {
  const { t } = useTranslation();

  const [formStatus, setFormStatus] = useState({
    status: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [login] = useMutation(LOGIN);

  const initiateLogin = async (data) => {
    setIsSubmitting(true);
    const res = await login({
      variables: data,
    });
    if (res?.data?.professional_login?.error) {
      message.error(res?.data?.professional_login?.error);
      setIsSubmitting(false);
      return { success: false };
    } else if (res?.data?.professional_login?.token) {
      setLocalToken("auth", res.data.professional_login.token.access_token);
      setLocalToken(
        "auth-createdAt",
        res.data.professional_login.token.created_at
      );

      // await onSuccessFunc();

      setIsSubmitting(false);
      return { success: true };
    }
  };

  return { initiateLogin, loading: isSubmitting };
};
