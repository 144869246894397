import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import { GET_FOLLOWS } from "shared/services";
import { FollowingOrFollower, LoadingSkeleton } from "shared/components";
import { useHistory } from "react-router-dom";

export default function ViewFollowers({ isOpen, onClose, currentUserId }) {
  const { t } = useTranslation();
  const history = useHistory();

  const { loading, data, refetch } = useQuery(GET_FOLLOWS, {
    variables: { followingId: currentUserId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const gotoUser = (id) => {
    onClose();
    history.push(`/user/${id}`);
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      footer={null}
      title={t("followers")}
    >
      {loading || !data ? (
        <>
          <LoadingSkeleton props={{ count: 4 }} />
        </>
      ) : (
        data?.professional_follows?.professional_follows.map((follower) => (
          <FollowingOrFollower
            key={follower.id}
            type="follower"
            followObj={follower}
            refetch={refetch}
            userId={follower.professional_id}
            gotoUser={gotoUser}
          />
        ))
      )}
    </Modal>
  );
}
