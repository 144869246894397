export const getLocalToken = (tokenType: string): any => {
  // if is auth return default auth token;
  if (tokenType === "auth") {
    return localStorage.getItem("meem-pro-auth");
  } else if (tokenType === "locale") {
    return localStorage.getItem("meem-pro-locale");
  } else if (tokenType === "auth-createdAt") {
    return localStorage.getItem("meem-pro-auth-createdAt");
  }
};

export const setLocalToken = (tokenType: string, token: string): any => {
  if (tokenType === "auth") {
    // remove exisitng token,
    localStorage.removeItem("meem-pro-auth");
    // set new token
    localStorage.setItem("meem-pro-auth", token);
  } else if (tokenType === "locale") {
    localStorage.setItem("meem-pro-locale", token);
  } else if (tokenType === "auth-createdAt") {
    localStorage.setItem("meem-pro-auth-createdAt", token);
  }
};

export const removeLocalToken = (tokenType: string): void => {
  if (tokenType === "auth") {
    localStorage.removeItem("meem-pro-auth");
  }
};

export const isInputValid = (
  inputDataType: string,
  inputVal: string
): boolean => {
  // check if input is valid
  // can be extended with regex later
  // only alphanumeric
  if (inputVal && inputDataType === "username") {
    return !!inputVal.match(/^[0-9a-z]+$/);
  }

  return true;
};

export const convertBytesToMb = (bytes: number): number => {
  return bytes * 0.000001;
};

export const convertMbToGb = (mb: number): number => {
  return mb * 0.001;
};

export const getPercentageOfTotal = (usage: number, total: number): number => {
  return (usage / total) * 100;
};

export const bytesToSize = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
