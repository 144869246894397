import { FiFileMinus } from "react-icons/fi";
import { useTranslation } from "react-i18next";

export default function NoPostExists({ text = "No posts found" }) {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div
        className="flex flex-col justify-center items-center"
        style={{ padding: "3rem" }}
      >
        <FiFileMinus
          style={{ fontSize: "4rem", marginBottom: "1rem", opacity: ".75" }}
        />
        <h2>{t(text)}</h2>
      </div>
    </div>
  );
}
