import { useState } from "react";
import { format } from "date-fns";
import { Comment, Avatar, Form, Button, Tooltip, Input, message } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { MdPerson } from "react-icons/md";
import { useTranslation } from "react-i18next";

import { formatRelativeTimeFromNow } from "shared/utils/util";
import { useUser } from "shared/contexts/user-context";
import { GET_COMMENTS, CREATE_COMMENT, DELETE_COMMENT } from "shared/services";
import { LoadingSkeleton } from "shared/components";

import styles from "./styles.module.scss";

const { TextArea } = Input;

export default function PostComments({
  post,
  video,
  toggleShowComments,
  refetchTotalComments,
}) {
  const { t } = useTranslation();

  const { loading, data, refetch } = useQuery(GET_COMMENTS, {
    variables: {
      postId: post?.id,
      videoId: video?.id,
      sort: "created_at",
      order: "DESC",
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  return (
    <>
      <hr className={styles.divider} />
      {loading && !data ? (
        <LoadingSkeleton />
      ) : (
        <>
          <CommentEditor
            refetchTotalComments={refetchTotalComments}
            post={post}
            video={video}
            refetchComments={refetch}
            toggleShowComments={toggleShowComments}
          />
          {data.comments.comments.map((comment) => (
            <PostComment
              key={comment.id}
              comment={comment}
              refetchComments={refetch}
              refetchTotalComments={refetchTotalComments}
            />
          ))}
        </>
      )}
    </>
  );
}

/* ---- Commnent and replies ---- */
function PostComment({
  children,
  comment,
  post,
  refetchTotalComments,
  refetchComments,
  refetchCommentReplies = () => {},
}) {
  const { t } = useTranslation();
  const { user } = useUser();
  const { id, content, professional, created_at } = comment;

  const postCommentByMe = professional.id === user.id;

  const {
    loading,
    data,
    refetch: refetchCommentRepliesFn,
  } = useQuery(GET_COMMENTS, {
    variables: { commentId: comment.id, sort: "created_at", order: "DESC" },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const [deleteComment, { loading: deleteCommentLoading }] = useMutation(
    DELETE_COMMENT,
    {
      onCompleted(data) {
        if (data.delete_comment.error) {
          message.error(data.delete_comment.error);
        } else {
          refetchTotalComments();
          refetchComments();
          refetchCommentReplies();
          message.success(`${t("Comment deleted successfully")}`);
        }
      },
    }
  );

  let actions = [
    <span
      key="comment-nested-reply-to"
      onClick={() => setreplyToComment(!replyToComment)}
    >
      {t("Reply")}
    </span>,
  ];

  // User can delete only their own commnent
  if (postCommentByMe) {
    actions.push(
      <span
        key="comment-nested-reply-to"
        onClick={() =>
          !deleteCommentLoading &&
          deleteComment({
            variables: { commentId: comment.id },
          })
        }
      >
        {t("Delete")}
      </span>
    );
  }

  const [replyToComment, setreplyToComment] = useState();
  return (
    <Comment
      actions={actions}
      author={
        <p>
          {professional.last_name} {professional.first_name}
        </p>
      }
      avatar={
        <Avatar
          src={professional.image}
          icon={
            <MdPerson
              style={{
                marginTop: ".25rem",
              }}
            />
          }
        />
      }
      content={<p>{content}</p>}
      datetime={
        <Tooltip title={format(new Date(created_at), "yyyy-MM-dd hh:mm:ss")}>
          <span>{formatRelativeTimeFromNow(created_at)}</span>
        </Tooltip>
      }
    >
      {replyToComment && (
        <CommentEditor
          comment={comment}
          refetchTotalComments={refetchTotalComments}
          refetchComments={refetchComments}
          refetchCommentReplies={refetchCommentRepliesFn}
          hideReplyToComment={() => setreplyToComment(false)}
        />
      )}
      {loading && !data ? (
        <LoadingSkeleton />
      ) : (
        <>
          {data.comments.comments.map((comment) => (
            <PostComment
              key={comment.id}
              comment={comment}
              refetchComments={refetchComments}
              refetchTotalComments={refetchTotalComments}
              refetchCommentReplies={refetchCommentRepliesFn}
            />
          ))}{" "}
        </>
      )}
    </Comment>
  );
}

/* ---- New comment editor ---- */
function CommentEditor({
  comment,
  post,
  video,
  refetchTotalComments,
  refetchComments,
  refetchCommentReplies,
  toggleShowComments,
  hideReplyToComment,
}) {
  const { t } = useTranslation();
  const { user } = useUser();

  const [commentContent, setCommentContent] = useState();
  const handleCommentInput = (e) => {
    setCommentContent(e.target.value);
  };

  const [createComment, { loading: createCommentLoading }] = useMutation(
    CREATE_COMMENT,
    {
      onCompleted(data) {
        if (data.create_comment.error) {
          message.error(data.create_comment.error);
        } else {
          refetchTotalComments();
          refetchComments();
          setCommentContent();
          if (post || video) {
            toggleShowComments();
          } else {
            hideReplyToComment();
            refetchCommentReplies();
          }
        }
      },
    }
  );

  return (
    <Comment
      avatar={
        <Avatar
          src={user.image}
          icon={
            <MdPerson
              style={{
                marginTop: ".25rem",
              }}
            />
          }
        />
      }
      content={
        <>
          <Form.Item>
            <TextArea
              rows={2}
              onChange={handleCommentInput}
              value={commentContent}
            />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              loading={createCommentLoading}
              onClick={() => {
                if (post) {
                  // Either base post comment or comment reply
                  createComment({
                    variables: { postId: post.id, content: commentContent },
                  });
                } else if (video) {
                  createComment({
                    variables: { videoId: video.id, content: commentContent },
                  });
                } else {
                  createComment({
                    variables: {
                      commentId: comment.id,
                      content: commentContent,
                    },
                  });
                }
              }}
              type="primary"
            >
              {t("Add Comment")}
            </Button>
          </Form.Item>
        </>
      }
    />
  );
}
