import { useState } from "react";
import { message, Upload, Modal, Button } from "antd";
import ImgCrop from "antd-img-crop";
import { MdCloudUpload } from "react-icons/md";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { REST_API_ENDPOINT } from "config";

import { useUser } from "shared/contexts/user-context";
import { getLocalToken } from "shared/utils/util-funcs";

export default function UploadProfilePicture({ isOpen, onClose }) {
  const { t } = useTranslation();
  const { user, setUser } = useUser();

  const [fileList, setFileList] = useState([]);

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const onChange = (info) => {
    if (["image/jpeg", "image/png"].includes(info.file.type)) {
      setFileList(
        info.fileList.filter((file) =>
          ["image/jpeg", "image/png"].includes(file.type)
        )
      );
    } else {
      return [];
    }
  };
  const [isUploading, setIsUploading] = useState(false);
  const handleUploadFile = async () => {
    setIsUploading(true);
    const formData = new FormData();

    formData.append("image", fileList[0].originFileObj);

    const authToken = getLocalToken("auth");
    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      const res = await axios.put(
        `${REST_API_ENDPOINT}/professionals/${user.id}/image`,
        formData,
        config
      );

      setUser({ ...user, image: res.data.uploaded_link });
      message.success(`${t("profile picture uploaded successfully")}`);
      setIsUploading(false);
      onClose();
    } catch (err) {
      console.log({ err });
      message.error(
        `${t("something went wrong with upload please try again")}`
      );
      onClose();
      return;
    }
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={!isUploading && onClose}
      footer={null}
      title={t("Upload profile picture")}
    >
      <div>
        <div className="prof-doc-upload">
          <ImgCrop shape="round">
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              beforeUpload={(file) => {
                if (!["image/jpeg", "image/png"].includes(file.type)) {
                  message.error(
                    `${t("Allowed file extensions")}: .jpg, .jpeg, .png`
                  );
                  setFileList([]);
                  return false;
                } else {
                  setFileList([file]); // Handle file upload call manually
                  return true;
                }
              }}
            >
              {fileList.length < 1 && <UploadButton />}
            </Upload>
          </ImgCrop>

          {fileList.length > 0 && (
            <Button
              style={{ width: "100%" }}
              type="primary"
              onClick={() => handleUploadFile()}
              loading={isUploading}
              disabled={isUploading}
            >
              {t("Upload")}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}

function UploadButton() {
  const { t } = useTranslation();

  return (
    <div>
      <MdCloudUpload />
      <div style={{ marginTop: 8 }}>{t("Select file")}</div>
    </div>
  );
}
