import { createContext, useReducer, useContext, useEffect } from "react";

const FeedStateContext = createContext();
const FeedDispatchContext = createContext();

/* ---- Initial State Object ---- */
const initialState = {
  posts: [],
  newVideos: [],
  popularVideos: [],
  recommended: [],
};

/* ---- Transforms state based on action ---- */
const feedReducer = (state, action) => {
  switch (action.type) {
    case "ADD_POSTS": {
      return {
        ...state,
        posts: action.posts,
      };
    }

    case "ADD_POST": {
      return {
        ...state,
        posts: [action.post].concat(state.posts),
      };
    }
    case "ADD_MORE_POSTS": {
      return {
        ...state,
        posts: state.posts.concat(action.posts),
      };
    }
    case "ADD_NEW_VIDEOS": {
      return {
        ...state,
        newVideos: action.videos,
      };
    }

    case "ADD_NEW_VIDEO": {
      return {
        ...state,
        newVideos: [action.video].concat(state.newVideos),
      };
    }
    case "ADD_MORE_VIDEOS": {
      return {
        ...state,
        posts: state.videos.concat(action.videos),
      };
    }
    case "ADD_POPULAR_VIDEOS": {
      return {
        ...state,
        popularVideos: state.popularVideos.concat(action.videos),
      };
    }
    case "ADD_RECOMMENDED_VIDEOS": {
      return {
        ...state,
        recommended: state.recommended.concat(action.videos),
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const FeedProvider = ({ children }) => {
  const [state, dispatch] = useReducer(feedReducer, initialState);

  return (
    <FeedStateContext.Provider value={state}>
      <FeedDispatchContext.Provider value={dispatch}>
        {children}
      </FeedDispatchContext.Provider>
    </FeedStateContext.Provider>
  );
};

const useFeedState = () => {
  const context = useContext(FeedStateContext);
  if (context === undefined) {
    throw new Error("useFeedState must be used within a FeedProvider");
  }
  return context;
};

const useFeedDispatch = () => {
  const context = useContext(FeedDispatchContext);
  if (context === undefined) {
    throw new Error("useFeedDispatch must be used within a FeedProvider");
  }
  return context;
};

const useFeed = () => {
  return [useFeedState(), useFeedDispatch()];
};
export { FeedProvider, useFeedDispatch, useFeed, useFeedState };
