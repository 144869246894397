import { useState } from "react";
import { Checkbox, Modal, Input, Button, message, Progress } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation, useApolloClient } from "@apollo/client";
import axios from "axios";

import { REST_VIDEO_API_ENDPOINT } from "config";
import { AddVideoTags } from "shared/components";
import { CREATE_MASTERLIST_TOPIC } from "shared/services";
import { bytesToSize, getLocalToken } from "shared/utils/util-funcs";

import styles from "./create-video-post.module.scss";
import { CREATE_VIDEO } from "./create-video-post.service";
import UploadVideo from "./UploadVideo";
import { useFeed } from "shared/contexts/feed-context";
import useStorage from "shared/hooks/useStorage";

const { TextArea } = Input;

export default function CreatePostModal({ isOpen, onClose }) {
  const [_, dispatch] = useFeed();
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
  });

  const handleChange = (prop) => (event) => {
    setFormValues({ ...formValues, [prop]: event.target.value.trim() });
  };

  const [allowAdvertisementUse, setAllowAdvertisementUse] = useState(true);
  const toggleChecked = () => {
    setAllowAdvertisementUse(!allowAdvertisementUse);
  };

  const [createVideo, { loading }] = useMutation(CREATE_VIDEO);
  const [isCreatingVideoPost, setIsCreatingVideoPost] = useState(false);
  const [tagTopicIds, setTagTopicIds] = useState([]);

  const client = useApolloClient();
  const [createMasterlistTopic] = useMutation(CREATE_MASTERLIST_TOPIC);

  const handleCreateMasterlistTopics = async (newTopics) => {
    const newTopicIds = [];
    for (const topic of newTopics) {
      const { data } = await createMasterlistTopic({
        variables: { topic: topic.value },
      });
      newTopicIds.push(data.create_masterlist_topic?.masterlist_topic.id);
    }
    return newTopicIds;
  };

  const handleTags = async () => {
    // Newly entered ones
    // Not UUIDS
    const newTopics = tagTopicIds.filter((i) => i.value.length !== 36);

    // find one without ids
    const existingTopicIds = tagTopicIds
      .filter((i) => i.value.length === 36)
      .map((i) => {
        return i.value;
      });

    // create new topics
    const newTopicIds = await handleCreateMasterlistTopics(newTopics);

    // merge existing and new topics to create interest
    const tagsToAdd = [...existingTopicIds, ...newTopicIds];
    return tagsToAdd;
  };

  const [fileList, setFileList] = useState([]);

  const storageInfo = useStorage(fileList[0]?.size || 0);

  const handleUploadVideo = async (createdVideoId) => {
    const formData = new FormData();

    formData.append("file", fileList[0].originFileObj);

    const authToken = getLocalToken("auth");
    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `${authToken}`,
      },
    };

    try {
      const res = await axios.post(
        `${REST_VIDEO_API_ENDPOINT}/videos/${createdVideoId}`,
        formData,
        config
      );
      return res;
    } catch (err) {
      return { msg: err.message, error: true };
    }
  };

  const handleCreateVideoPost = async () => {
    if (!formValues.title || !formValues.description) {
      message.error("values required");
      return;
    }
    setIsCreatingVideoPost(true);

    let tags = [];
    if (tagTopicIds.length > 0) {
      tags = await handleTags();
    }
    const res = await createVideo({
      variables: {
        ...formValues,
        allowAdvertisementUse,
        privacyPolicy: "public",
        tags: [...new Set(tags)],
      },
    });
    if (res.data.create_video.error) {
      message.error(res.data.create_video.error);
      onClose();
      return;
    }

    /* ---- HANDLE VIDEO UPLOAD WITH MICROSERVICE ---- */
    const videoRes = await handleUploadVideo(res.data.create_video.video.id);
    if (videoRes?.error) {
      message.error(`${t(videoRes.msg)}`);
      onClose();
    } else {
      message.success(
        `${t("file uploaded successfully video is being processed")}`
      );
      onClose();
      dispatch({
        type: "ADD_NEW_VIDEO",
        video: res.data.create_video.video,
      });
    }
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={(!loading || !isCreatingVideoPost) && onClose}
      footer={null}
      title={t("upload video")}
      className="custom-modal-header"
    >
      <TextArea
        showCount
        maxLength={100}
        rows={4}
        bordered={true}
        autoSize
        placeholder={t("title")}
        allowClear
        onChange={handleChange("title")}
      />
      <div style={{ marginBottom: "1rem", marginTop: "1rem" }}></div>
      <TextArea
        showCount
        maxLength={1000}
        rows={4}
        bordered={true}
        autoSize
        placeholder={t("description")}
        allowClear
        onChange={handleChange("description")}
      />
      <AddVideoTags setTagTopicIds={setTagTopicIds} tagTopicIds={tagTopicIds} />
      <UploadVideo fileList={fileList} setFileList={setFileList} />
      <FileSize file={fileList} info={storageInfo} />
      <Checkbox
        style={{ marginTop: ".5rem" }}
        checked={allowAdvertisementUse}
        onChange={toggleChecked}
      >
        {t("Allow usage for advertising purposes")}
      </Checkbox>
      <div className={styles.footer}>
        {fileList.length > 0 && (
          <Button
            type="primary"
            loading={loading || isCreatingVideoPost}
            disabled={
              loading || isCreatingVideoPost || storageInfo.usagePercent > 99
            }
            onClick={() => handleCreateVideoPost()}
          >
            {t("post")}
          </Button>
        )}
      </div>
    </Modal>
  );
}

function FileSize({ file, info }) {
  const { availableStorage, currUnit, usagePercent, totalUsage } = info;
  if (!file.length) return null;
  return (
    <div style={{ marginBottom: "1rem" }}>
      {/* <ul>
        <li>
          <span>File size: {bytesToSize(file[0].size, 2)}</span>
        </li>
        <li>
          <span>
            Left: {availableStorage - parseInt(bytesToSize(file[0].size, 2))}
          </span>
        </li>
      </ul> */}
      {availableStorage && (
        <div>
          <p>Available space after upload</p>
          <Progress
            percent={usagePercent}
            status={usagePercent > 99 ? "exception" : "normal"}
            size="small"
            showInfo={false}
          />
          <p className={styles.usageText}>
            {totalUsage}
            <span>{currUnit}</span> / {availableStorage}
            <span>{currUnit}</span>
          </p>
        </div>
      )}
    </div>
  );
}
