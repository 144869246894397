import { useMutation } from "@apollo/client";

import { Checkbox, message, Tooltip } from "antd";
import { UPDATE_PROFESSIONAL_PRIVACY_POLICY } from "pages/Profile/profile.service";
import { useTranslation } from "react-i18next";

export default function PrivacySetting({ info }) {
  const { t } = useTranslation();
  const [updatePrivacyPolicy, { loading }] = useMutation(
    UPDATE_PROFESSIONAL_PRIVACY_POLICY,
    {
      onCompleted(data) {
        if (data.update_professional_privacy_policy.error) {
          message.error(data.update_professional_privacy_policy.error);
        } else {
          message.success(
            `${t(
              `post privacy updated successfully ${data.update_professional_privacy_policy.professional_privacy_policy.datapoint}:`
            )} ${
              data.update_professional_privacy_policy
                .professional_privacy_policy.privacy_level
            }`
          );
        }
      },
    }
  );

  const updatePrivacy = (e) => {
    updatePrivacyPolicy({
      variables: {
        id: info.id,
        privacyLevel: e.target.checked ? "only_me" : "public",
      },
    });
  };
  if (info && info.id)
    return (
      <Tooltip placement="top" title={t("check to set privacy to private")}>
        <Checkbox
          onChange={updatePrivacy}
          defaultChecked={info.privacy_level === "only_me"}
          style={{ fontWeight: "normal", fontSize: "0.7rem" }}
        >
          {/* {t("Private")} */}
        </Checkbox>
      </Tooltip>
    );
  return null;
}
