import { useEffect } from "react";
import clsx from "clsx";
import { useQuery, useMutation } from "@apollo/client";
import { format } from "date-fns";

import "shared/styles/app.global.scss";
import { useUser } from "shared/contexts/user-context";

import styles from "./banner-ads.module.scss";
import { GET_SLOTS, CREATE_SLOT_TRACKING } from "./banner-ads.service";
import {
  getDeviceType,
  filterCurrentDateLoginSessions,
  currentDaySlotViews,
} from "./banner-ads.util";

export default function BannerAds() {
  const currentDate = format(new Date(), "yyyy-MM-dd");

  const { loading, data, refetch } = useQuery(GET_SLOTS, {
    variables: {
      limit: 100,
      status: "booked",
      date: currentDate,
    },
  });

  return (
    <>
      {/* Hide if no ads */}
      {!loading && data?.slots?.slots.length > 0 ? (
        <div
          className={clsx(
            "bannerAdsContainer b-rad-4",
            styles.bannersContainer
          )}
        >
          {data.slots.slots
            .slice()
            .sort(
              (a, b) => a.banner_position.position - b.banner_position.position // Sort by position, 1,2,3...
            )
            .map((slot) => (
              <Banner key={slot.id} slot={slot} refetch={refetch} />
            ))}
        </div>
      ) : null}
    </>
  );
}

function Banner({ slot, refetch }) {
  const { user } = useUser();
  const { sessions } = user;

  const [createSlotTracking] = useMutation(CREATE_SLOT_TRACKING);

  const handleSlotTracking = async (action) => {
    const deviceType = getDeviceType();
    await createSlotTracking({
      variables: { userId: user.id, slotId: slot.id, deviceType, action },
    });
    refetch();
  };

  // Client requirment: Log view, once a day (login in a day)
  useEffect(() => {
    const currentDayLogins = filterCurrentDateLoginSessions(sessions);
    const currentSlotViewsByUser = currentDaySlotViews(slot.trackings, user.id);

    // Only for first login of the day
    if (currentDayLogins.length === 1) {
      handleSlotTracking("view");
    }
  }, []);

  return (
    <a
      className={styles.banner}
      // rel={noreferrer}
      target="_blank"
      href={slot?.banner?.link}
      onClick={() => handleSlotTracking("click")}
    >
      <img src={slot?.banner?.image} />
    </a>
  );
}
