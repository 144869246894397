import { Cascader } from "antd";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SelectDropdownInput } from "..";
import PrivacySetting from "../PrivacySetting";

export default function CustomReactHooksSelect({
  name,
  control,
  privacyInfo,
  errors,
  label,
  cascadeData,
  disableCascade,
  options,
  type = "select",
  rules,
  defaultValue,
  valueSelector,
  style,
  keySelector,
}) {
  const { t } = useTranslation();

  function displayRender(label) {
    return label[label.length - 1];
  }
  function filter(inputValue, path) {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  }

  return (
    <Controller
      key={name}
      render={(props) => (
        <div className="select-form-input cascade-form-input" style={style}>
          <label htmlFor={name} className={clsx("formLabel")}>
            <div>
              {t(label)}

              {errors?.[name] && (
                <span className={"formErrortext"}>
                  {t(errors[name]?.message)}
                </span>
              )}
            </div>

            {privacyInfo && <PrivacySetting info={privacyInfo} />}
          </label>
          {type === "cascade" ? (
            <Cascader
              status={errors?.[name] && "error"}
              disabled={disableCascade}
              options={options}
              style={style}
              showSearch={{ filter }}
              placeholder=""
              expandTrigger="hover"
              displayRender={displayRender}
              {...props}
            />
          ) : (
            <SelectDropdownInput
              error={errors?.[name] ? true : false}
              defaultValue={defaultValue}
              props={props}
              optionsArr={options}
              optionKey={keySelector}
              optionValue={valueSelector}
            />
          )}
        </div>
      )}
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
    />
  );
}
