import { useState, useEffect } from "react";
import DatePickerR from "react-date-picker";
import "react-calendar/dist/Calendar.css";
import "react-date-picker/dist/DatePicker.css";
import { getLocalToken } from "shared/utils/util-funcs";
import { useTranslation } from "react-i18next";

function ReactDatePicker({
  register,
  dateValue,
  handleDateChange,
  defaultValue,
  error,
}) {
  const [value, onChange] = useState(null);
  const lang = getLocalToken("locale");
  const d = new Date();
  const maxDate = new Date(d.getFullYear() - 18, d.getMonth());

  useEffect(() => {
    if (defaultValue) {
      onChange(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div>
      <DatePickerR
        className={`${error && "red"}`}
        maxDate={maxDate}
        ref={register}
        onChange={(e) => {
          handleDateChange(e);
          onChange(e);
        }}
        value={value}
        format={"y-MM-dd"}
        locale={lang === "jp" ? "ja" : "en"}
      />
    </div>
  );
}

export default ReactDatePicker;
