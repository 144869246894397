import { Suspense, lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollMemory from "react-router-scroll-memory";
import { UserProvider } from "shared/contexts/user-context";
import { TopProgressBar, FullPageErrorFallback } from "shared/components";

/* ---- Lazy load base routes ---- */
const Terms = lazy(() => import("./pages/Terms"));
const AuthenticatedApp = lazy(() => import("./AuthenticatedApp"));
const PrivateRoute = lazy(() => import("./PrivateRoute"));
const Login = lazy(() => import("./pages/Login/Login"));
const Signup = lazy(() => import("./pages/Signup/Signup"));
const ForgotPassword = lazy(() =>
  import("./pages/ForgotPassword/ForgotPassword")
);
const ResetPasswordLink = lazy(() =>
  import("./pages/ResetPasswordLink/ResetPasswordLink")
);
const NotFound = lazy(() => import("./pages/NotFound/NotFound"));

export default function App() {
  // const setLang = useSetLanguage();

  // On local developement, english is default
  // if (["localhost", "127.0.0.1"].includes(window.location.hostname)) {
  //   setLang("en");
  // }

  return (
    <ErrorBoundary FallbackComponent={FullPageErrorFallback}>
      <Suspense fallback={<TopProgressBar />}>
        <UserProvider>
          <Router>
            <ScrollMemory />
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route
                exact
                path="/password-reset"
                component={ResetPasswordLink}
              />
              <Route exact path="/terms" component={Terms} />
              <PrivateRoute path="/" component={AuthenticatedApp} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Router>
        </UserProvider>
      </Suspense>
    </ErrorBoundary>
  );
}
