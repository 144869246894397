import { Select } from "antd";
import clsx from "clsx";
import { useEffect } from "react";
import styles from "./select-dropdown-input.module.scss";

const { Option } = Select;

export default function SelectDropdownInput({
  name,
  label,
  error = false,
  inlineStyles,
  register,
  optionsArr = [],
  optionKey = "",
  optionValue = "",
  props,
  defaultValue = "",
  controlOnchange = true,
  onChange = () => {},
}) {
  return (
    <Select
      status={error && "error"}
      defaultValue={defaultValue}
      style={{ ...inlineStyles }}
      {...(controlOnchange && { onChange: onChange })}
      {...props}
    >
      {optionsArr.map((option) => (
        <Option key={option?.id} value={option[optionValue]}>
          {option[optionKey]}
        </Option>
      ))}
    </Select>
  );
}
