import { useState, useEffect } from "react";
import { Avatar, Popover } from "antd";
import { MdPerson, MdMoreHoriz, MdContentCopy } from "react-icons/md";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { MentionsInput, Mention } from "react-mentions";

import { useUser } from "shared/contexts/user-context";
import { formatRelativeTimeFromNow } from "shared/utils/util";
import { GET_POST } from "shared/services";
import {
  FollowUser,
  PostLikes,
  PostCommentButton,
  PostComments,
  HashtagOutput,
  LoadingSkeleton,
} from "shared/components";

import { GET_COMMENTS } from "./post.service";
import styles from "./post.module.scss";
import PostPrivacy from "./PostPrivacy";
import PostMenu from "./PostMenu";

export default function Post({ post }) {
  console.log("🚀 ~ file: Post.js ~ line 27 ~ Post ~ post", post);
  const { t } = useTranslation();
  const { user } = useUser();

  const [postData, setPostData] = useState();

  const { loading, data, refetch } = useQuery(GET_POST, {
    variables: { postId: post.id },
    skip: !post.id,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!loading && data) {
      setPostData(data.post.post);
    }
  }, [loading, data]);

  const { created_at, professional_id } = post;

  // check if one's own post
  const ownPost = user.id === post.professional_id;

  /* ---- Commnents ---- */
  const {
    loading: commentsLoading,
    data: commentsData,
    refetch: refetchComments,
  } = useQuery(GET_COMMENTS, {
    variables: { postId: post.id },
    notifyOnNetworkStatusChange: true,
  });

  const [postMode, setPostMode] = useState();
  const handlePostMode = (status) => {
    setPopvisible(false);
    setPostMode(status);
  };

  // Programmitically control pop-over state
  const [popvisible, setPopvisible] = useState(false);
  const handleVisibleChange = (visible) => {
    setPopvisible(visible);
  };

  const [showComments, setShowComments] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.postTop}>
        {postData ? (
          <>
            <Link to={`/user/${professional_id}`}>
              <Avatar
                src={postData.professional.image}
                icon={
                  <MdPerson
                    style={{
                      marginTop: ".5rem",
                      height: "1.5rem",
                      width: "1.5rem",
                    }}
                  />
                }
                style={{
                  marginRight: ".5rem",
                  height: "2.5rem",
                  width: "2.5rem",
                }}
              />
            </Link>

            <div className={styles.postUser}>
              <Link to={`/user/${professional_id}`}>
                <p className={styles.username}>
                  {postData.professional.last_name}{" "}
                  {postData.professional.first_name}
                </p>
              </Link>
              <div className={styles.postSubHeader}>
                <Link to={`/post/${post.id}`}>
                  <p className={styles.postTime}>
                    <time datetime={created_at} title={new Date(created_at)}>
                      {formatRelativeTimeFromNow(created_at)}
                    </time>
                  </p>
                </Link>
                {ownPost && ["published"].includes(post.status) && (
                  <>
                    <p style={{ marginRight: "2px", marginLeft: "2px" }}>·</p>
                    <PostPrivacy
                      status={postData.privacy_policy}
                      postId={post.id}
                    />
                  </>
                )}
              </div>
            </div>

            {ownPost ? (
              <Popover
                placement="bottomRight"
                content={
                  <PostMenu
                    post={postData}
                    currentUser={user.id}
                    postUser={post.professional_id}
                    postMode={postMode}
                    handlePostMode={handlePostMode}
                  />
                }
                trigger="click"
                visible={popvisible}
                onVisibleChange={handleVisibleChange}
              >
                <MdMoreHoriz
                  style={{
                    fontSize: "1.5rem",
                    opacity: ".9",
                    cursor: "pointer",
                  }}
                />
              </Popover>
            ) : (
              <div style={{ display: "flex" }}>
                <FollowUser currentUserId={post.professional_id} />
                <Popover
                  placement="bottomRight"
                  content={
                    <PostMenu
                      post={postData}
                      currentUser={user.id}
                      postUser={post.professional_id}
                      postMode={postMode}
                      handlePostMode={handlePostMode}
                    />
                  }
                  trigger="click"
                  visible={popvisible}
                  onVisibleChange={handleVisibleChange}
                >
                  <MdMoreHoriz
                    style={{
                      fontSize: "1.5rem",
                      opacity: ".9",
                      cursor: "pointer",
                    }}
                  />
                </Popover>
              </div>
            )}
          </>
        ) : (
          <LoadingSkeleton props={{ circle: true, height: 50, width: 50 }} />
        )}
      </div>

      <p className={styles.postText}>
        {!postData?.content ? (
          <LoadingSkeleton props={{ count: 5 }} />
        ) : (
          <HashtagOutput content={postData?.content} />
        )}
      </p>
      {postData?.image && (
        <img src={postData.image} className={styles.postImage} />
      )}

      <hr className={styles.divider} />
      {!postData?.content || !commentsData ? (
        <LoadingSkeleton />
      ) : (
        <div className={styles.postFooter}>
          <div className={styles.postActions}>
            <PostLikes post={postData} refetchPost={refetch} />
            <PostCommentButton
              toggleShowComments={() => setShowComments(!showComments)}
              totalComments={commentsData?.comments}
            />
          </div>
        </div>
      )}
      {showComments && (
        <PostComments
          post={postData}
          refetchPost={refetch}
          toggleShowComments={() => setShowComments(!showComments)}
          totalComments={commentsData}
          refetchTotalComments={refetchComments}
        />
      )}
    </div>
  );
}
