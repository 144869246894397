import React from "react";
import { Result, Button } from "antd";
import { useTranslation } from "react-i18next";

export default function FullPageErrorFallback() {
  const { t } = useTranslation();

  return (
    <Result
      style={{ marginTop: "10vh" }}
      status="500"
      title={t("Sorry something went wrong")}
      extra={
        <a href="/">
          <Button type="primary">{t("Back Home")}</Button>
        </a>
      }
    />
  );
}
