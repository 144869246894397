import React, { useState } from "react";
import { CardElement, useStripe } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";

import "shared/styles/stripe-styles.scss";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#333",
      fontWeight: 500,
      fontFamily: "'Noto Sans JP', sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#87bbfd",
      },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

function CardField({ onChange }) {
  return (
    <div className="FormRow StripeFormRow">
      <CardElement options={CARD_OPTIONS} onChange={onChange} />
    </div>
  );
}

function Field({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) {
  const { t } = useTranslation();

  return (
    <div className="FormRow">
      <label htmlFor={id} className="FormRowLabel">
        {t(label)}
      </label>
      <input
        className="FormRowInput"
        id={id}
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

function SubmitButton({ processing, error, children, disabled }) {
  const { t } = useTranslation();

  return (
    <button
      className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
      type="submit"
      disabled={processing || disabled}
    >
      {processing ? `${t("Processing")}...` : children}
    </button>
  );
}

function ErrorMessage({ children }) {
  return (
    <div className="ErrorMessage" role="alert">
      <svg width="16" height="16" viewBox="0 0 17 17">
        <path
          fill="#FFF"
          d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
        />
        <path
          fill="#6772e5"
          d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
        />
      </svg>
      {children}
    </div>
  );
}

export default function StripeCardForm({
  elements,
  saveCard,
  billingDetails,
  setBillingDetails,
  paymentStatus,
  onExecFunc,
  enterBillingDetails = false,
  amountToPay = 0,
  error,
  setError,
}) {
  const { t } = useTranslation();

  const stripe = useStripe();
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    onExecFunc();
  };

  return (
    <div className="AppWrapper">
      <form className="Form" onSubmit={handleSubmit}>
        {enterBillingDetails && (
          <>
            {" "}
            <fieldset className="FormGroup">
              <Field
                label={t("Name")}
                id="name"
                type="text"
                placeholder={t("Your name")}
                required
                autoComplete="name"
                value={billingDetails.name}
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    name: e.target.value,
                  });
                }}
              />
              <Field
                label={t("Email")}
                id="email"
                type="email"
                placeholder={t("Your email")}
                required
                autoComplete="email"
                value={billingDetails.email}
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    email: e.target.value,
                  });
                }}
              />
              <Field
                label={t("Phone")}
                id="phone"
                type="tel"
                placeholder={t("Your phone number")}
                required
                autoComplete="tel"
                value={billingDetails.phone}
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    phone: e.target.value,
                  });
                }}
              />
            </fieldset>
          </>
        )}
        <fieldset className="FormGroup CardField">
          <CardField
            onChange={(e) => {
              setError(e.error);
              setCardComplete(e.complete);
            }}
          />
        </fieldset>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        <SubmitButton
          processing={processing}
          error={error}
          disabled={!stripe || error}
        >
          {saveCard
            ? `${t("Save card to account")}`
            : `${t("pay")} ¥${amountToPay}`}
        </SubmitButton>
      </form>
    </div>
  );
}
