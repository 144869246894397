import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { useUser } from "shared/contexts/user-context";
import { AuthNavbar, ConfirmEmail } from "shared/components";

import OnboardForm from "./OnboardForm";
import UploadProfDocs from "./UploadProfDocs";
import OnboardingStatus from "./OnboardingStatus";
import ReuploadDocs from "./ReuploadDocs";

export default function Onboarding({ refetchUser }) {
  // CONFIRM EMAIL -> ONBOARD DETAILS -> UPLOAD DOCS -> AWAIT APPROVAL -> READY TO USE
  const { user } = useUser();
  const { docs } = user;

  // If user is approved and mistakenly viist this route, redirect to home

  const [currentMode, setCurrentMode] = useState("ADD_DETAILS");

  // if (process.env.NODE_ENV === "development") {
  //   return (
  //     <>
  //       <AuthNavbar />
  //       <OnboardForm
  //         handleShowUploadDocs={() => setCurrentMode("UPLOAD_DOCS")}
  //       />
  //       {/* <UploadProfDocs refetchUser={refetchUser} />; */}
  //     </>
  //   );
  // }

  if (["approved"].includes(user.status)) {
    return <Redirect to="/" />;
  }

  let comp;

  if (!user.email_confirmed) {
    comp = <ConfirmEmail />;
  } else if (docs.length > 0 && docs.some((doc) => doc.status === "rejected")) {
    // Any of the uploaded doc is rejected, they've to reupload
    comp = <ReuploadDocs refetchUser={refetchUser} />;
  } else if (
    ["processing", "awaiting_approval", "rejected", "processing"].includes(
      user.status
    ) &&
    user.phone_no &&
    (docs.length >= 2 || (docs.length >= 1 && user.licence_no))
  ) {
    // Details added, doc uploaded or licence details added
    comp = <OnboardingStatus />;
  } else if (
    currentMode === "ADD_DETAILS" &&
    docs.length === 0 &&
    ["awaiting_onboard"].includes(user.status)
  ) {
    // No docs uploaded, awaiting onboard
    comp = (
      <OnboardForm handleShowUploadDocs={() => setCurrentMode("UPLOAD_DOCS")} />
    );
  } else if (
    currentMode === "UPLOAD_DOCS" ||
    docs.length > 0 ||
    (["awaiting_approval", "rejected"].includes(user.status) && user.phone_no)
  ) {
    // If user hasn't uploaded atleast 2 docs (photo id & prof id)
    // 1. No docs uploaded
    // 2. 1 doc uploaded (photo ID) && no licence
    // 3. 2 Doc uploaded

    comp = <UploadProfDocs refetchUser={refetchUser} />;
  }

  return (
    <>
      <AuthNavbar />
      {comp}
    </>
  );
}
