import { useState, useEffect } from "react";
import DatePickerR from "react-date-picker";
import "react-calendar/dist/Calendar.css";
import "react-date-picker/dist/DatePicker.css";
import { getLocalToken } from "shared/utils/util-funcs";
import { useTranslation } from "react-i18next";
import { DatePicker } from "antd";
import jp from "antd/es/date-picker/locale/ja_JP";
import en from "antd/es/date-picker/locale/en_US";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import PrivacySetting from "../PrivacySetting";

// import moment from "moment";
// import "moment/locale/ja";

function AntDatePicker({
  control,
  rules,
  required,
  privacyInfo,
  title,
  error,
  minYear = 0,
  style,
  ...childProps
}) {
  const { name } = childProps;
  const { t } = useTranslation();

  const [focus, setFocus] = useState(false);

  const lang = getLocalToken("locale");
  const d = new Date();
  const maxDate = new Date(d.getFullYear() - minYear, d.getMonth());

  const disabledDate = (current) => {
    // Can not select days before max date
    return current && current > maxDate;
  };

  // useEffect(() => {
  //   if (defaultValue) {
  //     onChange(defaultValue);
  //   }
  // }, [defaultValue]);

  return (
    <div className="select-form-input" style={style}>
      <label htmlFor="dob" className={clsx("formLabel")}>
        <div>{t(title)}</div>

        <PrivacySetting info={privacyInfo} />
      </label>
      <Controller
        as={
          <DatePicker
            status={error && "error"}
            format="YYYY年MM月DD日"
            disabledDate={disabledDate}
            // placeholder={props.placeholder || ""}
            onBlur={() => {
              setFocus(false);
            }}
            onFocus={() => {
              setFocus(true);
            }}
            name={name}
          />
        }
        name={name}
        control={control}
        rules={rules}
        onChange={([selected]) => ({ value: selected })}
        locale={lang === "jp" ? jp : en}
      />
    </div>
    // <div>
    //   <DatePicker
    //     className={`${error && "red"}`}
    //     // maxDate={maxDate}
    //     value={value}
    //     disabledDate={disabledDate}
    //     format="YYYY年MM月DD日"
    //     ref={register}
    //     locale={lang === "jp" ? jp : en}
    //   />
    // </div>
  );
}

export default AntDatePicker;
