import { useTranslation } from "react-i18next";
import { MdReport, MdModeEdit } from "react-icons/md";

import { UpdateVideoPost, ReportPost } from "shared/components";

import styles from "./video-post.module.scss";

export default function VideoPostMenu({
  postUser,
  currentUser,
  video,
  postMode,
  handlePostMode,
}) {
  const { t } = useTranslation();

  return (
    <>
      {postMode === "REPORT" && (
        <ReportPost
          isOpen={postMode === "REPORT"}
          onClose={() => handlePostMode(null)}
          post={video}
          type="video"
        />
      )}

      {postMode === "EDIT" && (
        <UpdateVideoPost
          isOpen={postMode === "EDIT"}
          onClose={() => handlePostMode(null)}
          video={video}
        />
      )}
      <div>
        {postUser !== currentUser && (
          <div
            className={styles.postMenuItem}
            onClick={() => handlePostMode("REPORT")}
          >
            <MdReport style={{ fontSize: "1rem" }} />
            <p>{t("report")}</p>
          </div>
        )}
        {postUser === currentUser && (
          <div
            className={styles.postMenuItem}
            onClick={() => handlePostMode("EDIT")}
          >
            <MdModeEdit style={{ fontSize: "1rem" }} />
            <p>{t("edit")}</p>
          </div>
        )}
      </div>
    </>
  );
}
