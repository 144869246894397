import { MdContentCopy, MdModeEdit, MdReport } from "react-icons/md";
import { useTranslation } from "react-i18next";

import { UpdatePost, ReportPost } from "shared/components";

import styles from "./post.module.scss";
import { message } from "antd";

export default function PostMenu({
  postUser,
  currentUser,
  post,
  postMode,
  handlePostMode,
}) {
  const { t } = useTranslation();
  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(post.id);
    message.success("Copied to clipboard");
  };
  return (
    <>
      {postMode === "REPORT" && (
        <ReportPost
          isOpen={postMode === "REPORT"}
          onClose={() => handlePostMode(null)}
          post={post}
          type="post"
        />
      )}
      {postMode === "EDIT" && (
        <UpdatePost
          isOpen={postMode === "EDIT"}
          onClose={() => handlePostMode(null)}
          post={post}
        />
      )}
      <div>
        {/* Can only report from other's post */}
        {postUser !== currentUser && (
          <div
            className={styles.postMenuItem}
            onClick={() => handlePostMode("REPORT")}
          >
            <MdReport style={{ fontSize: "1rem" }} />
            <p>{t("report")}</p>
          </div>
        )}

        {/* Can only edit own post */}
        {postUser === currentUser && (
          <div
            className={styles.postMenuItem}
            onClick={() => handlePostMode("EDIT")}
          >
            <MdModeEdit style={{ fontSize: "1rem" }} />
            <p>{t("edit")}</p>
          </div>
        )}
        <div className={styles.postMenuItem} onClick={copyToClipboard}>
          <MdContentCopy
            style={{
              fontSize: "1.2rem",
            }}
          />
          <p> {t("Copy id")}</p>
        </div>
      </div>
    </>
  );
}
