import { gql } from "@apollo/client";

export const CREATE_POST = gql`
  mutation CreatePost(
    $content: String!
    $privacyPolicy: PostPrivacyPolicy!
    $tags: [UUID]
  ) {
    create_post(
      details: { content: $content, privacy_policy: $privacyPolicy }
      tags: $tags
    ) {
      error
      post {
        id
        content
        status
        image
        image_uploaded
        professional_id
        professional {
          id
          first_name
          last_name
          image
        }
        created_at
        updated_at
        privacy_policy
        tags {
          id
          masterlist_topic_id
          masterlist_topic {
            id
            topic
          }
        }
        status
        status_description
      }
    }
  }
`;
