import clsx from "clsx";
import { Link, useRouteMatch, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "shared/styles/app.global.scss";

import styles from "./side-navbar.module.scss";
import SideBarChat from "./SideBarChat";

function SidebarCard({ title, dataArr = [], isActive }) {
  const { t } = useTranslation();
  const isVideo = ["videos", "posts"].includes(title);

  return (
    <div
      className={clsx(
        styles.topicContainer,
        "b-rad-4",
        isActive && styles.active
      )}
    >
      <h2 className={clsx(styles.topicTitle)}>{t(title)}</h2>
      {dataArr.map((d) => d)}
    </div>
  );
}

export default function SideNavbar({
  containerClass,
  videoPaneText = "posts",
}) {
  const { pathname } = useLocation();
  console.log(pathname);

  const videoPaneLink = videoPaneText === "videos" ? "videos" : "";
  return (
    <div className="sideNavContainer">
      <h4 className={styles.title}></h4>
      {/* <Link to={`/${videoPaneLink}`}>
        <SidebarCard title={videoPaneText} />
      </Link> */}
      <Link to={{ pathname: `/me`, query: { mypage: true } }}>
        <SidebarCard title="mypage" isActive={pathname === "/me"} />
      </Link>

      <Link to={`/`}>
        <SidebarCard title="posts" isActive={pathname === "/"} />
      </Link>
      <Link to={`/videos`}>
        <SidebarCard title="videos" isActive={pathname.includes("/video")} />
      </Link>
      {/* {["/me", "/chats", "/faq"].includes(path) && (
        <Link to={`/videos`}>
          <SidebarCard title="videos" />
        </Link>
      )} */}

      <Link to={`/chats`}>
        <SidebarCard title="Chats" isActive={pathname.includes("/chat")} />
      </Link>

      <Link to="/faq">
        <SidebarCard title="faq" isActive={pathname.includes("/faq")} />
      </Link>
    </div>
  );
}
