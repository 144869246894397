import { message, Upload } from "antd";
import { MdCloudUpload } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { convertBytesToMb } from "shared/utils/util-funcs";

const fileTypes = ["video/mp4", "video/quicktime"];

export default function UploadVideo({ fileList, setFileList }) {
  const { t } = useTranslation();

  const onChange = (info) => {
    if (fileTypes.includes(info.file.type)) {
      setFileList(
        info.fileList.filter((file) => fileTypes.includes(file.type))
      );
    } else {
      return [];
    }
  };

  return (
    <div style={{ marginBottom: "1rem" }}>
      <div className="prof-doc-upload">
        <Upload
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          showUploadList={{
            showPreviewIcon: false,
            showRemoveIcon: true,
            showDownloadIcon: false,
          }}
          beforeUpload={(file) => {
            if (!fileTypes.includes(file.type)) {
              message.error(`${t("Allowed file extensions")}: .mp4,.mov`);
              setFileList([]);
            } else {
              setFileList([file]); // Handle file upload call manually
              return false;
            }
          }}
        >
          {fileList.length < 1 && <UploadButton />}
        </Upload>
      </div>
    </div>
  );
}

function UploadButton() {
  const { t } = useTranslation();

  return (
    <div>
      <MdCloudUpload />
      <div style={{ marginTop: 8 }}>{t("Select video file")}</div>
    </div>
  );
}
