import { useQuery } from "@apollo/client";
import { Tag, Divider, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { MdModeEdit } from "react-icons/md";

import { useUser } from "shared/contexts/user-context";
import { GET_PROFESSIONAL_INTERESTS } from "shared/services";
import { LoadingSkeleton } from "shared/components";

import styles from "./interests.module.scss";

export default function ViewInterests({
  currentUserId,
  ownProfile,
  changeToEdit,
}) {
  const { t } = useTranslation();
  const { user } = useUser();

  const { loading, data } = useQuery(GET_PROFESSIONAL_INTERESTS, {
    variables: { professionalId: currentUserId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  if (loading) {
    return (
      <LoadingSkeleton
        props={{ count: 1, height: 40, style: { marginTop: "2rem" } }}
      />
    );
  }

  return (
    <div style={{ marginBottom: "2.5rem" }}>
      <Divider orientation="left">
        {t("Interests")}{" "}
        {ownProfile && (
          <Tooltip placement="top" title={t("add or edit interests")}>
            <button
              className={styles.labelActionBtn}
              onClick={() => changeToEdit()}
            >
              <MdModeEdit className={styles.labelActionIcon} />
            </button>
          </Tooltip>
        )}
      </Divider>
      {!loading &&
        data?.professional_interests.professional_interests.map((int) => (
          <Tag color="#180f77" key={int.id}>
            {int.masterlist_topic.topic}
          </Tag>
        ))}
    </div>
  );
}
