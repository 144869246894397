import { gql } from "@apollo/client";

export const GET_PROFESSIONAL_PRIVACY_POLICIES = gql`
  query GetProfessionalPrivacyPolicies($userId: UUID!, $limit: Int) {
    professional_privacy_policies(professional_id: $userId, limit: $limit) {
      error
      professional_privacy_policies {
        id
        privacy_level
        datapoint
      }
    }
  }
`;

export const UPDATE_PROFESSIONAL_PRIVACY_POLICY = gql`
  mutation UpdateProfessionalPrivacyPolicy(
    $id: UUID!
    $privacyLevel: ProfessionalPrivacyPolicyLevel!
  ) {
    update_professional_privacy_policy(
      id: $id
      details: { privacy_level: $privacyLevel }
    ) {
      error
      professional_privacy_policy {
        id
        privacy_level
        datapoint
      }
    }
  }
`;
