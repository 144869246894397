import clsx from "clsx";

import styles from "./form-input.module.scss";
import { Checkbox, message } from "antd";
import { UPDATE_PROFESSIONAL_PRIVACY_POLICY } from "pages/Profile/profile.service";
import { useMutation } from "@apollo/client";
import PrivacySetting from "../PrivacySetting";
import { useTranslation } from "react-i18next";

export default function FormInput({
  label,
  name,
  register,
  value,
  onChange = () => {},
  customClasses,
  errors,
  tabIndex,
  type = "text",
  style,
  width,
  privacyInfo,
  required,
}) {
  const { t } = useTranslation();

  return (
    <div
      style={style}
      className={clsx(
        styles.formField,
        width === "half" && styles.halfWidth,
        width === "third" && styles.thirdWidth
      )}
    >
      {label && (
        <label htmlFor={name} className={clsx(styles.formLabel)}>
          <div>
            {t(label)}
            {errors?.[name] && (
              <span className={styles.formErrortext}>
                {t(errors[name]?.message)}
              </span>
            )}
          </div>

          {privacyInfo && <PrivacySetting info={privacyInfo} />}
        </label>
      )}

      <input
        tabIndex={tabIndex}
        id={name}
        name={name}
        type={type}
        ref={register}
        value={value}
        onChange={onChange(name)}
        className={clsx({
          [styles.formFieldError]: errors?.[name],
        })}
      />
    </div>
  );
}
