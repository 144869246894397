import { useState, useEffect, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  useQuery,
  useMutation,
  useLazyQuery,
  useApolloClient,
} from "@apollo/client";
import { Select, message, DatePicker, Checkbox } from "antd";
import clsx from "clsx";
import moment from "moment";

import {
  SelectDropdownInput,
  FormInput,
  AuthForm,
  ReenterPasswordModal,
  ReactDatePicker,
} from "shared/components";
import {
  loopOverCascade,
  getProfFromSpecialisation,
  filterMatchedProfFromSpecialisation,
} from "shared/utils/util";

import {
  GET_MASTERLIST_SPECIALISATIONS,
  GET_MASTERLIST_COUNTRIES,
  GET_MASTERLIST_STATES,
  UPDATE_USER,
} from "shared/services";
import { useUser } from "shared/contexts/user-context";

import styles from "./onboarding.module.scss";
import { countryCode } from "./countryCode";

import { GET_PROFESSIONAL_PRIVACY_POLICIES } from "pages/Profile/profile.service";
import PrivacySetting from "../PrivacySetting";
import CustomReactHooksSelect from "../CustomSelect";
import { getLocalToken } from "shared/utils/util-funcs";
import PasswordInput from "../PasswordInput/PasswordInput";
import { useLoginHook } from "shared/hooks/useLoginHook";
import { useTranslation } from "react-i18next";
import AntDatePicker from "../AntDatePicker/AntDatePicker";
import { Link } from "react-router-dom";

const { Option, OptGroup } = Select;

export default function OnboardForm({ handleShowUploadDocs }) {
  const client = useApolloClient();
  const { t } = useTranslation();
  const { user } = useUser();
  const localLang = getLocalToken("locale");

  const { loading: specialiseLoading, data: specialiseData } = useQuery(
    GET_MASTERLIST_SPECIALISATIONS,
    { variables: { limit: 100 } }
  );
  const { loading: countryLoading, data: countryData } = useQuery(
    GET_MASTERLIST_COUNTRIES,
    { variables: { limit: 300 } }
  );
  const { loading: stateLoading, data: stateData } = useQuery(
    GET_MASTERLIST_STATES,
    { variables: { limit: 100 } }
  );

  useEffect(() => {
    setValue("firstName", user.first_name);
    setValue("lastName", user.last_name);
    setValue("middleName", user.middle_name);
    setValue("gender", user.gender);
    setValue("phoneNo", user.phone_no);
    setValue("designation", user.designation);
    setValue("organization", user.organization);
    setValue("profession", user.profession);
    setValue("residencyCity", user.residency_city);
    setValue("residencyAddress", user.residency_address);
    setValue("residencyState", user.residency_state);

    setValue("officeCity", user.office_city);
    setValue("officeState", user.office_state);
    setValue("officeAddress", user.office_address);
    setValue("dob", user.DOB ? moment(user.DOB).toDate() : null);

    // setValue("dob", new Date());
    // console.log(DOB);
  }, [user.id]);

  /* ---- Handle Update ---- */
  const [updateUser] = useMutation(UPDATE_USER);

  /* ------- Handle form state ------- */

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    watch,
    getValues,
  } = useForm();
  console.log(
    "🚀 ~ file: OnboardForm.js ~ line 104 ~ OnboardForm ~ errors",
    errors
  );

  const [officeState, setOfficeState] = useState([]);
  const [residencyState, setResidencyState] = useState([]);

  const watchOfficeCountry = watch("officeCountry");
  const watchResidencyCountry = watch("residencyCountry");
  const watchdob = watch("dob");

  useEffect(() => {
    console.log(watchdob);
  }, [watchdob]);

  const getCountryState = async (country) => {
    try {
      const countryId =
        countryData.masterlist_countries.masterlist_countries.find(
          (item) => item.country === country
        );
      const d = await client.query({
        query: GET_MASTERLIST_STATES,
        variables: { countryId: countryId?.id, limit: 100 },
      });

      return d.data.masterlist_states.masterlist_states;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(async () => {
    if (watchOfficeCountry && !countryLoading) {
      setValue("officeState", "");
      const d = await getCountryState(watchOfficeCountry);
      setOfficeState(d);
    } else {
      setOfficeState([]);
    }
  }, [watchOfficeCountry, countryLoading]);

  useEffect(async () => {
    if (watchResidencyCountry && !countryLoading) {
      setValue("residencyState", "");
      const d = await getCountryState(watchResidencyCountry);
      setResidencyState(d);
    } else {
      setResidencyState([]);
    }
  }, [watchResidencyCountry, countryLoading]);

  const selectedProfession = watch("profession");

  const [professionData, setProfessionData] = useState([]);
  const [specialisationData, setSpecialisationData] = useState([]);
  const [cascadeData, setCascadeData] = useState([]);

  useEffect(() => {
    if (!specialiseLoading && specialiseData) {
      setProfessionData(
        getProfFromSpecialisation(
          specialiseData?.masterlist_specialisations?.masterlist_specialisations
        )
      );
      setSpecialisationData(
        specialiseData?.masterlist_specialisations?.masterlist_specialisations
      );
    }
  }, [specialiseLoading, specialiseData]);

  useEffect(() => {
    if (professionData && specialisationData && selectedProfession) {
      const filteredSpecs = filterMatchedProfFromSpecialisation(
        selectedProfession,
        specialisationData
      );
      console.log("filtered", filteredSpecs);
      setCascadeData(loopOverCascade(filteredSpecs));
    }
  }, [selectedProfession]);

  const { initiateLogin, loading } = useLoginHook({ email: user.email });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    // setFormValues({
    //   ...data,
    //   // profession: selectedProfession,
    //   ...(data.specialisation && {
    //     specialisation: filterSpecialisation(data.specialisation),
    //   }),
    //   // ...{ phoneCc },
    // });
    // setShowPasswordModal(true);

    // format date string

    const res = await initiateLogin({
      email: user.email,
      password: data.password,
    });
    if (res.success) {
      updateUser({
        variables: {
          ...data,
          id: user.id,
          dob: moment(data.dob).format("YYYY-MM-DD"),
          profession: selectedProfession,
          ...(data.specialisation && {
            specialisation: filterSpecialisation(data.specialisation),
          }),
        },
      })
        .then((r) => {
          if (r?.data?.update_professional?.error) {
            // setShowPasswordModal(false);
            message.error(r.data.update_professional.error);
          } else if (r?.data?.update_professional?.professional.id) {
            // setShowPasswordModal(false);
            handleShowUploadDocs();
          }
        })
        .catch((err) => console.log(err));
    }
    setIsSubmitting(false);
  };

  /* ------- Handle form state ------- */

  const [formStatus, setformStatus] = useState({
    status: null,
    statusMsg: "",
  });
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  // let loading = false;
  const [privacyData, setPrivacyData] = useState({});

  // After user is available, then call privacy policy
  useEffect(() => {
    const getPrivacyPolicy = async () => {
      const ob = {};
      try {
        const privacyData = await client.query({
          query: GET_PROFESSIONAL_PRIVACY_POLICIES,
          variables: { userId: user.id, limit: 100 },
        });
        privacyData.data.professional_privacy_policies.professional_privacy_policies.forEach(
          (item) => {
            ob[item.datapoint] = {
              id: item.id,
              privacy_level: item.privacy_level,
            };
          }
        );
        setPrivacyData(ob);
      } catch (err) {
        console.log(err);
      }
    };
    if (user.id) {
      getPrivacyPolicy();
    }
  }, [user.id]);

  // Cascaded specialisation,returns the path of selected option
  // Take the last item in the selected value as the value
  const filterSpecialisation = (selectedPath) => {
    return selectedPath[selectedPath.length - 1];
  };

  const handleDateChange = (date) => {
    if (date) {
      setValue("dob", moment(date).format("YYYY-MM-DD"));
    } else {
      setValue("dob", date);
    }
  };

  // ------------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* {showPasswordModal && (
        <ReenterPasswordModal
          isOpen={showPasswordModal}
          onCancel={() => setShowPasswordModal(false)}
          onSuccessFunc={handleOnboarding}
        />
      )} */}
      <div>
        <AuthForm
          onSubmit={handleSubmit(onSubmit)}
          formStatus={formStatus}
          title="add profile details"
          isDisabled={isSubmitting}
          isProcessing={isSubmitting}
          inlineStyles={{ width: "48rem" }}
        >
          <div className={clsx("formFieldSideContainer")}>
            <FormInput
              label="last name"
              name="lastName"
              register={register({
                required: { value: true },
              })}
              errors={errors}
              width="third"
              tabIndex={5}
            />
            <FormInput
              label="first name"
              name="firstName"
              register={register({
                required: { value: true },
              })}
              errors={errors}
              width="third"
              tabIndex={5}
            />
            <FormInput
              label="middle name"
              name="middleName"
              register={register({
                required: { value: true },
              })}
              errors={errors}
              width="third"
              tabIndex={5}
            />
          </div>

          <div className={clsx("formFieldSideContainer")}>
            <CustomReactHooksSelect
              control={control}
              name="gender"
              rules={{ required: { value: true } }}
              label="gender"
              style={{ width: "48%" }}
              options={[
                { gender: "male", label: `${t("Male")}` },
                { gender: "female", label: `${t("Female")}` },
                { gender: "other", label: `${t("Other")}` },
              ]}
              valueSelector="gender"
              keySelector="label"
              errors={errors}
              privacyInfo={privacyData.gender}
            />
            <AntDatePicker
              name="dob"
              title="date of birth"
              error={errors.dob}
              rules={{ required: { value: true } }}
              control={control}
              privacyInfo={privacyData.DOB}
              minYear={18}
              style={{ width: "47%", marginBottom: "1rem" }}
            />
            {/* <Controller
              render={(props) => (
                <div
                  className="select-form-input"
                  style={{ width: "48%", marginBottom: "1rem" }}
                >
                  <label htmlFor="dob" className={clsx("formLabel")}>
                    <div>
                      {t("date of birth")}
                      {errors?.dob && (
                        <span className={"formErrortext"}>
                          {t(errors["dob"]?.message)}
                        </span>
                      )}
                    </div>

                    <PrivacySetting info={privacyData.DOB} />
                  </label>

                  <ReactDatePicker
                    error={errors?.dob && true}
                    defaultValue={user.DOB && moment(user.DOB).toDate()}
                    handleDateChange={handleDateChange}
                  />
                </div>
              )}
              key="dob"
              control={control}
              name="dob"
              defaultValue={null}
              rules={{ required: { value: true } }}
            /> */}
          </div>

          <div>
            <div className="flex justify-between">
              <CustomReactHooksSelect
                control={control}
                label="profession"
                style={{ width: "30%" }}
                valueSelector="value"
                keySelector="label"
                privacyInfo={privacyData.profession}
                errors={errors}
                options={professionData}
                name="profession"
                rules={{ required: { value: true } }}
              />

              <CustomReactHooksSelect
                name="specialisation"
                label="specialisation"
                type="cascade"
                style={{ width: "160px" }}
                control={control}
                disableCascade={!selectedProfession}
                options={cascadeData}
                errors={errors}
                privacyInfo={privacyData.specialisation}
                rules={{ required: { value: true } }}
              />

              <FormInput
                label="designation"
                name="designation"
                style={{ width: "30%" }}
                register={register({
                  required: { value: true },
                })}
                errors={errors}
                tabIndex={5}
                width="third"
                privacyInfo={privacyData.designation}
              />
            </div>
          </div>

          <div>
            <div className={clsx("formFieldSideContainer")}>
              <FormInput
                label="organization"
                width="half"
                name="organization"
                register={register({
                  required: { value: true },
                })}
                errors={errors}
                tabIndex={4}
              />
              <CustomReactHooksSelect
                control={control}
                options={
                  countryData?.masterlist_countries?.masterlist_countries || []
                }
                style={{ width: "48%" }}
                errors={errors}
                valueSelector="country"
                keySelector="country"
                label="office country"
                name="officeCountry"
                rules={{ required: { value: true } }}
                defaultValue="日本"
                privacyInfo={privacyData?.office_country}
              />
            </div>
            <div style={{ gap: "1.5rem" }} className="flex justify-between">
              {officeState.length > 0 ? (
                <CustomReactHooksSelect
                  label="office state"
                  style={{ width: "48%" }}
                  options={officeState}
                  control={control}
                  errors={errors}
                  privacyInfo={privacyData.office_state}
                  name="officeState"
                  keySelector="state"
                  valueSelector="state"
                  rules={{ required: { value: true } }}
                />
              ) : (
                <FormInput
                  label="office state"
                  name="officeState"
                  register={register({
                    required: { value: true },
                  })}
                  errors={errors}
                  tabIndex={5}
                  privacyInfo={privacyData.office_state}
                  width="half"
                />
              )}
              <FormInput
                label="office city"
                required
                name="officeCity"
                register={register({
                  required: { value: true },
                })}
                errors={errors}
                tabIndex={5}
                privacyInfo={privacyData.office_city}
                width="half"
              />
            </div>
            <div>
              <FormInput
                label="office address"
                name="officeAddress"
                required
                register={register({
                  required: { value: true },
                })}
                errors={errors}
                privacyInfo={privacyData.office_address}
                tabIndex={5}
              />
            </div>

            <div
              // style={{ marginBottom: "1.5rem" }}
              className={clsx("formFieldSideContainer")}
            >
              <Controller
                render={(props) => (
                  <div className="select-form-input" style={{ width: "30%" }}>
                    <label htmlFor="state" className={clsx("formLabel")}>
                      <div>
                        {t("residency country")}
                        {errors?.residencyCountry && (
                          <span className={"formErrortext"}>
                            {t(errors["residencyCountry"]?.message)}
                          </span>
                        )}
                      </div>
                      <PrivacySetting info={privacyData.residency_country} />
                    </label>
                    <Select
                      showSearch
                      status={errors?.residencyCountry && "error"}
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        console.log(option);
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      {...props}
                    >
                      {countryData?.masterlist_countries?.masterlist_countries?.map(
                        (d) => (
                          <Option key={d.country} value={d.country}>
                            {d.country}
                          </Option>
                        )
                      )}
                    </Select>
                  </div>
                )}
                control={control}
                name="residencyCountry"
                rules={{ required: { value: true } }}
                defaultValue="日本"
              />
              {residencyState.length > 0 ? (
                <CustomReactHooksSelect
                  control={control}
                  style={{ width: "30%" }}
                  label="residency state"
                  errors={errors}
                  name="residencyState"
                  privacyInfo={privacyData.residency_state}
                  options={residencyState}
                  rules={{ required: { value: true } }}
                  keySelector="state"
                  valueSelector="state"
                />
              ) : (
                <FormInput
                  label="residency state"
                  name="residencyState"
                  register={register({
                    required: { value: true },
                  })}
                  errors={errors}
                  tabIndex={6}
                  privacyInfo={privacyData.residency_state}
                  width="third"
                />
              )}

              <FormInput
                label="residency city"
                name="residencyCity"
                required
                register={register({
                  required: { value: true },
                })}
                errors={errors}
                tabIndex={6}
                privacyInfo={privacyData.residency_city}
                width="third"
              />
            </div>
            <div
              style={{ gap: "1.5rem", marginBottom: "0.5rem" }}
              className="flex justify-between"
            >
              <FormInput
                label="residency address"
                style={{ width: "65%" }}
                required
                name="residencyAddress"
                register={register({
                  required: { value: true },
                })}
                errors={errors}
                privacyInfo={privacyData.residency_address}
                tabIndex={5}
              />
              <FormInput
                label="phone"
                width="third"
                required
                name="phoneNo"
                tabIndex={1}
                register={register({
                  required: { value: true },
                })}
                privacyInfo={privacyData.phone_no}
                errors={errors}
              />
            </div>
            <hr style={{ margin: "1rem auto", borderColor: "#ffffff73" }} />
            <div>
              <PasswordInput
                register={register({
                  required: { value: true },
                })}
                errors={errors}
                label="enter your password"
                name="password"
                tabIndex={2}
              />
            </div>
            {/*
            <div style={{ margin: "2rem 0" }}>
              <Link
                target="_blank"
                style={{
                  display: "block",
                  color: "blue",
                  marginBottom: "1rem",
                }}
                to="/terms"
              >
                Read terms & conditions
              </Link>

              <Checkbox
                name="terms"
                checked={termsCheck}
                onChange={(e) => setTermsCheck(e.target.checked)}
              >
                Accept Terms & conditions
              </Checkbox>
            </div> */}
          </div>
        </AuthForm>
      </div>
    </>
  );
}
