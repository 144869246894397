import { Popover, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import JapanFlag from "assets/images/jp-locale.svg";
import UsaFlag from "assets/images/us-locale.svg";
import { getLocalToken, setLocalToken } from "shared/utils/util-funcs";

import styles from "./locale-selector.module.scss";

const textHelper = { en: "English (US)", jp: "日本語" };

export default function LocaleSelector({ textOnly, style }) {
  const { t, i18n } = useTranslation();

  const localLang = getLocalToken("locale");
  // Current selected lang by user or User stored one or eng
  const currentLang = localLang || i18n.languages[0] || "en";

  if (textOnly)
    return (
      <div style={style}>
        <Tooltip placement="left" title={t("select language")}>
          <Popover
            placement="bottomRight"
            trigger="click"
            content={<Locales />}
          >
            <p style={{ cursor: "pointer" }}>{textHelper[currentLang]}</p>
          </Popover>
        </Tooltip>
      </div>
    );
  return (
    <div className={styles.localeContainer} style={style}>
      <Tooltip
        placement="left"
        style={{ cursor: "pointer" }}
        title={t("select language")}
      >
        <Popover placement="bottomRight" content={<Locales />}>
          {currentLang === "en" ? (
            <img src={UsaFlag} className={styles.localeCurrent} />
          ) : (
            <img src={JapanFlag} className={styles.localeCurrent} />
          )}
        </Popover>
      </Tooltip>
    </div>
  );
}

export function Locales() {
  const { i18n } = useTranslation();

  const changeLanguage = (code) => {
    i18n.changeLanguage(code);

    if (code === "jp") {
      document.documentElement.style.setProperty(
        "--font-base",
        "'Noto Sans JP', sans-serif"
      );
    }

    if (code === "en") {
      document.documentElement.style.setProperty(
        "--font-base",
        "'Noto Sans JP', cursive"
      );
    }
    // Update localestorage locale, to be sent with backend requests
    setLocalToken("locale", code);

    window.location.reload();
  };

  return (
    <>
      <div
        onClick={() => changeLanguage("en")}
        className={styles.localeFlagContainer}
      >
        {/* <img src={UsaFlag} className={styles.localeFlag} /> */}
        <p>English (US)</p>
      </div>
      <div
        onClick={() => changeLanguage("jp")}
        style={{ marginTop: ".75rem" }}
        className={styles.localeFlagContainer}
      >
        {/* <img src={JapanFlag} className={styles.localeFlag} /> */}
        <p>日本語</p>
      </div>
    </>
  );
}
