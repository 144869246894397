import { useState } from "react";
import { Modal, Input, Select, Form, Button, message } from "antd";
import { MdReport } from "react-icons/md";

import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";

import { useUser } from "shared/contexts/user-context";
import { REPORT_POST, GET_POST_REPORTS } from "shared/services";
import { LoadingSkeleton } from "shared/components";

const { TextArea } = Input;

export default function ReportPost({ isOpen, onClose, post, type }) {
  const { t } = useTranslation();
  const { user } = useUser();

  const { loading: reportLoading, data: reportData } = useQuery(
    GET_POST_REPORTS,
    {
      variables: {
        reporterId: user.id,
        ...(type === "post" && { postId: post.id }),
        ...(type === "video" && { videoId: post.id }),
      },
      fetchPolicy: "network-only",
    }
  );

  const [reportPost, { loading }] = useMutation(REPORT_POST);

  const onFinish = async (values) => {
    console.log("Success:", values);

    const res = await reportPost({
      variables: {
        ...values,
        name: `${user.first_name} ${user.last_name}`,
        email: `${user.email}`,
        ...(type === "post" && { postId: post.id }),
        ...(type === "video" && { videoId: post.id }),
      },
    });

    if (res?.data?.create_post_video_report?.error) {
      message.error(res?.data?.create_post_video_report?.error);
    } else {
      message.success(`${t("your report has been submitted successfully")}`);
    }
    onClose();
  };

  return (
    <Modal
      title={t(`Report ${type}`)}
      visible={isOpen}
      onCancel={!loading && onClose}
      width={360}
      onOk={onFinish}
      footer={
        reportData?.post_video_reports?.total_count < 1 && [
          <Button
            type="primary"
            form="reportForm"
            key="submit"
            htmlType="submit"
            disabled={loading}
            loading={loading}
          >
            {t(`Report ${type}`)}
          </Button>,
        ]
      }
    >
      {reportLoading && <LoadingSkeleton props={{ count: 3 }} />}
      {!reportLoading &&
        reportData &&
        reportData.post_video_reports.total_count > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MdReport style={{ fontSize: "2.5rem", marginBottom: "1rem" }} />
            <h3>{t("you have already reported this post")}</h3>
          </div>
        )}
      {!reportLoading &&
        reportData &&
        reportData.post_video_reports.total_count < 1 && (
          <Form
            onFinish={onFinish}
            layout="vertical"
            name="reportForm"
            id="reportForm"
          >
            <Form.Item
              label={t("Report category")}
              name="category"
              rules={[{ required: true, message: `${t("required")}` }]}
            >
              <Select>
                <Select.Option value="inappropriate_content">
                  {t("inappropriate content")}
                </Select.Option>
                <Select.Option value="copyright_issue">
                  {t("copyright issue")}
                </Select.Option>
                <Select.Option value="spam">{t("spam")}</Select.Option>
                <Select.Option value="affecting_privacy">
                  {t("affecting privacy")}
                </Select.Option>
                <Select.Option value="political_issue">
                  {t("political issue")}
                </Select.Option>
                <Select.Option value="fallacious_content">
                  {t("fallacious content")}
                </Select.Option>
                <Select.Option value="other">{t("other")}</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label={t("description")} name="description">
              <TextArea rows={4} bordered={true} allowClear />
            </Form.Item>
          </Form>
        )}
    </Modal>
  );
}
