import { useState, useEffect } from "react";
import { Button, message } from "antd";
import { FiStar } from "react-icons/fi";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { useUser } from "shared/contexts/user-context";
import {
  GET_FAVOURITE_VIDEOS,
  CREATE_FAVOURITE_VIDEO,
  DELETE_FAVOURITE_VIDEO,
} from "shared/services";

import styles from "./video-post.module.scss";

export default function VideoFavourite({ post, refetchPost, refetchFavVids }) {
  const { t } = useTranslation();
  const { user } = useUser();

  const [videoFavByMe, setVideoFavByMe] = useState(false);
  const [favId, setFavId] = useState();

  const {
    loading: favLoading,
    data,
    refetch,
  } = useQuery(GET_FAVOURITE_VIDEOS, {
    variables: { professionalId: user.id, videoId: post.id },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!favLoading && data) {
      setVideoFavByMe(Boolean(data?.favourite_videos.total_count === 1));
      setFavId(data?.favourite_videos?.favourite_videos[0]?.id);
    }
  }, [favLoading]);

  const [createFav, { loading }] = useMutation(CREATE_FAVOURITE_VIDEO, {
    onCompleted(data) {
      if (data.create_favourite_video.error) {
        message.error(data.create_favourite_video.error);
      } else {
        message.success(`${t("Video added to favourites successfully")}`);
        refetch();
        refetchFavVids();
      }
    },
  });

  const [deleteFav, { loading: deleteFavLoading }] = useMutation(
    DELETE_FAVOURITE_VIDEO,
    {
      onCompleted(data) {
        if (data.delete_favourite_video.error) {
          message.error(data.delete_favourite_video.error);
        } else {
          message.success(`${t("Video removed from favourites successfully")}`);
          refetch();
          refetchFavVids();
        }
      },
    }
  );

  return (
    <div className={styles.action}>
      <Button
        className={clsx(styles.button, "post-action-btn")}
        disabled={loading || deleteFavLoading || favLoading}
        onClick={() => {
          if (!videoFavByMe) {
            createFav({ variables: { videoId: post.id } });
          } else {
            deleteFav({
              variables: { id: favId },
            });
          }
        }}
      >
        <FiStar className={(styles.actionIcon, videoFavByMe && styles.liked)} />
        <p style={{ marginLeft: ".25rem" }}>
          {!videoFavByMe
            ? `${t("add to favourite")}`
            : `${t("remove from favourite")}`}
        </p>
      </Button>
    </div>
  );
}
