import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "reportWebVitals";
import "normalize.css";
import "antd/dist/antd.css";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "shared/styles/index.scss";

import App from "./App";
import ApolloProvider from "./apollo-setup";

/* ---- i18n setup ---- */
import { I18nextProvider } from "react-i18next";
import i18nConfig from "./i18n";

ReactDOM.render(
  <I18nextProvider i18n={i18nConfig}>
    <ApolloProvider>
      <App />
    </ApolloProvider>
  </I18nextProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
