import { useQuery } from "@apollo/client";
import axios from "axios";
import { REST_VIDEO_API_ENDPOINT } from "config";
import { useEffect, useState } from "react";

import { useUser } from "shared/contexts/user-context";
import { GET_USER_STORAGE } from "shared/services";
import {
  convertBytesToMb,
  convertMbToGb,
  getLocalToken,
  getPercentageOfTotal,
} from "shared/utils/util-funcs";

const useStorage = (add = 0) => {
  const { user } = useUser();
  const [storageInfo, setStorageInfo] = useState({
    posts_s3_usage_in_bytes: 0,
    total_s3_usage_exclude_video_in_bytes: 0,
    vdocipher_usage_in_bytes: 0,
    video_s3_usage_in_bytes: 0,
  });
  const [totalUsage, setTotalUsage] = useState(0);
  const [usagePercent, setUsagePercent] = useState(0);
  const [availableStorage, setAvailableStorage] = useState(0);
  const [currUnit, setCurrUnit] = useState("mb");

  const { data, loading } = useQuery(GET_USER_STORAGE, {
    variables: {
      userId: user.id,
    },
  });

  useEffect(() => {
    const getStorageUsage = async () => {
      try {
        const res = await axios.post(
          `${REST_VIDEO_API_ENDPOINT}/usage/`,
          {
            video: true,
            post: true,
            professional_ids: [user.id],
          },
          { headers: { Authorization: getLocalToken("auth") } }
        );
        setStorageInfo(res.data[0]);
      } catch (err) {
        return { msg: err.message, error: true };
      }
    };
    getStorageUsage();
  }, []);

  useEffect(() => {
    if (!loading && data) {
      let videoUsage = convertBytesToMb(
        parseInt(storageInfo.video_s3_usage_in_bytes) + parseInt(add)
      );
      let availableStorage = convertBytesToMb(
        data?.professional?.professional.available_storage
      );

      // if availableStorage is in GB, convert units to GB
      // 1 GB = 1024MB
      if (availableStorage > 1024) {
        availableStorage = convertMbToGb(availableStorage);
        videoUsage = convertMbToGb(videoUsage);
        setCurrUnit("gb");
      }

      const usagePercentage = getPercentageOfTotal(
        videoUsage,
        availableStorage
      );

      setUsagePercent(usagePercentage);
      setTotalUsage(videoUsage.toFixed(0));
      setAvailableStorage(availableStorage.toFixed(0));
    }
  }, [storageInfo, data, loading, add]);

  return {
    availableStorage,
    totalUsage,
    currUnit,
    usagePercent,
    storageInfo: data ? storageInfo : {},
  };
};

export default useStorage;
